import React, { Component } from "react";
import { FaUserLock  } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "../Atoms/button";
class SinAcceso extends Component {
    constructor(props){
        super(props);
        this.enlace = React.createRef();
    }
    render(){
    return(
        <div className="content-unlock">
            <div className="icon-unlock" >
                <FaUserLock />
            </div>
            <p className="message-unlock">Acceso no autorizado</p>
            <p className="message-unlock">{this.props.text}</p>
            <div className="margin-medium-top-3" style={{textAlign:"center"}}>
                <Button styleClass="button__primary button--size-large" text={this.props.textButton} method={()=>{this.chooseOption()}} />
                <Link to="/" ref={this.enlace} className="disabled"/>
            </div>
        </div>
    );
    }
    chooseOption(){
        if (this.props.type) {
            localStorage.removeItem("user");
            localStorage.removeItem("iniciada");
            sessionStorage.removeItem("sesion");
            this.enlace.current.click();
        }else{
            // localStorage.removeItem("user");
            this.enlace.current.click();
        }
    }
}

export default SinAcceso;