import React, { Component } from 'react';
import logo from '../../canacope-logo.png';
import { FaSignOutAlt, FaHome, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";
import Tooltip from '../Molecules/tooltip';
class Header extends Component {
    constructor(props) {
        super(props);
        this.loginPage = React.createRef();
    }
    render() {
        return (
            <header className={`header margin-small-top-2 ${this.props.styleClass}`}>
                <div className="header__content">
                    <img className="header__logo" src={logo} alt="canacope-logo" />
                    <nav className="header__nav">
                        <Tooltip text="Principal" position="tooltip--bottom" styleComponent="margin-icon__right">
                            <Link to="/dashboard" ><FaHome /></Link>
                        </Tooltip>
                        <Tooltip text="Agregar" position="tooltip--bottom" styleComponent="margin-icon__right" >
                            <Link to="/registro" ><FaPlusCircle /></Link>
                        </Tooltip>
                        <Tooltip text="Editar" position="tooltip--bottom" styleComponent="margin-icon__right" >
                            <Link to="/actualizacion"><FaEdit /></Link>
                        </Tooltip>
                        <Tooltip text="Cerrar sesion" position="tooltip--left" >
                            <FaSignOutAlt onClick={() => { this.closeSession() }} />
                        </Tooltip>
                        <Link ref={this.loginPage} className="disabled" to="/"></Link>
                    </nav>
                </div>
            </header>
        );
    }
    closeSession() {
        localStorage.removeItem("user");
        localStorage.removeItem("iniciada");
        sessionStorage.removeItem("sesion");
        this.loginPage.current.click();
    }
}

export default Header;