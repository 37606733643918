import React, { Component } from "react";
/**
 * para usarlo los hijos dentro del Autocompletado deben tener un 
 * atributo dataset "data-value" al que se le colocan cada uno de 
 * sus valores corresponsientes los hijos de preferencia deben ser
 * <li></li>
 * 
 * */
class Autocompletado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFilter: "",
            valueInputHidden: "",
            classEnabledContent: "disabled"
        }
        this.listElement = React.createRef()
    }

    render() {

        const { inputFilter, classEnabledContent, valueInputHidden } = this.state;
        let {defaultInputHidden} = this.props;
        defaultInputHidden = defaultInputHidden || "";
        let filterElement =  this.props.children.filter(child => {
            let string = child.props.children.toUpperCase();
            return string.includes(inputFilter.toUpperCase())
        });
        return (
            <div style={{ position: "relative" }}>
                <label className="content-element__form-sample">
                    <span className="form__label">{this.props.icon}</span>
                    <input className="form__input form__normal" onKeyUp={(evt) => { this.keyPress(evt) }} value={inputFilter} onChange={(evt) => { this.filterOption(evt) }} type="text" placeholder={this.props.defaultValue} required={this.props.habilitado} readOnly={this.props.disabledInput}   autoComplete="off" />
                    <input name={this.props.nameInput} type="hidden" value={valueInputHidden === "" ? defaultInputHidden : valueInputHidden} />
                </label>
                <ul className={`option-filter ${classEnabledContent}`} onClick={(evt) => { this.selectedOption(evt) }} ref={this.listElement} onKeyUp={(evt) => { this.keyPress(evt) }} >
                    {
                        filterElement.length > 0 ? filterElement: <li tabIndex={-1} data-value="sinDatos" style={{fontWeight:"700"}}>No existen elementos</li> 
                    }
                </ul>
            </div>

        );
    }

    filterOption(evt) {
        let element = evt.target;
        let obj = {};
        if (element.value !== "") {
            obj.classEnabledContent = "enabled";
            obj.inputFilter = evt.target.value;
        } else {
            obj.classEnabledContent = "disabled";
            obj.inputFilter = evt.target.value;
            obj.valueInputHidden = evt.target.value;
        }
        this.setState(obj);
    }

    selectedOption(evt) {
        let element = evt.target;
        this.setState(
            {
                inputFilter: element.textContent,
                valueInputHidden: element.dataset.value,
                classEnabledContent: "disabled"
            }
        );

    }
    keyPress(evt) {
        let element = evt.target;

        if (evt.key === "ArrowDown" && element.nodeName === "INPUT") {
            this.listElement.current.firstElementChild.focus();
        }
        if (evt.key === "Enter") {
            this.setState(
                {
                    inputFilter: element.textContent,
                    valueInputHidden: element.dataset.value,
                    classEnabledContent: "disabled"
                }
            );
        }

    }
}

export default Autocompletado;