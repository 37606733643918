import React, { Component } from "react";
/**componente que ayuda  a seleccinar una seccion de una pagina renderizando su contenido
 * Para que funcione en el componente que es llamado se crea una propiedad de estado "sectionNumber: 0"
 * Y en el componente OptionPage de crea una propiedad  de la siguiente forma "stateSection={(sectionNumber)=>{this.setState({sectionNumber})}}"
 * Dentro de los hijos directo de OptionPage crear una propiedad dataset data-section="0"  cambiando el valor de esta segun el orden que se quiera mostrar  
*/
class SectionPageDisplay extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { section } = this.props;
        return (
            // this.props.children.find((element, index) => section === index)
            this.props.children.map((child,index)=> {
                let classVisible = index === section ? "enabled":"disabled";
                return React.cloneElement(child,{
                    className: `${child.props.className} ${classVisible}`
                })  
            })
        );
    }


}

class OptionPage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="nav-container" onClick={(evt) => { this.selectSection(evt) }}>
                {
                    this.props.children
                }
            </div>
        );
    }

    selectSection(evt) {
        let element = evt.target.dataset.section ? evt.target : evt.target.parentElement;
        if (element.dataset.section) {
            let sectionNumber = parseInt(element.dataset.section);
            this.props.stateSection(sectionNumber);
        }


    }
}

export { SectionPageDisplay as default, OptionPage };