import React from "react";
import PropTypes from "prop-types";
function Tooltip(props) {
    return(
        <span className={`tooltip__box ${props.position} ${props.styleComponent}`}>
            {props.children}
            <span className={`tooltip__text`}>{props.text}</span>
        </span>
    );
}

Tooltip.propTypes ={
    position: PropTypes.string
}
Tooltip.defaultProps = {
    position: ""
}

export default Tooltip;