import React, { Component } from "react";
import Title from "../Atoms/title";
import Button from "../Atoms/button";
import BoxInput from "../Molecules/box-input";
import Spinner from "../Molecules/spinner";
import Autocompletado from "./autocompletado";
import { FaStore } from "react-icons/fa";

class FormComercio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vendor: [],
            vendorName: "",
            tipoVialidad: [],
            giroComercial: [],
            //
            colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "Sin Colonia" }],
            ciudad: "",
            municipioCiudad: "",
            //
            detailCommerce: false,
            entreVialidad: [],
            datosContactos: [],
            idUpdateVendor: "",
            inputVendorId: "",
            commerces: [],
            spinnerLoadCommerce: true,
            spinnerUpdateCommerce: false,
            enabledSection: "enabled",
            otherPostCode: "disabled",
            postCodeAdd: false,
            enabledOtherPost: false,
            actividadPrincipal: [],
            //parametro otra direccion Fiscal
            visibilityAddresBill: false,
            //validar tipo empresa
            typeCompany: [],
            typeCompanyDefault: false


        }
        this._isMounted = false;
        this.postalCodeInput = React.createRef();
        this.formCommerce = React.createRef();
    }

    render() {
        const { vendor, colonia, tipoVialidad, giroComercial, detailCommerce, commerces, enabledSection, actividadPrincipal, spinnerUpdateCommerce, ciudad, municipioCiudad } = this.state;
        // console.log(detailCommerce?.estado);
        return (
            <>
                <section className={enabledSection}>
                    {this.props.params.type === "details" ? <></> : <Title type="title" content="Actualizar comercio" />}
                    {
                        this.props.params.idCommerce ?
                            <></>
                            :
                            <>
                                <h3 className="margin-large-top-3">Buscar Comercio</h3>
                                <form className="margin-medium-top-2" onSubmit={(evt) => { this.searchCommerce(evt) }}>
                                    <div style={{ width: "800px" }} className="grid-layout grip-gap grid-2">
                                        <BoxInput typeBox="sample" typeInput="number" icon="Número de cliente" styleClass="form__label" statusInput="form__normal" nameInput="clienId" />
                                        <div >
                                            <Button styleClass="button__primary button--size-medium" text="Buscar" />
                                        </div>
                                    </div>

                                </form>
                                <form className="margin-large-top-3" onSubmit={(evt) => { this.loadDataCommerce(evt) }}>
                                    {
                                        commerces.length > 0 ?
                                            commerces.map((commerce, index) => (
                                                <div key={index} className="margin-medium-top-2">
                                                    <BoxInput value={commerce.id} typeBox="checkbox" typeInput="radio" icon={`${commerce.nombre}`} styleClass="form__label" statusInput="form__normal" nameInput="commerce" />
                                                </div>
                                            )) : <></>
                                    }
                                    {commerces.length > 0 ? <Button styleClass="button__primary button--size-medium margin-medium-top-2" text="Cargar" /> : <></>}
                                </form>
                                {
                                    this.state.spinnerLoadCommerce ? <></> :
                                        <div className="margin-large-top-2"><Spinner text="Cargando datos" /></div>
                                }
                            </>
                    }

                    <form className="margin-large-top-3" ref={this.formCommerce} onSubmit={(evt) => { this.updateCommerce(evt) }}>
                        <h3>{this.props.params.estadoCliente && detailCommerce?.estado ? <></> : <p><FaStore className="icon-inactive icon-size-medium" /> Comercio Bloquedo</p>}</h3>
                        <h3 className="margin-large-top-3">Promotor</h3>
                        {
                            detailCommerce ?
                                <p className="margin-medium-top-2" style={{ textDecoration: "2px black underline", fontWeight: "700" }}>
                                    Promotor Actual: {`${detailCommerce.promotorModelo.nombre} ${detailCommerce.promotorModelo.apellidoPaterno} ${detailCommerce.promotorModelo.apellidoMaterno}`}
                                </p> : <></>
                        }
                        <div className="margin-medium-top-1" style={{ width: "500px" }}>
                            <Autocompletado icon="Promotor" nameInput="promotorModelo" defaultInputHidden={detailCommerce.promotorModelo ? detailCommerce.promotorModelo.id : ""} disabledInput={this.props.disabledInput || false}>
                                {
                                    vendor.map((vendorElement, index) => <li key={index} tabIndex={-1} data-value={vendorElement.id}>{`${vendorElement.apellidoPaterno} ${vendorElement.apellidoMaterno} ${vendorElement.nombre}`}</li>)

                                }
                            </Autocompletado>
                        </div>
                        <h3 className="margin-medium-top-3">Datos del comercio</h3>
                        <h4 className="margin-large-top-3 color-title__primary">-Datos Generales----</h4>
                        <div className="margin-medium-top-3">
                            <BoxInput typeBox="description" typeInput="text" icon="Nombre Comercial" description="Escriba el nombre como se le conoce al establecimiento, por ejemplo: Tortilleria María" statusInput="form__normal" styleClass="form__label" nameInput="nombre" disabledInput={this.props.disabledInput || false} minLength={3} maxLength={100} />
                        </div>
                        <h5 className="margin-medium-top-3 color-title__primary" style={{fontSize:"17px"}} >-Ubicación del Comercio--</h5>
                        <div className="grid-layout  grip-gap layout__ubication margin-large-top-2">
                            <label htmlFor="" className="content-element__form-sample">
                                <span className="form__label">Tipo Vialidad</span>
                                <select name="tipoVialidadModelo" disabled={this.props.disabledInput || false} className="form__input form__normal select-disabled" >
                                    {
                                        tipoVialidad.map(
                                            vialidad => (
                                                <option key={vialidad.id} value={vialidad.id}>{vialidad.descripcion}</option>
                                            )
                                        )
                                    }
                                </select>
                            </label>
                            <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" icon="Nombre de la vialidad" statusInput="form__normal" nameInput="nombreVialidad" defaultValue="S/D" disabledInput={this.props.disabledInput || false} maxLength={50} />
                        </div>
                        <div className="margin-large-top-3 grid-layout grip-gap grid-2" style={{ width: "550px" }}>
                            <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" icon="Número Exterior" statusInput="form__normal" nameInput="numeroExterior" defaultValue="S/N" disabledInput={this.props.disabledInput || false} maxLength={25} />
                            <BoxInput typeBox="sample" typeInput="text" defaultValue="S/N" styleClass="form__label" icon="Número Interior" statusInput="form__normal" nameInput="numeroInterior" disabledInput={this.props.disabledInput || false} maxLength={25} />
                        </div>
                        
                        <div className="grid-layout grip-gap margin-medium-top-3" style={{ gridTemplateColumns: "2fr 2fr 1fr", width: "1200px" }} >
                            <div className="grid-layout grip-gap " style={{ gridTemplateColumns: "1fr max-content" }}  >
                                <div >
                                    <BoxInput typeBox="input" styleClass="form__label" icon="Código postal">
                                        <input type="text" maxLength={5} className="form__normal form__input" name="codigoPostal" placeholder="00000" ref={this.postalCodeInput} readOnly={this.props.disabledInput || false} />
                                    </BoxInput>

                                </div>
                                <div>
                                    <button type="button" className="button button__primary button--size-medium" onClick={() => { this.getPostalCode() }} >consultar</button>
                                </div>
                            </div>
                            <label htmlFor="" className="content-element__form-sample">
                                <span className="form__label">Colonia</span>
                                <select name="coloniaModelo" className="form__input form__normal select-disabled" >
                                    {colonia.length <= 0 ? <option>cargando......</option> :
                                        colonia.map(
                                            colonia => (
                                                <option key={colonia.id} value={colonia.id}>{colonia.nombre}</option>
                                            )
                                        )
                                    }

                                </select>
                            </label>
                            <div onChange={(evt) => { this.otherPostCode(evt) }}>
                                <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Otro" disabledInput={this.props.disabledInput || false} styleClass="form__label" />
                            </div>
                        </div>
                        <div className="margin-medium-top-3">
                            <span className="margin-medium-right-3" style={{ fontWeight: 700 }}>Estado: {ciudad || "S/D"}</span>
                            <span style={{ fontWeight: 700 }} >Municipio: {municipioCiudad || "S/D"}</span>
                        </div>
                        <div className={`margin-medium-top-3 ${this.state.otherPostCode}`} style={{ width: "600px" }}>
                            <BoxInput typeBox="sample" typeInput="text" icon="Nueva colonia" styleClass="form__label" statusInput="form__normal" nameInput="nuevaColonia" />
                        </div>
                        {/* <div className="margin-medium-top-2 grid-layout grip-gap" style={{ gridTemplateColumns: "min-content min-content" }} > 
                            <span>
                                <BoxInput typeBox="checkbox" styleClass="form__label" nameInput="direccionFiscal" statusInput="form__normal" typeInput="checkbox" icon="Si este domicilio de comercio se desea utilizarlo como dirección fiscal, marca aquí" value="true" disabledInput={this.props.disabledInput || false} />
                            </span>
                          
                        </div>*/}

                        <h4 className="margin-medium-top-3 color-title__primary">-Entrevialidades----</h4>
                        <div className="margin-medium-top-3">
                            <div>
                                <BoxInput typeBox="sample" typeInput="text" icon="Nombre vialidad" styleClass="form__label" statusInput="form__normal" nameInput="nombreVialidadUno" defaultValue="S/D" disabledInput={this.props.disabledInput || false} maxLength={50} />
                            </div>
                            <div className="margin-medium-top-3">
                                <BoxInput typeBox="sample" typeInput="text" icon="Nombre vialidad" styleClass="form__label" statusInput="form__normal" nameInput="nombreVialidadDos" defaultValue="S/D" disabledInput={this.props.disabledInput || false} maxLength={50} />
                            </div>
                        </div>
                        <h4 className="margin-medium-top-3 color-title__primary">-Datos de contacto del establecimiento para negocios----</h4>
                        <div className="margin-medium-top-3">
                            <div className="grid-layout grid-2 grip-gap">
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="nombreContacto" defaultValue="S/D" icon="Nombre" disabledInput={this.props.disabledInput || false} maxLength={80} />
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="puestoContacto" defaultValue="S/D" icon="Puesto" disabledInput={this.props.disabledInput || false} maxLength={20} />

                            </div>
                            <div className="grid-layout grid-3 grip-gap margin-medium-top-2">
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="correoElectronico" defaultValue="S/D" icon="Email" disabledInput={this.props.disabledInput || false} maxLength={100} />
                                <BoxInput typeBox="input" styleClass="form__label" icon="Teléfono">
                                    <input type="text" className="form__normal form__input" name="telefono" placeholder="S/D" maxLength={33} readOnly={this.props.disabledInput || false} />
                                </BoxInput>
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="extension" defaultValue="S/D" icon="Extensión" disabledInput={this.props.disabledInput || false} maxLength={4} />

                            </div>
                            <div className="grid-layout grid-3 grip-gap margin-medium-top-2">
                                <BoxInput typeBox="input" styleClass="form__label" icon="Whatsapp">
                                    <input type="text" className="form__normal form__input" name="whatsapp" placeholder="S/D" maxLength={10} readOnly={this.props.disabledInput || false} />
                                </BoxInput>
                            </div>
                        </div>
                        <h4 className="margin-medium-top-3 color-title__primary">-Perfil general de la empresa----</h4>
                        <div className="grid-layout grid-2 grip-gap margin-medium-top-3">
                            <BoxInput typeBox="sample" typeInput="date" styleClass="form__label" icon="Fecha de Inicio de operaciones" statusInput="form__normal" nameInput="inicioOperaciones" defaultValue="S/D" disabledInput={this.props.disabledInput || false} />
                            <div className="layout-form__type-company">
                                <span className="label-font">Empresa</span>
                                <div className="grid-layout grid-3 grip-gap" style={{ justifyItems: "center" }} onChange={(evt) => { this.validateTypeCompany(evt) }}>
                                    <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" statusInput="form__normal" value="UNICA" nameInput="tipoEmpresa" icon="Única" disabledInput={this.props.disabledInput || false} />
                                    <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" statusInput="form__normal" value="MATRIZ" nameInput="tipoEmpresa" icon="Matriz" disabledInput={this.props.disabledInput || false} />
                                    <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" statusInput="form__normal" value="SUCURSAL" nameInput="tipoEmpresa" icon="Sucursal" disabledInput={this.props.disabledInput || false} />
                                </div>
                            </div>

                        </div>
                        <h4 className="margin-medium-top-3 color-title__primary">-Actividad economica----</h4>
                        <div className="margin-medium-top-3">
                            <p style={{ fontWeight: "700", textDecoration: "2px black underline" }}>Giro actual: {detailCommerce.actividadPrincipal || "..........."}</p>
                            <div className="margin-medium-top-2">
                                <Autocompletado icon="Giro comercial" nameInput="actividadPrincipal" defaultInputHidden={detailCommerce.actividadPrincipal ? detailCommerce.actividadPrincipal : ""} disabledInput={this.props.disabledInput || false}>
                                    {
                                        actividadPrincipal.map((actividad, index) => <li key={index} tabIndex={-1} data-value={actividad} >{actividad}</li>)
                                    }
                                </Autocompletado>
                            </div>
                            <h4 className="margin-medium-top-3 ">Llenar solo si desea crear una nuevo Giro Comercial</h4>
                            <BoxInput typeBox="description" typeInput="text" statusInput="form__normal" styleClass="form__label" nameInput="actividadPrincipalCrear" description="¿Cuál es la actividad principal del establecimiento según los ingresos obtenidos?" defaultValue="S/D" disabledInput={this.props.disabledInput || false} maxLength={150} />
                        </div>
                        <div className="margin-medium-top-3" style={{ width: "1000px" }}>
                            {
                                detailCommerce.giroComercialModelo ?
                                    <p style={{ fontWeight: "700", textDecoration: "2px black underline" }}>Codigo SCIAN actual: {detailCommerce.giroComercialModelo.descripcion}</p> : <></>
                            }
                            <div className="margin-medium-top-2">
                                <Autocompletado icon="Codigo SCIAN" nameInput="giroComercialModelo" defaultInputHidden={detailCommerce.giroComercialModelo ? detailCommerce.giroComercialModelo.id : ""} disabledInput={this.props.disabledInput || false}>
                                    {
                                        giroComercial.map(
                                            giro => (
                                                <li key={giro.id} tabIndex={-1} data-value={giro.id}>{`${giro.id}-${giro.descripcion}`}</li>
                                            )
                                        )
                                    }
                                </Autocompletado>
                            </div>
                        </div>
                        <h4 className="margin-medium-top-3 color-title__primary">-Personal ocupado----</h4>
                        <div className="margin-medium-top-3 layout-form__type-company" style={{ width: "80%" }}>
                            <span className="label-font">¿Cuántas personas trabajaron para este establecimiento?</span>
                            <div className="grid-layout grid-2 grip-gap" style={{ justifyItems: "center" }}>
                                <BoxInput typeBox="sample" typeInput="number" styleClass="form__label" statusInput="form__normal form__input--medium" nameInput="trabajadorHombre" icon="Total" defaultValue="S/N" disabledInput={this.props.disabledInput || false} />
                                <BoxInput typeBox="sample" typeInput="number" styleClass="form__label" statusInput="form__normal form__input--medium" nameInput="trabajadorMujer" icon="Mujeres" defaultValue="S/N" disabledInput={this.props.disabledInput || false} />

                            </div>
                        </div>
                        <h4 className="margin-medium-top-3 color-title__primary">-Información comercial----</h4>
                        <div className="margin-medium-top-3">
                            <h5>Escriba la url de los sitios de internet correspondientes. Por ejemplo: https://es-es.facebook.com/miempresa_mx</h5>
                            <div className="margin-medium-top-3">
                                <BoxInput typeBox="sample" typeInput="text" nameInput="facebook" styleClass="form__label" statusInput="form__normal" icon="Facebook" defaultValue="S/D" disabledInput={this.props.disabledInput || false} maxLength={50} />
                            </div>
                            <div className="margin-medium-top-3">
                                <BoxInput typeBox="sample" typeInput="text" nameInput="twitter" styleClass="form__label" statusInput="form__normal" icon="Twitter" defaultValue="S/D" disabledInput={this.props.disabledInput || false} maxLength={15} />
                            </div>

                        </div>
                        {
                            this.props.params.estadoCliente ?
                                <div className="margin-large-top-3">
                                    <h4>{detailCommerce.estado ? "¿Desea bloquear este comercio?" : "¿Desea desbloquear este comercio?"}</h4>
                                    <div className="margin-medium-top-3" onChange={(evt)=>{this.blockCommerce(evt)}}>
                                        <BoxInput typeBox="checkbox" typeInput="checkbox" nameInput="estado" styleClass="form__label" icon={detailCommerce.estado ? "Bloquear Comercio" : "Desbloquear Comercio"} value={detailCommerce.estado} disabledInput={this.props.disabledInput || false} />
                                    </div>
                                </div>
                                : <></>
                        }
                        {this.props.disabledInput ? <></> : <><Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Actualizar" />{spinnerUpdateCommerce ? <Spinner text="Actualizando" /> : <></>}</>}

                    </form>
                </section>

            </>
        );
    }

    async componentDidMount() {
        let detailCommerce, entreVialidad, datosContactos, tipoVialidad, giroComercial, vendor, actividadPrincipal, type;
        // this.props.loadPage()
        this._isMounted = true;
        tipoVialidad = await this.read(`${this.props.hosting}/webservice/catalogos/tipo-vialidad`);
        giroComercial = await this.read(`${this.props.hosting}/webservice/catalogos/giro-comercial`);
        vendor = await this.read(`${this.props.hosting}/webservice/promotor?estado=true`);
        actividadPrincipal = await this.read(`${this.props.hosting}/webservice/comercio/actividadPrincipal`);
        type = await this.read(`${this.props.hosting}/webservice/comercio/tipoEmpresa/${this.props.params.idClient}`);
        this.assingTypeCompanyDefault(type);
        if (this.props.params.idCommerce && this._isMounted) {

            ({ detailCommerce, entreVialidad, datosContactos } = await this.searchDetailsCommerce(this.props.params.idCommerce));
            // console.log(detailCommerce.estado);
            this.props.enabledPay(detailCommerce.estado);
            this.setState(
                {
                    detailCommerce,
                    entreVialidad,
                    datosContactos,
                    inputVendorId: detailCommerce.promotorModelo.id,
                    colonia: [{ id: detailCommerce.coloniaModelo.id, nombre: detailCommerce.coloniaModelo.nombre }],
                    actividadPrincipal,
                    tipoVialidad,
                    giroComercial,
                    vendor,
                    typeCompany: type || [],
                    ciudad: detailCommerce.estadoMunicipio,
                    municipioCiudad: detailCommerce.municipio,


                }
            );

            if (this._isMounted) {
                this.fillFormCommerce()
            }

        } else {
            this.setState(
                {
                    tipoVialidad,
                    giroComercial,
                    spinnerLoadPage: false,
                    enabledSection: "enabled"
                }
            );
        }


    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async searchVendor(evt) {
        evt.preventDefault();
        this.setState({
            vendor: [0]
        });
        let formVendor = new FormData(evt.target);
        let vendorDate = await this.read(`${this.props.hosting}/webservice/promotor?estado=true&palabra=${formVendor.get("promotor")}`) || [];
        this.setState({
            vendor: vendorDate
        });
        if (document.getElementById("form-vendor")) {
            document.getElementById("form-vendor").reset();
        }

    }
    selectVendorCommerce(evt) {
        this.setState(
            {
                inputVendorId: evt.target.value
            }
        );
    }
    async searchCommerce(evt) {
        evt.preventDefault();
        let form = new FormData(evt.target);
        let data = await this.read(`${this.props.hosting}/webservice/comercio/${form.get("clienId")}`);

        this.setState(
            {
                commerces: data
            }
        );

    }

    async getPostalCode() {
        this.setState({ colonia: [] });
        let postCode = this.postalCodeInput.current.value;
        if (this.postalCodeInput.current.value.length < 5) {
            this.props.modal("El codigo postal debe tener 5 caracteres. Por ejemplo 9999 colocar 09999", 2)
            this.setState({ colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "No existe el codigo postal" }] });
            return 0;
        }
        let response = await this.read(`${this.props.hosting}/webservice/direccion/${postCode}`);
        const coloniaModeloLista = response.coloniaModeloLista ? response.coloniaModeloLista : [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "No existe el codigo postal" }];
        let { idMunicipio, estado, municipio } = response;
        this.setState({
            colonia: coloniaModeloLista,
            postCodeAdd: { idMunicipio, nombre: postCode },
            ciudad: estado,
            municipioCiudad: municipio,
        });
        if (this.state.enabledOtherPost) {
            this.setState({ otherPostCode: "enabled" });
        }
    }

    async loadDataCommerce(evt) {
        evt.preventDefault();
        this.setState({ spinnerLoadCommerce: false });
        let form = new FormData(evt.target);
        let detailCommerce, entreVialidad, datosContactos;
        ({ detailCommerce, entreVialidad, datosContactos } = await this.searchDetailsCommerce(form.get("commerce")));
        this.setState(
            {
                detailCommerce,
                entreVialidad,
                datosContactos,
                inputVendorId: detailCommerce.promotorModelo.id
            }
        );
        this.fillFormCommerce()
        this.setState({ spinnerLoadCommerce: true });


    }
    async updateCommerce(evt) {
        evt.preventDefault();
        let dataAdress = null;
        const { typeAdress,dateClient,statusForm} = this.props.params;
        let formCommerce = new FormData(evt.target);
        const { entreVialidad, datosContactos } = this.state;
        let objFormCommerce = {};
        for (const pair of formCommerce.entries()) {
            objFormCommerce[pair[0]] = pair[1] === "" ? "S/D" : pair[1].toUpperCase() ;
        }
        //console.log(objFormCommerce);
        if (this.state.otherPostCode === "enabled") {
            const { idMunicipio, nombre } = this.state.postCodeAdd;
            let objPostCode = {
                coloniaModeloLista: [
                    {
                        nombre: objFormCommerce.nuevaColonia
                    },
                ],
                idMunicipio
            }
            // console.log(objPostCode);
            let response = await this.create(`${this.props.hosting}/webservice/direccion/colonia/${nombre}`, objPostCode, true);
            //preguntar si se en la respuesta puede regresar el id del codigoPostalcreado creado
            if (response) {
                objFormCommerce.coloniaModelo = response.coloniaModeloLista[0].id;
                // console.log(response);
            }

        }
        let stateCommerce = objFormCommerce?.estado === "TRUE" ? false : true  
        /**ver si hay otra direccion fiscal */
        // let addAdress = objFormCommerce.direccionFiscalAlterna !== "S/D" ? objFormCommerce.direccionFiscalAlterna : false;
        const objCommerce = {
            nombre: objFormCommerce.nombre,
            tipoVialidadModelo: {
                id: objFormCommerce.tipoVialidadModelo
            },
            giroComercialModelo: {
                id: objFormCommerce.giroComercialModelo
            },
            nombreVialidad: objFormCommerce.nombreVialidad,
            numeroExterior: objFormCommerce.numeroExterior === "S/D" ? "S/N" : objFormCommerce.numeroExterior,
            numeroInterior: objFormCommerce.numeroInterior === "S/D" ? "S/N" : objFormCommerce.numeroInterior,
            coloniaModelo: { id: objFormCommerce.coloniaModelo.toLowerCase() },
            promotorModelo: { id: objFormCommerce.promotorModelo.toLowerCase() },
            inicioOperaciones: objFormCommerce.inicioOperaciones,
            tipoEmpresa: objFormCommerce.tipoEmpresa,
            actividadPrincipal: objFormCommerce.actividadPrincipalCrear !== "S/D" ? objFormCommerce.actividadPrincipalCrear : objFormCommerce.actividadPrincipal,
            trabajadorHombre: parseInt(objFormCommerce.trabajadorHombre),
            trabajadorMujer: parseInt(objFormCommerce.trabajadorMujer),
            facebook: objFormCommerce.facebook,
            twitter: objFormCommerce.twitter,
            direccionFiscal: objFormCommerce.direccionFiscal ? true : false,
            estado: objFormCommerce?.estado ? stateCommerce :  this.state.detailCommerce.estado 
        }
        // console.log(objCommerce);
        const objEntrevialidad = [{ nombre: objFormCommerce.nombreVialidadUno }, { nombre: objFormCommerce.nombreVialidadDos }];
        const objContacto = {
            nombre: objFormCommerce.nombreContacto.concat(":", objFormCommerce.puestoContacto),
            telefono: objFormCommerce.telefono,
            extension: objFormCommerce.extension,
            correoElectronico: objFormCommerce.correoElectronico,
            whatsapp: objFormCommerce.whatsapp
        }
        // console.log(objCommerce);
        try {
            let responseStreetOne, responseStreetTwo, responseContact, validateStreetOne, validateStreetTwo;
            this.setState({ spinnerUpdateCommerce: true });
            if (typeAdress && (typeAdress.id !== this.props.params.idCommerce) && objCommerce.direccionFiscal) {
                // console.log("entro cambio de direccion");
                let commerceData = await this.read(`${this.props.hosting}/webservice/comercio/${typeAdress.id}`);
                let objUpdateAdress = {
                    nombre: commerceData.nombre,
                    tipoVialidadModelo: {
                        id: commerceData.tipoVialidadModelo.id
                    },
                    giroComercialModelo: {
                        id: commerceData.giroComercialModelo.id
                    },
                    coloniaModelo: { id: commerceData.coloniaModelo.id},
                    nombreVialidad: commerceData.nombreVialidad,
                    numeroExterior: commerceData.numeroExterior,
                    numeroInterior: commerceData.numeroInterior,
                    promotorModelo: { id: commerceData.promotorModelo.id },
                    inicioOperaciones: commerceData.inicioOperaciones,
                    tipoEmpresa: commerceData.tipoEmpresa,
                    actividadPrincipal: commerceData.actividadPrincipal,
                    trabajadorHombre: commerceData.trabajadorHombre,
                    trabajadorMujer: commerceData.trabajadorMujer,
                    facebook: commerceData.facebook,
                    twitter: commerceData.twitter,
                    direccionFiscal: false,
                    estado: commerceData.estado
                }
                // console.log(objUpdateAdress);
                let resposeCommerce = await this.update(`${this.props.hosting}/webservice/comercio/${typeAdress.id}`, objUpdateAdress);
                if (!resposeCommerce) {
                    return 0;
                }


            }

            if (objCommerce.direccionFiscal) {
                let nameCommerce = objCommerce.nombre;
                    let adress = await this.read(`${this.props.hosting}/webservice/direccion/${objFormCommerce.codigoPostal}`);
                    let codePostcode = false;
                    if (adress) {
                        codePostcode = adress.coloniaModeloLista.filter(colonia => colonia.id === objCommerce.coloniaModelo.id);    
                    }
                    
                    let adressClient = {
                        nombreVialidad: objCommerce.nombreVialidad,
                        numeroExterior: objCommerce.numeroExterior,
                        numeroInterior: objCommerce.numeroInterior,
                        coloniaModelo:{nombre:codePostcode[0]?.nombre || ""},
                        municipio: adress.municipio || "",
                        estadoMunicipio: adress.estado || ""
                    }

                    dateClient(nameCommerce,adressClient);
            }
            let resposeCommerce = await this.update(`${this.props.hosting}/webservice/comercio/${this.props.params.idCommerce || this.state.detailCommerce.id}`, objCommerce);

            if (entreVialidad) {

                let datoUno = this.validateDataForm(objEntrevialidad[0], 1, entreVialidad[0]);
                let datoDos = this.validateDataForm(objEntrevialidad[1], 1, entreVialidad[1]);
                if (datoUno) {
                    responseStreetOne = await this.update(`${this.props.hosting}/webservice/entre-vialidad/${this.state.entreVialidad[0].id}`, objEntrevialidad[0]);
                }
                if (datoDos) {
                    responseStreetTwo = await this.update(`${this.props.hosting}/webservice/entre-vialidad/${this.state.entreVialidad[1].id}`, objEntrevialidad[1]);
                }

            } else {
                validateStreetOne = this.validateDataForm(objEntrevialidad[0], 0);
                validateStreetTwo = this.validateDataForm(objEntrevialidad[1], 0);
                if (validateStreetOne) {
                    objEntrevialidad[0].idComercio = this.props.params.idCommerce;
                    await this.create(`${this.props.hosting}/webservice/entre-vialidad`, objEntrevialidad[0]);
                }
                if (validateStreetTwo) {
                    objEntrevialidad[1].idComercio = this.props.params.idCommerce;
                    await this.create(`${this.props.hosting}/webservice/entre-vialidad`, objEntrevialidad[1]);
                }
            }

            if (datosContactos) {
                let datoContacto = this.validateDataForm(objContacto, 1, datosContactos);
                if (datoContacto) {
                    responseContact = await this.update(`${this.props.hosting}/webservice/datos-contacto/${this.props.params.idCommerce || this.state.detailCommerce.id}`, objContacto);
                }

            } else {
                let datoContacto = this.validateDataForm(objContacto, 0);
                if (datoContacto) {
                    await this.create(`${this.props.hosting}/webservice/datos-contacto/${this.props.params.idCommerce || this.state.detailCommerce.id}`, objContacto);
                }
            }

            // if (this.state.visibilityAddresBill && addAdress) {
            //     dataAdress = await this.update(`${this.props.hosting}/webservice/cliente/direccion/${this.props.params.idClient}`, { direccionFiscal: addAdress });

            // }
            if (resposeCommerce) {
                // console.log("todo bien");
                this.props.modal("Se actualizó el comercio correctamente, Desea realizar un pago", 1);
                this.refreshDetailCommerce();
                statusForm(true);
                this.props.enabledPay(objCommerce.estado);
                document.querySelector("input[name=estado]").checked = false;
                if (!(this.props.params.idCommerce)) {
                    evt.target.reset();
                    this.setState({
                        commerces: []
                    });
                    window.scroll(0, 0);
                    document.getElementById("promotor").value = "";
                }
                this.updateTypeCompany(objCommerce.tipoEmpresa);
                this.setState({ spinnerUpdateCommerce: false });

            }
        } catch (error) {
            // console.log(error);
        }
    }

    fillFormCommerce() {
        const { dateClient } = this.props.params;
        const { nombre, nombreVialidad, numeroInterior, numeroExterior, actividadPrincipal, trabajadorHombre, trabajadorMujer, direccionFiscal, inicioOperaciones, facebook, twitter, tipoEmpresa, tipoVialidadModelo, giroComercialModelo, promotorModelo, coloniaModelo } = this.state.detailCommerce;
        const nombreVialidad1 = this.state.entreVialidad ? this.state.entreVialidad[0].nombre : "S/D";
        const nombreVialidad2 = this.state.entreVialidad ? this.state.entreVialidad[1].nombre : "S/D";
        const { nombre: nombreContacto, telefono, extension, correoElectronico, whatsapp } = this.state.datosContactos;

        const puestoContacto = nombreContacto && nombreContacto.split(":")[1];
        const nombreDelContacto = nombreContacto && nombreContacto.split(":")[0];
        let position = [nombre, nombreVialidad, numeroInterior || "S/N", numeroExterior || "S/N", actividadPrincipal, parseInt(trabajadorHombre), parseInt(trabajadorMujer), inicioOperaciones, facebook, twitter, nombreVialidad1, nombreVialidad2, nombreDelContacto || "S/D", puestoContacto || "S/D", telefono || "S/D", extension || "S/D", correoElectronico || "S/D", whatsapp || "S/D", coloniaModelo?.codigoPostal || ""];
        // document.getElementById("promotor").value = `${promotorModelo.nombre} ${promotorModelo.apellidoPaterno} ${promotorModelo.apellidoMaterno}`;
        dateClient(nombre);
        let datosComercio = ["nombre", "nombreVialidad", "numeroInterior", "numeroExterior", "actividadPrincipal", "trabajadorHombre", "trabajadorMujer", "inicioOperaciones", "facebook", "twitter", "nombreVialidadUno", "nombreVialidadDos", "nombreContacto", "puestoContacto", "telefono", "extension", "correoElectronico", "whatsapp", "codigoPostal"];
        let form = this.formCommerce.current;
        let dates = Array.from(form.querySelectorAll("input"));
        let inputSelect = Array.from(form.querySelectorAll("select"));
        datosComercio.forEach(commerce => {
            let input = dates.find(input => commerce === input.name);
            let positionArray = datosComercio.findIndex(dtaComercio => commerce === dtaComercio);
            if (input) {
                input.value = position[positionArray] === undefined ? "S/D" : position[positionArray];
            }
        })
        /*if (direccionFiscal) {
            dates.find(input => "direccionFiscal" === input.name).checked = true;
        }*/
        switch (tipoEmpresa) {
            case "UNICA":
                dates.find(input => input.type === "radio" && input.value === "UNICA").checked = true;
                break;
            case "MATRIZ":
                dates.find(input => input.type === "radio" && input.value === "MATRIZ").checked = true;
                break;
            case "SUCURSAL":
                dates.find(input => input.type === "radio" && input.value === "SUCURSAL").checked = true;
                break;

            default:
                break;
        }
        let tipoVialidad = form.querySelector("select[name=tipoVialidadModelo]");
        let optionTipoVialidad = Array.from(tipoVialidad.querySelectorAll("option"));
        let indexVialidad = optionTipoVialidad.findIndex(option => option.value === tipoVialidadModelo.id);
        tipoVialidad.selectedIndex = indexVialidad;
        this.setState({ typeCompanyDefault: tipoEmpresa })
        // console.log(indexVialidad, tipoVialidadModelo.id);

    }

    async searchDetailsCommerce(param) {
        let detailCommerce = await this.read(`${this.props.hosting}/webservice/comercio/${param}`)
        let entreVialidad = await this.read(`${this.props.hosting}/webservice/entre-vialidad/${param}`);
        let datosContactos = await this.read(`${this.props.hosting}/webservice/datos-contacto/${param}`);
        return ({
            detailCommerce,
            entreVialidad,
            datosContactos
        });
    }

    async refreshDetailCommerce() {
        let detailCommerce = await this.read(`${this.props.hosting}/webservice/comercio/${this.props.params.idCommerce}`);
        this.setState(
            {
                detailCommerce
            }
        );
    }

    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && response.status === 200) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")

            }
        } catch (error) {
            data = false;

        } finally {
            return data;
        }

    }

    async update(url, contentBody) {
        let data, response;
        const objData = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true
            }
            // console.log(contentBody);

        } catch (error) {
            // console.log(error);
            // console.log(contentBody);
            data = false;
        } finally { return data }


    }

    async create(url, contentBody, pibote) {
        let data, response;
        const objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 201) {
                if (pibote) {
                    data = response.json();
                } else {
                    data = true
                }
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally { return data }
    }

    otherPostCode(evt) {

        if (this.state.postCodeAdd) {
            this.setState({ otherPostCode: evt.target.checked ? "enabled" : "disabled" });
            this.setState({ enabledOtherPost: evt.target.checked })
        } else if (evt.target.checked) {
            this.setState({ enabledOtherPost: true })
            this.props.modal("Favor de seleccionar primero el codigo postal", 2);
        }

    }

    validateTypeCompany(evt) {
        let element = evt.target, validateType = null;
        let parent = element.parentElement.parentElement.parentElement;
        const { typeCompany, typeCompanyDefault } = this.state;
        let pibote = typeCompany.length;
        if (pibote > 1 && element.checked === true) {
            switch (element.value) {
                case "UNICA":
                    element.checked = false;
                    this.props.modal("No se puede asignar este campo ya que el cliente tiene mas de un comercio", 2);
                    parent.querySelector(`input[value=${typeCompanyDefault}]`).checked = true;
                    break;
                case "SUCURSAL":
                    if (typeCompanyDefault === "MATRIZ") {
                        this.props.modal("El cliente se quedara sin su comercio matriz", 2);
                    }
                    break;
                case "MATRIZ":
                    if (typeCompanyDefault === "SUCURSAL" && typeCompany.includes("MATRIZ")) {
                        element.checked = false;
                        this.props.modal("No se puede asignar este campo ya que existe un comercio con el tipo de empresa \"Matriz\"", 2);
                        parent.querySelector(`input[value=${typeCompanyDefault}]`).checked = true;
                    }
                    break;

                default:
                    break;
            }
        }


    }

    async updateTypeCompany(typeDefault) {
        let type = await this.read(`${this.props.hosting}/webservice/comercio/tipoEmpresa/${this.props.params.idClient}`);
        this.assingTypeCompanyDefault(type);
        if (typeDefault !== "UNICA") {
            this.props.params.dateClient(false,false,true);
        }else {
            this.props.params.dateClient(false,false,"false");
        }
        this.setState({
            typeCompany: type || [],
            typeCompanyDefault: typeDefault
        });
       
    }

    assingTypeCompanyDefault(arrayType) {
        //console.log(arrayType);
        let typeCompanyDefault = arrayType ? (arrayType.includes("UNICA") ? "invalida" : "SUCURSAL") : "UNICA";
       
        this.setState({ typeCompanyDefault });
    }

    validateDataForm(objData, option, objCompare) {
        let pibote = 0
        switch (option) {
            case 0:
                for (const key in objData) {
                    if (objData[key] !== "S/D") {
                        pibote++
                    }
                }
                break;
            case 1:
                for (const key in objData) {
                    if (objCompare.hasOwnProperty(key)) {
                        if (objCompare[key] !== objData[key])
                            pibote++
                    }
                }
                break;
            default:
                break;
        }

        return (pibote > 0);
    }

    blockCommerce(event){
        let element = event.target;
        if(element.checked && element.value === "TRUE"){
            this.props.modal("Al bloquear este comercio no se le podran realizar mas pagos",2);
        }

    }
}

export default FormComercio;