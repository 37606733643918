import React,{Component} from "react";
import Title from "../Atoms/title";
import Button from "../Atoms/button";
import BoxInput from "../Molecules/box-input";
import Paginacion from "../Templates/paginacion";

class FormPromotor extends Component {
    constructor(props){
        super(props);
        this.state = {
            vendor:[0],
            inputVendorId:""
        }
    }
    render(){
        const {vendor} = this.state
        return(
            <section >
                            <Title type="title" content="Actualizar Promotor" />
                            <form className="margin-large-top-3 grid-layout grip-gap grid-2" onSubmit={(evt) => { this.searchVendor(evt) }}>
                                <BoxInput typeBox="input" icon="Nombre Promotor" styleClass="form__label" >
                                    <input id="promotor" type="text" name="promotor" className="form__input form__normal" />
                                </BoxInput>
                                <div>
                                    <Button styleClass="button__primary button--size-medium" text="Buscar" />
                                </div>

                            </form>
                            <form onChange={(evt) => { this.selectVendor(evt) }} ref={this.formVendorTwo}>
                                {
                                    vendor[0] === 0 ?
                                        <></>
                                        :
                                        <Paginacion numberElement={2}>
                                            {
                                                vendor.length > 0 ?
                                                    vendor.map((element, index) =>
                                                        <div key={index} className="margin-medium-top-2">
                                                            <BoxInput value={`${element.nombre}:${element.apellidoPaterno}:${element.apellidoMaterno}:${element.id}`} typeBox="checkbox" typeInput="radio" icon={`${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`} styleClass="form__label" statusInput="form__normal" nameInput="vendorUpdate" />
                                                        </div>)
                                                    :
                                                    [<p className="margin-medium-top-2" style={{ fontWeight: "bold" }}>NO EXISTEN COINCIDENCIAS EN LA BUSQUEDA</p>]
                                            }
                                        </Paginacion>
                                }
                            </form>
                            <form id="form-vendor" className="margin-large-top-3" onSubmit={(evt) => { this.updateVendor(evt) }}  >
                                <div className="grid-layout grip-gap grid-3">
                                    <BoxInput typeBox="input" typeInput="text" icon="Nombre" styleClass="form__label" >
                                        <input type="text" name="nombre" className="form__input form__normal" required={true}/>
                                    </BoxInput>
                                    <BoxInput typeBox="sample" typeInput="text" icon="Apellido Paterno" styleClass="form__label" statusInput="form__normal" nameInput="apellidoPaterno" habilitado={true} />
                                    <BoxInput typeBox="sample" typeInput="text" icon="Apellido Materno" styleClass="form__label" statusInput="form__normal" nameInput="apellidoMaterno" />
                                    <input type="hidden" name="idVendor" value={this.state.inputVendorId} required={true} /> 
                                </div>
                                <Button styleClass="margin-medium-top-3 button__primary button--size-medium" text="Actualizar" />
                            </form>
                        </section>
        );

    }

    selectVendor(evt) {
        let formVendor = document.getElementById("form-vendor");
        const [nombre, apellidoPaterno, apellidoMaterno, id] = evt.target.value.split(":");
        let nodosInput = formVendor.querySelectorAll("input");
        nodosInput[0].value = nombre;
        nodosInput[1].value = apellidoPaterno;
        nodosInput[2].value = apellidoMaterno;
        if (evt.target.name === "vendorUpdate") {
            this.setState(
                {
                    inputVendorId: id
                }
            );
        }
    }
    async searchVendor(evt) {
        evt.preventDefault();
        this.setState({
            vendor: [0]
        });
        let formVendor = new FormData(evt.target);
        let vendorDate = await this.read(`${this.props.hosting}/webservice/promotor?estado=true&palabra=${formVendor.get("promotor")}`) || []
        this.setState({
            vendor: vendorDate
        });
        if (document.getElementById("form-vendor")){
            document.getElementById("form-vendor").reset();
        }

    }

   async updateVendor(evt) {
        evt.preventDefault();
        let formVendor = new FormData(evt.target);
        let objVendor = {}, idVendor = formVendor.get("idVendor");
        formVendor.delete("idVendor");
        for (const pair of formVendor.entries()) {
            objVendor[pair[0]] = this.validateForm(pair[1].toUpperCase());
        }
       let responseUpdateVendor = await this.update(`${this.props.hosting}/webservice/promotor/${idVendor}`,objVendor);
       if (responseUpdateVendor) {
           this.setState({
               vendor:[0]
           }); 
           if (document.getElementById("form-vendor")){
            document.getElementById("form-vendor").reset();
            }
            this.props.modal("Se actualizo el promotor correctamente",1);
       }
    }

   
    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && response.status === 200) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")
            }
        } catch (error) {
            data = false;

        } finally {
            return data;
        }

    }

    async update(url, contentBody) {
        let data, response;
        const objData = {
            method: "PUT",
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true
            }

        } catch (error) {
            console.log(error);
            data = false;
        } finally { return data }
    }

    validateForm(data) {
        return data === "" ? "S/D" : data;
    }
}

export default FormPromotor;