import React, { Component } from "react";

class Dropdawn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classVisible:"visible-hidden",
            enabled: false
        }

    }
    render() {
        return (

            <label className={`dropdawn ${this.props.styleClass || ""}`} >
                <span onClick={()=>{this.enabledDropdawn()}}>{this.props.icon}</span>
                <ul className={this.state.classVisible} onClick={()=>{this.enabledDropdawn()}}>
                    {this.props.children}
                </ul>
            </label>

        );
    }

    enabledDropdawn(){
        if (!this.state.enabled) {
            this.setState(
                {
                    classVisible:"visible-element",
                    enabled:true
                }
            );    
        }else{
            this.setState(
                {
                    classVisible:"visible-hidden",
                    enabled:false
                }
            );    
        }
        
    }

}

export default Dropdawn;