import './styles.css';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import Login from './components/Pages/login';
import Dashoard from './components/Pages/dashboard';
import Registro from './components/Pages/registro';
import Pagos from "./components/Pages/pagos";
import SinAcceso from './components/Pages/sin-acceso';
import Actualizacion from "./components/Pages/actualizacion";
import Detalles from './components/Pages/detalles';
import Pruebas from './components/Pages/pruebas';


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={(props) => <Login {... props} hosting={process.env.REACT_APP_DOMAIN} img="canacope-logo.png" />} />
        <Route path="/dashboard" component={(props)=> <Dashoard {...props} hosting={process.env.REACT_APP_DOMAIN} />} /> 
        <Route path="/registro/:name/:id" component={(props)=> <Registro {... props} hosting={process.env.REACT_APP_DOMAIN}  />} />
        <Route path="/registro" component={(props)=> <Registro {... props} hosting={process.env.REACT_APP_DOMAIN}  />} />
        <Route path="/pagos/:name/:id" component={(props)=> <Pagos {...props} hosting={process.env.REACT_APP_DOMAIN}  />} />
        <Route path="/actualizacion/:idClient" component={(props)=><Actualizacion {...props}  hosting={process.env.REACT_APP_DOMAIN} />} />
        <Route path="/actualizacion" component={(props)=> <Actualizacion {...props} hosting={process.env.REACT_APP_DOMAIN}  />} />
        <Route path="/detalles/:idCommerce/:idClient" component={(props)=><Detalles {...props} hosting={process.env.REACT_APP_DOMAIN}  />} />
        <Route path="/pruebas" component={Pruebas} />
        <Route path="/sin-acceso" component={()=><SinAcceso text="Una sesión esta abierta" textButton="Cerrar sesión" type={true} />} />
        <Route component={()=>{
          return <p>Error</p>
        }}/>
      </Switch>
    </Router>
  );
}

export default App;
