import React from "react";

function Spinner({text}) {
    return(
        <span style={{display:"inline-flex",alignItems:"center"}}>
            <span className="loader margin-large-left-3"></span>
            <span style={{fontSize:"20px",display:"inline-block",fontWeight:"900"}} className="margin-medium-left-3">{text}....</span>
        </span>
    );    
}
export default Spinner;