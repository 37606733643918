import React, { Component } from "react";
import SinAcceso from "../Pages/sin-acceso";

function withLogin(WrappedComponent){
    return class WithLoginComponent extends Component {
        constructor(props){
            super(props);
            this.state = {
                logged: sessionStorage.getItem("sesion"),
                session: localStorage.getItem("iniciada"),
            }
        }
        render(){
            const {logged,session,sesionUser} = this.state;
            const option = logged === null || session === null ? false : (logged === session ? true : false);
            return option ? <WrappedComponent {...this.props} /> : <SinAcceso type={false} textButton="Salir" />;
        }
    }
}

export default withLogin;