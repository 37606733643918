import React, { Component } from "react";
import Button from "../Atoms/button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

class PaginacionTabla extends Component {
    constructor(props) {
        super(props);
        
      
    }
    render() {
        const { page } = this.props;
        return (                
                    this.props.children.slice(page, (page + this.props.numberElement))
        );
    }
    
    

}

class ButtonPagination extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
        <div className={`margin-medium-top-2 ${this.props.numberChild> this.props.numberElement ? "enabled" : "disabled"}`} onClick={(evt) => { this.drawPages(evt) }}>
            <Button styleClass={`${this.props.page <= 0 ? "button__disabled" : "button__primary"} button--size-medium margin-medium-right-3`} text={<FaArrowLeft />} typeButton="button" reference="left" disabled={this.props.page <= 0} />
            <span className="margin-medium-right-3 table__pagination">
                {`${Math.floor(this.props.page/this.props.numberElement) + 1}/${Math.floor(this.props.numberChild/this.props.numberElement) + 1}`}
            </span>
            <Button styleClass={`${this.props.page >= (this.props.numberChild - this.props.numberElement) ? "button__disabled" : "button__primary"} button--size-medium`} text={<FaArrowRight />} typeButton="button" reference="right" disabled={this.props.page >= (this.props.numberChild - this.props.numberElement)}  />
        </div>
        )
    }

    drawPages(evt) {
        let element = evt.target;
        let reference = null;
        let nameTag = element.nodeName.toUpperCase();
        if (nameTag === "BUTTON" || nameTag === "SVG" || nameTag === "PATH" ) {
          reference = nameTag === "BUTTON" ? element : nameTag === "SVG" ? element.parentElement : element.parentElement.parentElement;
          let option = reference.dataset.reference;
          if (option && !(reference.disabled)) {
            switch (option) {
                case "left":
                   this.props.paginationState(this.props.page - this.props.numberElement );
                    break;
                case "right":
                    // this.setState((state) => {
                    //     return { page: state.page + this.props.numberElement }
                    // }
                    // );
                    this.props.paginationState(this.props.page + this.props.numberElement );
                    break;

                default:
                    break;
            }
        } 
        }
        
        
        

    }
    
}

export {PaginacionTabla as default,ButtonPagination}