import React, { Component } from "react";
import { FaRegFrownOpen } from "react-icons/fa";
import PaginacionTabla, { ButtonPagination } from "./paginacion-tabla";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
        }
        this.numberElement = 20;
    }

    render() {
        const { tableData, tableHeader} = this.props;
        
        return (
            <>
                <table className="table">
                    <thead className="table__head">
                        <tr>
                            {
                                tableHeader.map((head, index) => <th key={index} >{head}</th>)
                            }

                        </tr>
                    </thead>
                    <tbody className="table__body">
                        <PaginacionTabla numberElement={this.numberElement} page={this.state.page} >
                            {
                                tableData ? tableData.map((row, index) => (
                                    <tr key={index}>
                                        {Object.values(row).map(
                                            (colum, index) => <td key={index}>{colum}</td>
                                        )}
                                    </tr>
                                )) : [<tr key="uno" style={{ height: "300px" }} >
                                        <td colSpan={8}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                                <FaRegFrownOpen className="icon__empty-table" />
                                                <p className="margin-large-top-3 table__message-empty">Sin datos</p>
                                            </div>
                                        </td>
                                    </tr>]
                            }
                        </PaginacionTabla>
                    </tbody>

                </table>
                <ButtonPagination numberElement={this.numberElement} numberChild={this.props.elementTable} page={this.state.page} paginationState={(page) => {
                    this.setState({ page });
                }} />
            </>


        );
    }
   
    elementChild(){
        this.setState({numberChild:this.props.tableData.length})
    }
}

export default Table;