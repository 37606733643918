import React,{Component} from 'react';
import PropTypes from 'prop-types';
class BoxInput extends Component{
    constructor(props){
        super(props);
    }
    render(){
        switch (this.props.typeBox) {
            case "sample":
                return(
                    <label className="content-element__form-sample">
                        <span className={this.props.styleClass}>{this.props.icon}</span><input className={`form__input ${this.props.statusInput}`} type={this.props.typeInput} placeholder={this.props.defaultValue} data-default-value={this.props.defaultValue} required={this.props.habilitado} disabled={this.props.disabled} readOnly={this.props.disabledInput} name={this.props.nameInput} autoComplete="nope" minLength={this.props.minLength} maxLength={this.props.maxLength}/>
                    </label>                    
                );
            case "description":
                return(
                    <label className="content-element__form-description">
                        <span className={this.props.styleClass}>{this.props.icon}</span><input className={`form__input ${this.props.statusInput}`} type={this.props.typeInput} disabled={this.props.disabled} readOnly={this.props.disabledInput} required={this.props.habilitado} name={this.props.nameInput} placeholder={this.props.defaultValue} data-default-value={this.props.defaultValue} autoComplete="nope" minLength={this.props.minLength} maxLength={this.props.maxLength} /><span className="form__description">{this.props.description}</span>
                    </label>                    
                );    
            case "checkbox":
                return(
                    <span className="content-element__form-checkbox">
                        <label className="form__checkbox form__normal">
                            <input style={{display:"none"}} value={this.props.value} readOnly={this.props.disabledInput}   type={this.props.typeInput} name={this.props.nameInput} disabled={this.props.disabledInput} />
                            <span className="checkmark"></span>
                        </label>
                        <span className={this.props.styleClass}>{this.props.icon}</span>
                    </span>
                )
            case "input":
                return(
                    <label className="content-element__form-description">
                        <span className={this.props.styleClass}>{this.props.icon}</span>
                        {this.props.children}
                        
                    </label>
                )            
            default:
                break;
        }
        
    }
}

BoxInput.propTypes = {
    habilitado: PropTypes.bool
}
BoxInput.defaultProps = {
    habilitado: false
}
export default BoxInput;