import React from "react";
function Title(props) {
    switch (props.type) {
        case "title":
            return (
                <h1 id={props.id} className={`titles ${props.styleClass}`} >{props.content}</h1>
            );
        case "subtitle":
            return(
                <h3 id={props.id} className="subtitles">{props.content}</h3>
            );    
    
        default:
            break;
    }
}

export default Title;