import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../Atoms/button";
import Title from "../Atoms/title";
import BoxInput from "../Molecules/box-input";
import Spinner from "../Molecules/spinner";
import Header from "../Organisms/header";
import Tabla from "../Templates/tabla";
import FormComercio from "../Templates/form-comercio";
import ModalDos, { ButtonModal } from "../Templates/modal-dos";
import { FaMoneyCheckAlt, FaEllipsisV, FaDollarSign, FaTrashAlt } from "react-icons/fa";
import Dropdawn from "../Molecules/drop-down";
import withLogin from "../HOC/withLogin";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReciboPago from "../Templates/recibo-pago";


class Detalles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cliente: {},
            adressClient: false,
            typeAdress: false,
            visibilityModal: "hidden",
            openModal: "",
            disabledInput: true,
            dataTable: [],
            loadPage: true,
            classLoad: "disabled",
            optionButton: 1,
            payId: "",
            stateClient: true,
            statePay: 0,
            nameCommerce: "",
            numberPhone: "",
            allCommerce: 0,
            //reporte pago
            conceptoPago: [],
            cantidadPago: 2000,
            buttonPDF: false,
            //boton agregar comercio
            addCommerce: true,
            //estado del comercio
            stateCommerce:false

        }
        this.buttonsModalOne = <><Link className="button button__primary button--size-small margin-medium-right-3" to={`/pagos/commerce/${this.props.match.params.idCommerce}:${this.props.match.params.idClient}`}>Pagar</Link>
            <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__secundary" >Salir</ButtonModal></>
        this.buttonsModalTwo = <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__primary" >Aceptar</ButtonModal>
        this.formAddBill =
            <form onSubmit={(evt) => { this.billAdd(evt) }}>
                <p>Ingresar el numero de factura</p>
                <div className="margin-medium-top-3">
                    <BoxInput typeBox="sample" typeInput="number" icon="Factura" styleClass="form__label" statusInput="form__normal" nameInput="factura" />
                </div>
                <div className="flex margin-medium-top-3" style={{ justifyContent: "center" }}>
                    <Button text="Aceptar" styleClass="button__primary button--size-medium  margin-medium-right-3" />
                    <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__secundary" >Salir</ButtonModal>
                </div>

            </form>;
        this.confirmActionDelete =
            <div>
                <p>¿Está seguro que desea borrar este pago?</p>
                <div className="flex margin-medium-top-3" style={{ justifyContent: "center" }}>
                    <Button text="Aceptar" typeButton="button" styleClass="button__primary button--size-medium  margin-medium-right-3" method={() => { this.deletePay() }} />
                    <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__secundary" >Salir</ButtonModal>
                </div>
            </div>;
    }

    render() {
        const { nombre: nombreCliente, rfc, apellidoPaterno, apellidoMaterno, id: numeroCliente, estado, moral, telefono } = this.state.cliente;
        const { loadPage, stateClient, statePay, nameCommerce, allCommerce, adressClient, typeAdress, buttonPDF, addCommerce ,stateCommerce } = this.state;
        return (
            <>
                <Header />
                {
                    loadPage ? <p className="margin-large-top-3"><Spinner text="Cargando" /></p> : <></>
                }
                <main style={{ margin: "30px 30px 30px 200px" }} className={`details-content ${this.state.classLoad}`}>


                    <Title type="title" content="Cliente" styleClass="margin-large-top-3" />
                    <p className="margin-medium-top-2">
                        <span className="label-detail" >Número de Cliente:</span> {numeroCliente}
                    </p>
                    <p className="margin-medium-top-2"><span className="label-detail">RFC:</span> {rfc} </p>
                    <p className="margin-medium-top-2"><span className="label-detail" >Nombre o razón social:</span> {nombreCliente ? `${apellidoPaterno} ${apellidoMaterno} ${nombreCliente}` : moral}</p>
                    {
                        adressClient ?
                            <p className="margin-medium-top-2"><span className="label-detail">Dirección fiscal:</span>{`${adressClient.nombreVialidad} ${adressClient.numeroExterior || ""} ${adressClient.numeroInterior || ""}  ${adressClient.coloniaModelo.nombre || ""} ${adressClient.municipio || ""} ${adressClient.estadoMunicipio || ""}`}</p>
                            :
                            <p className="margin-medium-top-2"><span className="label-detail">Dirección fiscal:</span> S/D</p>
                    }
                    <p className="margin-medium-top-2"><span className="label-detail">Estado del cliente:</span> {stateClient ? "Activo" : "Bloquedo"}</p>
                    <p className="margin-medium-top-2"><span className="label-detail">Teléfono:</span> {telefono || "S/D"}</p>
                    <p className="margin-medium-top-2"><span className="label-detail">Tipo de Pago del comercio:</span> <span className="label-detail" style={{ fontSize: "20px" }}>{statePay > 1 ? "Renovación" : "Nuevo"}</span></p>
                    <div className="flex margin-medium-top-2">
                        <p><span className="label-detail" >Número de comercios:</span> {allCommerce}</p>
                        {
                            allCommerce > 1 ? <Link to={`/pagos/client/${this.props.match.params.idClient}`} className={`button button__primary link margin-medium-left-3`}><FaDollarSign /> Pagar todos</Link> : <></>
                        }

                    </div>
                    {
                      addCommerce &&  <p className="margin-medium-top-2 label-detail" style={{textDecoration:"2px solid underline"}} >No se pueden agregar mas comercios a este cliente</p>
                    }
                    <Link to={`/actualizacion/${this.props.match.params.idClient}`} className="button button__primary button--size-medium margin-medium-top-3 link">Actualizar Cliente</Link>
                    {
                        !(addCommerce) && <Link to={`/registro/${nombreCliente ? `${apellidoPaterno} ${apellidoMaterno} ${nombreCliente}` : moral}/${this.props.match.params.idClient}`} className="button button__primary button--size-medium margin-medium-top-3 margin-medium-left-3 link">Agregar comercio</Link>
                    }
                    
                    <Button text={stateClient ? "Desactivar" : "Activar"} styleClass={`${stateClient ? "button__danger" : "button__active"} button--size-medium margin-medium-top-3 margin-medium-left-3`} method={() => { this.changeStatusClient() }} />
                    <Title type="title" content={`Comercio: ${nameCommerce} `} styleClass="margin-large-top-3" />
                    <div className="margin-medium-top-3" onChange={(evt) => { this.setState({ disabledInput: !(evt.target.checked) }) }}>
                        <BoxInput typeBox="checkbox" typeInput="checkbox" nameInput="actualizar" icon="Actualizar" styleClass="form__label" />
                    </div>
                    <FormComercio hosting={this.props.hosting} params={{ idCommerce: this.props.match.params.idCommerce, type: "details", estadoCliente: stateClient, idClient: this.props.match.params.idClient, dateClient: (nameCommerce, adressClient, addCommerce) => this.changeData(nameCommerce, adressClient, addCommerce), typeAdress,statusForm:(option)=>{this.disabledForm(option)} }} enabledPay={(data)=>{this.enabledPay(data)}} modal={(message, option) => { this.activeModal(message, option) }} disabledInput={this.state.disabledInput} loadPage={() => { this.loadPage() }} />
                    <div className="separador__comcepto-pago"></div>
                    { stateCommerce &&  <p style={{ fontWeight: "700" }} className="margin-large-top-3">¿Desea realizar un pago a este negocio?   <Link className="button button__primary button--size-medium" to={`/pagos/commerce/${this.props.match.params.idCommerce}:${this.props.match.params.idClient}`}>Realizar pago</Link></p>
                    }
                    <Title type="title" content="Historial de pagos" styleClass="margin-large-top-3" />
                    <div className="margin-medium-top-3">
                        <Tabla tableHeader={["Concepto", "Fecha de registro", "Año de concepto", "importe", "Recibo", "Engomado", "Factura", "Opciones"]} tableData={this.state.dataTable} elementTable={this.state.dataTable.length} />
                    </div>

                </main>
                <ModalDos stateModal={this.state.visibilityModal} optionContent={this.state.optionButton} modalOpen={this.state.openModal} sizeModal="modal__size--small" text={this.state.messageModal} buttonsModalOne={this.buttonsModalOne} buttonsModalTwo={this.buttonsModalTwo} />
                <ButtonModal option={true} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="disabled" id="btn-modal" >Probar</ButtonModal>
                <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="disabled" id="btn-modal-close" >Probar</ButtonModal>
                {
                    buttonPDF || <></>
                }


            </>
        );
    }

    async componentDidMount() {
        let cliente = await this.read(`${this.props.hosting}/webservice/cliente/${this.props.match.params.idClient}`);
        let commerces = await this.read(`${this.props.hosting}/webservice/comercio/${this.props.match.params.idClient}?activo=true`);
        let typeCompany = await this.read(`${this.props.hosting}/webservice/comercio/tipoEmpresa/${this.props.match.params.idClient}`);
        let addCommerce = typeCompany ? typeCompany.includes("UNICA"): false;
        let adressClient = false, typeAdress = false;
        if (cliente.clienteDireccionFiscalModelo) {
            adressClient = cliente.clienteDireccionFiscalModelo;

        } else {
            let responseAdress = await this.read(`${this.props.hosting}/webservice/comercio/direccionFiscal/${this.props.match.params.idClient}`);
            adressClient = responseAdress;
            typeAdress = responseAdress;
        }

        if (adressClient) {
            for (const property in adressClient) {
                adressClient[property] = adressClient[property] === "S/D" || adressClient[property] === "S/N" ? false : adressClient[property];
            }
        }
        this.setState({
            cliente,
            adressClient,
            typeAdress,
            stateClient: cliente.estado,
            allCommerce: commerces.length,
            addCommerce
        })
        this.loadPage();
        let pagos = await this.read(`${this.props.hosting}/webservice/pago/${this.props.match.params.idCommerce}`);
        if (pagos) {
            this.setState({ statePay: pagos.length });
        }

        this.writeTablePay(pagos);


    }
    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && (response.status === 200 || response.status === 205)) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")
            }
        } catch (error) {
            // console.log(error);
            data = false;

        } finally {
            return data;
        }

    }

    async update(url, contentBody) {
        let data, response;
        const objData = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally { return data }


    }

    async modify(url, contentBody) {
        let data, response;
        const objData = {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally { return data }


    }

    async delete(url, object) {
        let response = null, data = null;
        const objData = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object),
        };

        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true;
            } else {
                data = false;
                throw new Error("messageError");
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally {
            return data;
        }

    }

    async updateDataTablePay() {
        let pagos = await this.read(`${this.props.hosting}/webservice/pago/${this.props.match.params.idCommerce}`);
        this.writeTablePay(pagos);
    }

    changeStatusClient() {
        let bodyModal =
            <>
                {
                    this.state.stateClient ?
                        <>
                            <p>Al bloaquear a un cliente se desactivan todos sus comercio</p>
                            <p>Esta seguro de continuar</p>
                        </>
                        :
                        <p>Se volvera a activar el cliente junto con sus comercios</p>
                }

                <div className="flex margin-medium-top-3" style={{ justifyContent: "center" }}>
                    <Button method={() => { this.disableEnabledClient() }} text="Aceptar" styleClass="button__primary button--size-medium  margin-medium-right-3" />
                    <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__secundary" >Salir</ButtonModal>
                </div>
            </>
        this.activeModal(bodyModal);
    }

    async disableEnabledClient() {
        let actualState = this.state.stateClient;
        let obj = { estado: !(actualState) };
        let response = await this.update(`${this.props.hosting}/webservice/cliente/estado/${this.props.match.params.idClient}`, obj);
        if (response) {
            this.activeModal(`${!actualState ? "Se activo el comercio" : "Se desactivo el comercio"}`, 2);
            this.setState({ stateClient: !actualState })
        } else {
            this.activeModal("Ocurrio un error", 2);
        }
    }

    activeModal(message, option) {
        this.setState({
            messageModal: message,
            optionButton: option
        });
        document.getElementById("btn-modal").click();
    }

    writeTablePay(pagos) {
        let dataTable = [];
        if (pagos) {
            let contentTable = pagos.map(colum => {
                const { id: recibo, pago: fechaRegistro, comercioPagoModeloLista, factura } = colum;
                let pagosRecibo = comercioPagoModeloLista.map(pago => ({ anioConcepto: pago.anioConcepto, conceptoModelo: pago.conceptoModelo.concepto, importe: pago.importe, engomado: pago.engomado || "S/D" }));
                return (
                    {
                        recibo,
                        factura,
                        fechaRegistro,
                        pagosRecibo

                    }
                );
            });
            contentTable.forEach(colum => {
                const { recibo, fechaRegistro, factura } = colum;
                colum.pagosRecibo.forEach(pago => {
                    const obj = {
                        concepto: pago.conceptoModelo,
                        fechaRegistro,
                        anio: pago.anioConcepto,
                        importe: pago.importe,
                        recibo,
                        engomado: pago.engomado,
                        factura: factura ? factura : "S/D",
                        facturaAdd:
                            <Dropdawn icon={<FaEllipsisV />}>
                                <li data-id-pay={recibo} className="element-hover" onClick={(evt) => { this.openFormModal(evt, 1) }}><FaMoneyCheckAlt className="margin-small-right-3" />Agregar factura</li>
                                <li data-id-pay={recibo} className="element-hover" onClick={(evt) => { this.createDocumentPDF(evt.target.dataset.idPay) }}><FaMoneyCheckAlt className="margin-small-right-3" />Imprimir recibo</li>
                                <li><Link style={{ color: "black" }} className="link" to={`/pagos/update/${this.props.match.params.idCommerce}:${recibo}`}><FaDollarSign className="margin-small-right-3" />Actualizar pago</Link></li>
                                <li data-id-pay={recibo} className="element-hover" onClick={(evt) => { this.openFormModal(evt, 2) }}><FaTrashAlt className="margin-small-right-3" /> Eliminar pago</li>
                            </Dropdawn>
                    }
                    dataTable.push(obj)
                })
            });
        } else { dataTable = false; }


        this.setState({ dataTable })
    }

    loadPage() {
        this.setState({ loadPage: false, classLoad: "enabled" })
    }
    async billAdd(evt) {
        evt.preventDefault();
        let element = evt.target;
        let formBill = new FormData(element);
        let obj = { factura: parseInt(formBill.get("factura")) }
        //   console.log(JSON.stringify(obj));
        let response = await this.modify(`${this.props.hosting}/webservice/pago/factura/${this.state.payId}`, obj);
        if (response) {
            document.getElementById("btn-modal-close").click();
            this.updateDataTablePay();
        }
        // console.log(obj);
    }
    async deletePay() {
        // console.log("entro eliminar pago");
        let response = await this.delete(`${this.props.hosting}/webservice/pago/${this.state.payId}`);
        if (response) {
            document.getElementById("btn-modal-close").click();
            this.updateDataTablePay();
        }
    }
    openFormModal(evt, option) {
        let element = evt.target;
        element = element.nodeName.toUpperCase() === "LI" ? element : element.parentElement;
        // console.log(element.dataset.idPay);
        this.setState(
            {
                payId: element.dataset.idPay
            });
        switch (option) {
            case 1:
                this.activeModal(this.formAddBill);
                break;
            case 2:
                this.activeModal(this.confirmActionDelete);
                break;
            default:
                break;
        }
    }

    changeData(nameCommerce, adressClient, addCommerce) {
        //console.log(addCommerce);
        let obj = {};
        if (nameCommerce) {
            obj.nameCommerce = nameCommerce;
        }
        if (adressClient) {
            obj.adressClient = adressClient;
        }
        if (addCommerce) {
            //console.log(addCommerce);
            obj.addCommerce = addCommerce === "false" ? true : !addCommerce;
        }
        this.setState(obj);
    }
    enabledPay(stateCommerce){
        this.setState({stateCommerce});
    }

    disabledForm(option){
        if (option) {
            document.querySelector("input[name=actualizar]").checked = false
            this.setState({disabledInput:true});
            
        }
    }
    async createDocumentPDF(recibo) {
        let conceptoPago = await this.read(`${this.props.hosting}/webservice/impresion/pago/${recibo}`);
        if (conceptoPago) {
            let cantidadPago = this.countBill(conceptoPago);
            this.setState({
                conceptoPago,
                cantidadPago
            });
            this.downloadPFD();
        }

    }

    countBill(arrayBill) {
        return arrayBill.reduce((accumulator, currentvalue) => accumulator + currentvalue.importe, 0);
    }

    downloadPFD() {
        const { cantidadPago, conceptoPago } = this.state;

        let adress = `${validateData(conceptoPago[0]?.tipoVialidad) || ""} ${ validateData(conceptoPago[0]?.nombreVialidad)  || ""} ${validateData(conceptoPago[0]?.numeroExterior) || ""} ${validateData(conceptoPago[0]?.numeroInterior)  || ""}`;

        let exr = new RegExp("{|}", 'g');
        let conceptoRecibo = conceptoPago.map(value => {
            let anioConcepto = value.anioConcepto.replace(exr, "").split(",");
            let setAnios = new Set();
            anioConcepto.forEach((anio) => {
                setAnios.add(anio);
            });
            let stringAnios = Array.from(setAnios).join(",");
            let concepto = value.concepto.toUpperCase() === "AFILIACION" ? `${value.concepto} ${stringAnios}` : `registro ${value.concepto} ${stringAnios}`;
            return concepto.toUpperCase();
        });
        let engomado = conceptoPago.filter(pago => pago.concepto.toUpperCase() === "SIEM")[0]?.engomado.replace(exr,"").split(",").map(data => parseInt(data));
        let arrayDay = conceptoPago[0].fechaPago?.split("-");
        let fecha = arrayDay && `${arrayDay[2]}-${arrayDay[1]}-${arrayDay[0]}`;
        let dateEngomado = engomado ? (engomado.length > 1  ? ordenarData(engomado) : engomado[0]):false;
        let datosPDF = {
            rfc: conceptoPago[0].rfc,
            nombre: conceptoPago[0].nombre,
            municipio: conceptoPago[0].municipio,
            estado: conceptoPago[0].estado,
            colonia: conceptoPago[0].colonia,
            telefono: conceptoPago[0].telefono,
            codigoPostal: conceptoPago[0].codigoPsotal,
            direccion: adress,
            conceptoRecibo,
            engomado: dateEngomado,
            fecha
        }

        //console.log(conceptoPago, datosPDF, cantidadPago);

        this.setState(
            {
                buttonPDF: <PDFDownloadLink document={<ReciboPago cantidad={cantidadPago} conceptos={datosPDF} />} fileName="Recibo.pdf" className="disabled btn-pdf">
                    Descargar
            </PDFDownloadLink>
            });

        setTimeout(() => { document.querySelector(".btn-pdf").click() }, 400);
        function validateData(data) {
            return data ? (data === "S/D" || data === "S/N" ? false : data) : false;  
        }

        function ordenarData(array) {
            let pibote = true,start,end;
            while (pibote) {
                let count = 0;
                array.forEach((data,index)=>{
                    let nextData = array[index + 1] || false;
                   if ( nextData && data > nextData) {
                    array[index + 1] = data;
                    array[index] = nextData;
                    count++;
                   }
                });

                pibote = count > 0; 
            }

            start = array[0];
            end= array[array.length - 1];

            let result = end - start;
            
            let sumData = array.reduce((previus,current) => previus+current);
            let serializable =  start * (result + 1) + ((result * (result + 1))/2);

            return sumData === serializable ? `${start}-${end}` : array.join(",");
        }

    }
}

export default withLogin(Detalles);