import React, { Component } from "react";
import logo from "../../canacope-logo.png"
import { FaKey, FaUser } from "react-icons/fa";
import Button from "../Atoms/button";
import { BrowserRouter as Redirect, Route, Link } from "react-router-dom";
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validateLink: <></>,
            message: <></>,
        }
        this.linkPage = React.createRef();
    }
    render() {
        // console.log(sessionStorage.getItem("sesion"),localStorage.getItem("iniciada"));
        return (
            <>
                <div className="layout-login">
                    <form className="login" onSubmit={(evt) => { this.redirectPage(evt) }}>
                        <div className="login__logo margin-medium-bottom-2">
                            <img src={logo} alt={this.props.img} className="login__logo-img" />
                        </div>
                        <div style={{ position: "relative" }}>
                            <input type="text" className="login__input margin-medium-bottom-2" name="user" autoComplete="off" />
                            <FaUser className="icon__input" />
                        </div>
                        <div style={{ position: "relative" }} className="margin-medium-bottom-2">
                            <input type="password" className="login__input" name="password" />
                            <FaKey className="icon__input" />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Button text="entrar" styleClass="button__primary" />
                        </div>

                    </form>
                    {
                        this.state.message
                    }
                </div>
                <div>

                    {
                        this.state.validateLink
                    }
                </div>
            </>
        );
    };

    async redirectPage(evt) {
        evt.preventDefault();
        const urlHosting = process.env.REACT_APP_DOMAIN;
        let response = null, data = null;
        let password = new FormData(evt.target);
        try {
            response = await fetch(`${urlHosting}/webservice/login/${password.get("user")}/${password.get("password")}`);
            if (response.ok && response.status === 200) {
                data = await response.json();
                let session = localStorage.getItem("iniciada") || 0;
                if (session === 0) {
                    const crpuser = window.crypto.getRandomValues(new Uint32Array(10))
                    localStorage.setItem("user", data);
                    localStorage.setItem("iniciada", crpuser );
                    sessionStorage.setItem("sesion", crpuser);
                    this.setState(
                        {
                            validateLink: <Link ref={this.linkPage} to="/dashboard" className="disabled" id="click" />
                        }
                    );
                    this.linkPage.current.click();
                } else {
                    this.setState(
                        {
                            validateLink: <Link ref={this.linkPage} to="/sin-acceso" className="disabled" id="click" />
                        }
                    );
                    setTimeout(() => { this.linkPage.current.click() }, 200);
                }


            } else {
                this.setState({ message: <p className="margin-large-top-3" style={{ textAlign: "center", fontWeight: "700" }}>El usuario o contraseña es incorrecto </p> })
            }
        } catch {

        }
    }
}

export default Login;