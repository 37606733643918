import React, { Component } from "react";
import Tooltip from "../Molecules/tooltip";
import Header from "../Organisms/header";
import SectionPage, { OptionPage } from "../Templates/section-page";
import { FaUser, FaUserTie } from "react-icons/fa";
import ModalDos, { ButtonModal } from "../Templates/modal-dos";
import FormPromotor from "../Templates/form-promotor";
import FormCliente from "../Templates/form-cliente";
import Button from "../Atoms/button";
import withLogin from "../HOC/withLogin";

class Actualizacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibilityModal: "hidden",
            openModal: "",
            sectionNumber: 0,
            messageModal: "prueba de modal",
            buttonModal: [],
            resetClientForm: false
        }


        this.formVendorTwo = React.createRef();
        this.buttonModalOne = [
            <ButtonModal key={1} option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__primary" >Aceptar</ButtonModal>
        ];
        this.buttonModalTwo = [
            <Button key={1} styleClass="button__primary button--size-small margin-medium-right-3" text="Regresar a detalles" method={() => { window.history.back() }} />,
            <ButtonModal key={2} option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} method={() => { this.resetFormClient() }} styleClass="button__secundary"   >Salir</ButtonModal>
        ]
    }

    render() {
        const { buttonModal, resetClientForm } = this.state;
        const { idClient } = this.props.match.params;
        return (
            <>
                <Header />
                <main style={{ margin: "30px 30px 30px 200px" }}>
                    {
                        idClient ?
                            <SectionPage section={this.state.sectionNumber}>
                                <FormCliente hosting={this.props.hosting} params={{ idClient: this.props.match.params.idClient }} modal={(message, option) => { this.activeModal(message, option) }} reset={resetClientForm} />
                                <FormPromotor hosting={this.props.hosting} modal={(message, option) => { this.activeModal(message, option) }} />
                            </SectionPage> :
                            <FormPromotor hosting={this.props.hosting} modal={(message, option) => { this.activeModal(message, option) }} />

                    }

                </main>
                <aside className="aside-container__nav">

                    {
                        idClient &&
                        <OptionPage stateSection={(sectionNumber) => { this.setState({ sectionNumber }) }}>
                            <Tooltip text="Cliente" position="tooltip--right" styleComponent="margin-icon">
                                <FaUser data-section="0" />
                            </Tooltip>
                            <Tooltip text="Promotor" position="tooltip--right" >
                                <FaUserTie data-section="1" />
                            </Tooltip>
                        </OptionPage>

                    }

                </aside>
                <ModalDos stateModal={this.state.visibilityModal} modalOpen={this.state.openModal} sizeModal="modal__size--medium" text={this.state.messageModal} >
                    {
                        buttonModal.map(button => button)
                    }
                </ModalDos>
                <ButtonModal option={true} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="disabled" id="btn-modal" >Probar</ButtonModal>



            </>
        )
    }
    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && response.status === 200) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")
            }
        } catch (error) {
            data = false;

        } finally {
            return data;
        }

    }

    activeModal(message, option) {
        let buttonModal = null;
        switch (option) {
            case 1:
                buttonModal = this.buttonModalOne;
                break;
            case 2:
                buttonModal = this.buttonModalTwo;

            default:
                break;
        }
        this.setState({
            messageModal: message,
            buttonModal
        });
        document.getElementById("btn-modal").click();
    }
    resetFormClient() {
        this.setState({ resetClientForm: true });
    }

    async componentDidMount() {

    }



}

export default withLogin(Actualizacion);