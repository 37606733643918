import React, { Component } from 'react';
import { FaUser, FaPhoneAlt, FaMapMarkedAlt, FaMoneyCheckAlt, FaStoreAlt, FaLightbulb} from 'react-icons/fa';
import BoxInput from '../Molecules/box-input';
import Tooltip from "../Molecules/tooltip";
import Autocompletado from './autocompletado';


class Filtros extends Component {
    constructor(props) {
        super(props);
        this.state = {
            giroComercial:[],
            actividadPrincipal:[]
        }
    }
    render() {
        return (
            <aside className={this.props.styleClass}>
                <div onClick={(e) => this.element(e)}>
                    <div className="filtro__content">
                        <Tooltip text="Promotor" position="tooltip--right" styleComponent="margin-icon" >
                            <FaUser data-icono="user" />
                        </Tooltip>
                        <Tooltip text="RFC cliente" position="tooltip--right" styleComponent="margin-icon">
                            <span className="filter__element" data-icono="rfc" >RFC</span>
                        </Tooltip>
                        <Tooltip text="Teléfono contacto" position="tooltip--right" styleComponent="margin-icon">
                            <FaPhoneAlt data-icono="phone" />
                        </Tooltip>
                        <Tooltip text="Dirección comercio" position="tooltip--right" styleComponent="margin-icon">
                            <FaMapMarkedAlt data-icono="map" />
                        </Tooltip>
                        <Tooltip text="Recibo de pago" position="tooltip--right" styleComponent="margin-icon">
                            <FaMoneyCheckAlt data-icono="check" />
                        </Tooltip>
                        <Tooltip text="Giro comercial" position="tooltip--right" styleComponent="margin-icon">
                            <FaStoreAlt data-icono="typeCommerce" />
                        </Tooltip>
                        <Tooltip text="Actividad principal" position="tooltip--right">
                            <FaLightbulb data-icono="activity" />
                        </Tooltip>

                    </div>
                </div>
            </aside>
        )
    }
    element(evt) {
        let element = evt.target.dataset.icono || evt.target.parentElement.dataset.icono;
        let optionIcon = element;
        switch (optionIcon) {
            case "phone":
                this.props.handler(this.searchPhone(), "form__container", "disabled");
                break;
            case "rfc":
                this.props.handler(this.searchRfc(), "form__container", "disabled");
                break;
            case "user":
                this.props.handler(this.searchUser(), "form__container", "disabled");
                break;
            case "map":
                this.props.handler(this.searchMap(), "form__map-container", "disabled");
                break;
            case "check":
                this.props.handler(this.searchCountCheck(), "form__container", "disabled");
                break;
            case "typeCommerce":
                this.props.handler(this.searchTypeCommerce(), "form__large-element", "disabled");
                break;
            case "activity":
                this.props.handler(this.searchActivity(), "form__large-element", "disabled");
                break;
            default:
                break;
        }

    }
    searchUser() {
        return (
            <BoxInput icon={<FaUser className="icon-form" />} styleClass="form__icon" typeBox="sample" typeInput="text" statusInput="form__normal" nameInput="promotor" />
        );
    }
    searchPhone() {
        return (
            <BoxInput icon={<FaPhoneAlt className="icon-form" />} styleClass="form__icon" typeBox="sample" statusInput="form__normal" nameInput="telefono" />
        );
    }
    searchRfc() {
        return (
            <BoxInput icon="RFC:" styleClass="form__label" typeBox="sample" statusInput="form__normal" nameInput="rfc" />
        );
    }
    searchMap() {
        return (
            <>
                <FaMapMarkedAlt className="icon-form margin-small-bottom-3" />
                <BoxInput icon="Nombre de la vialidad:" styleClass="form__label" typeBox="sample" statusInput="form__normal" nameInput="vialidad" />
            </>
        );
    }
    searchCountCheck() {
        return (
            <BoxInput icon={<FaMoneyCheckAlt className="icon-form" />} styleClass="form__label" typeBox="sample" statusInput="form__normal" nameInput="engomado" typeInput="number" />
        );
    }
    searchTypeCommerce() {
        const {giroComercial} = this.state;
        return (
            <Autocompletado icon={<FaStoreAlt className="icon-form"  />} nameInput="idGiroComercial" >
                {
                    giroComercial.map((type,index) => <li key={index} data-value={type.id} tabIndex={-1}>{type.descripcion}</li>)
                }
            </Autocompletado>
        );
    }
    searchActivity() {
        const {actividadPrincipal} = this.state;
        return (
            <>
                <Autocompletado icon={<FaLightbulb className="icon-form" />} nameInput="actividadPrincipal" >
                    {
                        actividadPrincipal.map((activity,index) => <li key={index} data-value={activity} tabIndex={-1} >{activity}</li>)
                    }
                </Autocompletado>
            </>
        );
    }

    async componentDidMount(){
        const giroComercial = await this.read(`${this.props.hosting}/webservice/catalogos/giro-comercial`);
        const actividadPrincipal = await this.read(`${this.props.hosting}/webservice/comercio/actividadPrincipal`)
        this.setState({giroComercial,actividadPrincipal});
    }

    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && response.status === 200) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")
            }
        } catch (error) {
            data = false;

        } finally {
            return data;
        }

    }
}

export default Filtros;