import React, { Component } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import NumbertToLetter from "../Templates/number-to-letter";


const style = StyleSheet.create({
    page: {
        flexDirection: "row",
        //backgroundColor: "red"
    },
    columOne: {
        width: "16.75%",
        //backgroundColor: "#88AEC4"
    },
    columTwo: {
        width: "57.25%",
        //backgroundColor: "red"
    },
    columThree: {
        width: "26%",
        //backgroundColor: "blue"
    },
    rowEmpity: {
        height: "3.22cm",
       // backgroundColor: "green"
    },
    rowEngomado: {
        height: "4mm",
        //backgroundColor: "purple",
        textAlign: "right",
        //paddingTop: "6mm"
    },
    rowDataClient: {
        height: "4.5cm",
        paddingTop: "0mm",
        padding: "5 5 5 5",
        fontSize: 9,
        //backgroundColor: "green"
    },
    rowConcepto: {
        height: "3.7cm",
        paddingTop: "12mm",
        // backgroundColor: "yellow",
        fontSize: 16,
        fontWeight: "ultrabold",
        flexDirection: "column",
        justifyContent: "center",
        padding: "7 7 7 7"
    },
    rowCantidadNumero: {
        height: "4.5cm",
        paddingTop: "20mm",
        //backgroundColor: "yellow",
        fontSize: 18,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "5 5 5 5"
    },
    rowCantidad: {
        height: "14mm",
        flexDirection: "row",
        alignItems: "flex-end",
       // backgroundColor: "purple",
        fontSize: 9,
        padding: "5 5 5 5"
        //paddingTop: "3mm",
    },
    sameSize: {
        flexBasis: 0,
        flexGrow: 1

    },
    dataLetter1: {
        fontSize: 7,
        marginTop: 8,
        flexDirection: "row",
        //backgroundColor: "blue",
    },
    dataLetter2: {
        fontSize: 7,
        marginTop: 8,
        flexDirection: "row",
        //backgroundColor: "purple",
    },
    dataLetter3: {
        fontSize: 7,
        marginTop: 8,
        flexDirection: "row",
        //backgroundColor: "green",
    },


    dataLetter0: {
        fontSize: 7,
        marginTop: 1,
        flexDirection: "row",
        //backgroundColor: "yellow",
    }

});


class ReciboPago extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { cantidad, conceptos } = this.props;
        const numberLetter = new NumbertToLetter(cantidad.toFixed(2)).cantidad();
        // console.log(conceptos);
        return (
            <Document>
                <Page size="LETTER" style={style.page}>
                    <View style={style.columOne} >

                    </View>
                    <View style={style.columTwo}>
                        <View style={style.rowEmpity}></View>
                        <View style={style.rowEngomado}>
                            <Text style={{ fontSize: 9, fontWeight: "bold" }}>ENG {conceptos?.engomado || ""}</Text>
                        </View>
                        <View style={style.rowDataClient}>
                            
                        <View style={{ flexDirection: "row" }}>
                        <View style={{ width: "66%" }}>
                                <Text style={{ fontSize: 8, marginTop: 12 }}> {conceptos?.fecha || ""}</Text>
                        </View>
                            <View style={{ width: "34%" }}>

                                <Text style={{ fontSize: 8, marginTop: 11 }}> {conceptos?.rfc || ""}</Text>
                                    
                             </View>
                        </View>     
                            

                            <View style={style.dataLetter1} >

                            <View style={{ width: "66%" }}>
                                <Text style={{ fontSize: 8, marginTop: 11 }}> {conceptos?.nombre || ""}</Text>
                            </View>
                            <View style={{width: "22%" }}>
                                <Text style={{ fontSize: 8, marginTop: 11 }}> {conceptos?.telefono || ""}</Text> 
                            </View>     
                            <View style={{ width: "12%" }}>
                                <Text style={{ fontSize: 8, marginTop: 30 }}> {conceptos?.codigoPostal || ""}</Text>
                            </View>

                            
                        </View>

                            
                            <View style={style.dataLetter0} >

                            <View style={{ width: "66%" }}>
                                <Text style={{ fontSize: 8, marginTop: 0 }}>  {conceptos?.direccion || ""}</Text>
                            </View>
                            <View style={{ width: "34%" }}>
                                
                                    <Text style={{ fontSize: 8, marginTop: 0 }}> {conceptos?.colonia || ""}</Text>
                                    
                                
                            </View>

                            </View>
                            <View style={style.dataLetter2} >

                                <View style={{ width: "66%" }}>
                                    <Text style={{ fontSize: 8, marginTop: 12 }}> {conceptos?.municipio || ""} </Text>
                                </View>
                                <View style={{ flexDirection: "row", width: "34%" }}>
                                    <View style={{ flexBasis: 0, flexGrow: 1 }}>
                                        <Text style={{ fontSize: 8, marginTop: 10 }}> {conceptos?.estado || ""}</Text>    
                                        </View>
                                </View>

                            </View>
                        </View>
                        <View style={style.rowConcepto}>
                            {
                                 conceptos.conceptoRecibo.map((concepto, index) => <Text key={`concepto-${index}`}>{concepto}</Text>)
                            }

                        </View>
                        <View style={style.rowCantidad}>
                            <Text>{numberLetter} 00/100 M.N</Text>
                        </View>
                    </View>
                    <View style={style.columThree}>
                        <View style={style.rowEmpity} />
                        <View style={style.rowDataClient} />
                        <View style={style.rowCantidadNumero}>
                            <Text>${cantidad.toFixed(2)}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
    componentDidMount() {
        // console.log("se cargo el componente asfdasd");
    }

    cantidadLetra() {
        return `${new NumbertToLetter(this.props.cantidad.toFixed(2))}`
    }
}

export default ReciboPago;