import React from "react";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { FaHospitalAlt } from "react-icons/fa";
import BoxInput from "../Molecules/box-input";


function Direction(props) {
    let [stateTypeAdress, setStateTypeAdress] = useState([]);
    let [statePostCode, setStatePostCode] = useState({
        colonia: [],
        ciudad: "",
        municipioCiudad: "",
        addPostCode: "disabled",
        enabledOthePost: false,
        idMunicipio: "",
        vialidad: "U01"
    })
    useEffect(() => {
        (async () => {
            let response = await read(`${process.env.REACT_APP_DOMAIN}/webservice/catalogos/tipo-vialidad`);
            setStateTypeAdress(response);
        })();
    },[])
    
    const {colonia,municipioCiudad, ciudad, addPostCode,idMunicipio } = statePostCode;
    const coloniaPrueba = props.colonia || statePostCode.colonia;
    const estadoComercio = props.estado;
    const municipioComercio = props.municipio;
    let postCode = useRef(null);

    return (
        <>
            <div className="grid-layout  grip-gap layout__ubication margin-large-top-2">
                <label htmlFor="" className="content-element__form-sample">
                    <span className="form__label">Tipo Vialidad</span>
                    <select name="tipoVialidadModelo" className="form__input form__normal" defaultValue={statePostCode.vialidad} >
                        {
                            stateTypeAdress.map(
                                vialidad => (
                                    <option key={vialidad.id} value={vialidad.id}>{vialidad.descripcion}</option>
                                )
                            )
                        }
                    </select>
                </label>
                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" icon="Nombre de la vialidad" statusInput="form__normal" nameInput="nombreVialidad" defaultValue="S/D" maxLength={50} />
            </div>
            <div className="margin-large-top-3 grid-layout grip-gap grid-2" style={{ width: "1000px" }}>
                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" icon="Número Exterior" statusInput="form__normal" nameInput="numeroExterior" defaultValue="S/N" maxLength={25} />
                <BoxInput typeBox="sample" typeInput="text" defaultValue="S/N" styleClass="form__label" icon="Número Interior" statusInput="form__normal" nameInput="numeroInterior" maxLength={25} />
            </div>
            <div className="grid-layout grip-gap margin-medium-top-3" style={{ gridTemplateColumns: "2fr 2fr 1fr", width: "1200px" }}   >
                <div className="grid-layout grip-gap " style={{ gridTemplateColumns: "1fr max-content" }}  >
                    <div >
                        <BoxInput typeBox="input" styleClass="form__label" icon="Código postal">
                            <input type="text" maxLength={5} className="form__normal form__input" name="codigoPostal" placeholder="00000" ref={postCode} />
                        </BoxInput>

                    </div>
                    <div>
                        <button type="button" className="button button__primary button--size-medium" onClick={() => { getPostalCode() }} >consultar</button>
                    </div>
                </div>
                <label htmlFor="" className="content-element__form-sample">
                    <span className="form__label" >Colonia</span>
                    <select name="coloniaModelo" className="form__input form__normal" >
                        {colonia.length <= 0 ?  
                            coloniaPrueba.map(
                                colonia => (
                                    <option key={colonia.id} value={colonia.id}>{colonia.nombre}</option>
                                )
                            ):
                            colonia.map(
                                colonia => (
                                    <option key={colonia.id} value={colonia.id}>{colonia.nombre}</option>
                                )
                            )
                        }
                    </select>
                </label>
                <div onChange={(evt) => { otherPostCode(evt) }}>
                    <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Otro" styleClass="form__label" nameInput="otroCodigo" value="si" />
                </div>
            </div>
            <div className="margin-medium-top-3">
                <span className="margin-medium-right-3" style={{ fontWeight: 700 }}>Estado: {ciudad || estadoComercio || "S/D"}</span>
                <span style={{ fontWeight: 700 }} >Municipio: {municipioCiudad || municipioComercio || "S/D"}</span>
                <input type="hidden" name="idMunicipio" value={idMunicipio} />
            </div>
            <div className={`margin-medium-top-3 ${addPostCode}`} style={{ width: "600px" }}>
                <BoxInput typeBox="sample" typeInput="text" icon="Nueva colonia" styleClass="form__label" statusInput="form__normal" nameInput="nuevaColonia" disabled={addPostCode === "enabled" ? false : true} />
            </div>

        </>
    );
    async function read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && response.status === 200) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")
            }
        } catch (error) {
            data = false;

        } finally {
            return data;
        }


    }
    async function getPostalCode() {
        setStatePostCode({ ...statePostCode, colonia: [] });
        let postCodeValue = postCode.current.value;
        if (postCodeValue.length < 5) {
            props.modal("El codigo postal debe tener 5 caracteres. Por ejemplo 9999 colocar 09999",props.optionModal)
            setStatePostCode({ ...statePostCode, colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "No existe el codigo postal" }] });
            return 0;
        }
        let response = await read(`${process.env.REACT_APP_DOMAIN}/webservice/direccion/${postCodeValue}`);
        const coloniaModeloLista = response.coloniaModeloLista ? response.coloniaModeloLista : [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "No existe el codigo postal" }];
        let { estado, municipio,idMunicipio } = response;
        setStatePostCode({
            ...statePostCode,
            colonia: coloniaModeloLista,
            ciudad: estado,
            municipioCiudad: municipio,
            enabledOtherPost: true,
            idMunicipio
        });

    }

    function otherPostCode(evt){
        if (statePostCode.enabledOtherPost) {
            setStatePostCode({ ...statePostCode, addPostCode: evt.target.checked ? "enabled" : "disabled" });
        } else if (evt.target.checked) {
            setStatePostCode({...statePostCode, enabledOtherPost: false })
            props.modal("Favor de seleccionar primero el codigo postal",props.optionModal);
            evt.target.checked = false;
            
        }
    }
}



export default Direction;