import React, { Component } from "react";
import Title from "../Atoms/title";
import Header from "../Organisms/header";
import SectionPageDisplay, { OptionPage } from "../Templates/section-page-display";
import Tooltip from "../Molecules/tooltip";
import { FaDollarSign, FaSearch } from "react-icons/fa";
import withLogin from "../HOC/withLogin";
import BoxInput from "../Molecules/box-input";
import Button from "../Atoms/button";
import ModalDos, { ButtonModal } from "../Templates/modal-dos";
import Tabla from "../Templates/tabla";
import PaginationDisplay from "../Templates/pagination-display";
import Spinner from "../Molecules/spinner";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReciboPago from "../Templates/recibo-pago";

class Pagos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commerces: [],
            formEnabled: ["enabled", "disabled"],
            show: false,
            messageModal: "message",
            countPayAfiliacion: 0,
            countPaySiem: 0,
            sectionNumber: 0,
            payUpdate: false,
            //parametros Modal
            visibilityModal: "hidden",
            openModal: "",
            buttonModal: [<ButtonModal key={"button-uno"} option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__primary" >Aceptar</ButtonModal>],
            //tabla historial pago
            dataTable: [],
            //spinnerPago
            spinnerPago: false,
            spinnerPayAll: false,
            spinnerUpdate: false,
            //reporte pago
            conceptoPago: [],
            cantidadPago: 2000,
            //validate form all commerce
            siemCheck: true,
            afiliacionCheck: true,
            buttonPDF: false

        }
        this.buttonPDF = React.createRef();
    }
    render() {

        const { commerces, countPayAfiliacion, countPaySiem, spinnerPago, payUpdate, conceptoPago, afiliacionCheck, siemCheck, spinnerPayAll, buttonPDF, spinnerUpdate } = this.state;
        // console.log(cantidadPago,conceptoPago);
        return (
            <>
                <Header />
                <main style={{ margin: "30px 30px 30px 200px" }}>
                    <SectionPageDisplay section={this.state.sectionNumber}>
                        <section key={"uno"}>
                            <Title type="title" content={`${this.props.match.params.name === "update" ? "Actualizar pago" : "Agregar pago"}`} />
                            <form className={`margin-large-top-3 ${this.state.formEnabled[0]}`} onSubmit={this.props.match.params.name === "update" ? (evt) => { this.updatePayCommerce(evt) } : (evt) => { this.payCommerces(evt) }}>
                                {
                                    this.props.match.params.name === "update" ?
                                                                               
                                        <>
                                            {
                                                !payUpdate ? <Spinner text="Cargando" /> :
                                                payUpdate.comercioPagoLista ?
                                                    <>

                                                        {

                                                            payUpdate.comercioPagoLista.map((pay, index) =>
                                                                <div key={`pago-general-${index}`}>
                                                                    {

                                                                        < >
                                                                            <p className="margin-medium-top-3" style={{ fontWeight: "700" }} >Concepto de pago : {pay.nombreConcepto}</p>
                                                                            <div className="margin-medium-top-2 grid-layout grip-gap grid-2" style={{ width: "800px" }}>
                                                                                <BoxInput typeBox="input" styleClass="form__label" icon="Importe">
                                                                                    <input type="number" defaultValue={pay.importe} name={`importe${pay.nombreConcepto}`} className="form__input form__normal" min={0} max={9999999} step={0.01} required={true} />
                                                                                </BoxInput>


                                                                                <BoxInput typeBox="input" styleClass="form__label" icon="Año de concepto">
                                                                                    <input type="number" defaultValue={pay.anioConcepto} name={`anioConcepto${pay.nombreConcepto}`} className="form__input form__normal" min={2000} max={2031} required={true} />
                                                                                </BoxInput>
                                                                                {
                                                                                    pay.engomadoInicio ?
                                                                                        <BoxInput typeBox="input" styleClass="form__label" icon="Engomado de inicio">
                                                                                            <input type="number" defaultValue={pay.engomadoInicio} name="engomadoInicio" className="form__input form__normal" min={1} max={32768} required={true} />
                                                                                        </BoxInput> :
                                                                                        <></>
                                                                                }


                                                                            </div>
                                                                        </>

                                                                    }
                                                                </div>

                                                            )
                                                        }
                                                    </> :
                                                    <PaginationDisplay numberElement={10}>
                                                        {
                                                            payUpdate ? payUpdate.comercioPagoModeloLista.map((pay, indexParent) => {
                                                                return (
                                                                    <div key={indexParent}>
                                                                        {

                                                                            < >
                                                                                <h4 className="margin-medium-top-3 commerce__title">{`Comercio: ${pay.comercioModelo.nombre}`}</h4>
                                                                                <p className="margin-medium-top-3" style={{ fontWeight: "700" }} >Concepto de pago : {pay.conceptoModelo.concepto}</p>
                                                                                <div className="margin-medium-top-2 grid-layout grip-gap grid-2" style={{ width: "800px" }}>
                                                                                    <BoxInput typeBox="input" styleClass="form__label" icon="Importe">
                                                                                        <input type="number" defaultValue={pay.importe} name={`importe{${indexParent}}`} className="form__input form__normal" min={0} max={9999999} step={0.01} required={true} />
                                                                                    </BoxInput>


                                                                                    <BoxInput typeBox="input" styleClass="form__label" icon="Año de concepto">
                                                                                        <input type="number" defaultValue={pay.anioConcepto} name={`anioConcepto{${indexParent}}`} className="form__input form__normal" min={2000} max={2031} required={true} />
                                                                                    </BoxInput>
                                                                                    {
                                                                                        pay.engomado ?
                                                                                            <BoxInput typeBox="input" styleClass="form__label" icon="Engomado">
                                                                                                <input type="number" defaultValue={pay.engomado} name={`engomado{${indexParent}}`} className="form__input form__normal" min={1} max={32768} required={true} />
                                                                                            </BoxInput> :
                                                                                            <></>
                                                                                    }


                                                                                </div>
                                                                            </>

                                                                        }
                                                                        <input type="hidden" value={pay.comercioModelo.id} name={`comercioModelo{${indexParent}}`} />
                                                                        <input type="hidden" value={pay.conceptoModelo.id} name={`concepto{${indexParent}}`} />

                                                                    </div>
                                                                );


                                                            }) : []
                                                        }
                                                    </PaginationDisplay>

                                            }

                                            <div className="margin-medium-top-3" style={{ width: "500px" }}>
                                                <BoxInput typeBox="input" icon="Fecha de pago" styleClass="form__label"  >
                                                    <input type="date" name="pago" className="form__input form__normal" defaultValue={payUpdate.pago} />
                                                </BoxInput>
                                            </div>
                                            <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Actualizar" /> {spinnerUpdate && <Spinner text="Actualizando" />}

                                        </> :
                                        <>

                                            <PaginationDisplay numberElement={2}>
                                                {
                                                    commerces.length > 0 ?
                                                        commerces.map((commerce, index) =>
                                                            <div key={`content-${index}`} >
                                                                <h4 className="margin-medium-top-3 commerce__title">{`Comercio: ${commerce.nombre}`}</h4>
                                                                <div key={`siemAfiliacion-${index}`} className="margin-medium-top-2" style={{ width: "800px" }}>
                                                                    <div className="grid-layout grip-gap grid-2" onChange={(evt) => { this.validateForm(evt) }} >
                                                                        <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Afiliación" nameInput={`afiliacion{${index}}`} styleClass="form__label" value="2" />
                                                                        <BoxInput typeBox="input" styleClass="form__label" icon="Importe" >
                                                                            <input type="number" className="form__input form__normal" name={`importeAfiliacion{${index}}`} readOnly={true} min={1} max={9999999} step={0.01} />
                                                                        </BoxInput>
                                                                        <BoxInput typeBox="input" styleClass="form__label" icon="Año de concepto" >
                                                                            <input type="number" className="form__input form__normal" name={`anioConceptoAfiliacion{${index}}`} readOnly={true} min={2000} max={2031} />
                                                                        </BoxInput>
                                                                    </div>
                                                                    <div className="separador__comcepto-pago"></div>
                                                                    <div className="grid-layout grip-gap grid-2 margin-medium-top-3" onChange={(evt) => { this.validateForm(evt) }} >
                                                                        <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Siem" nameInput={`siem{${index}}`} styleClass="form__label" value="1" />
                                                                        <BoxInput typeBox="input" styleClass="form__label" icon="Importe">
                                                                            <input type="number" className="form__input form__normal" name={`importeSiem{${index}}`} readOnly={true} min={1} max={9999999} step={0.01} />
                                                                        </BoxInput>
                                                                        <BoxInput typeBox="input" styleClass="form__label" icon="Año de concepto" >
                                                                            <input type="number" className="form__input form__normal" name={`anioConcepto{${index}}`} readOnly={true} min={2000} max={2031} />
                                                                        </BoxInput>
                                                                        <BoxInput typeBox="input" styleClass="form__label" icon="Engomado" >
                                                                            <input type="number" className="form__input form__normal" name={`engomado{${index}}`} readOnly={true} min={1} max={32768} />
                                                                        </BoxInput>
                                                                    </div>

                                                                </div>

                                                                <input type="hidden" name={`idComercio{${index}}`} value={commerce.id} />
                                                            </div>) : []
                                                }
                                            </PaginationDisplay>

                                            <div className="grid-layout grid-2 grip-gap margin-large-top-3" style={{ width: "700px" }} >
                                                <BoxInput typeBox="sample" typeInput="text" statusInput="form__normal" styleClass="form__label" icon="Recibo" nameInput="recibo" habilitado={true} />
                                                <BoxInput typeBox="sample" typeInput="date" styleClass="form__label" statusInput="form__normal" icon="Fecha de pago" nameInput="pago" />
                                            </div>
                                            <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Pagar" />
                                            {
                                                spinnerPago ? <Spinner text="Pagando" /> : <></>
                                            }

                                        </>
                                }

                            </form>

                            {
                                commerces.length > 1 ? <>
                                    <div className="margin-medium-top-3" onChange={(evt) => { this.formOption(evt) }} >
                                        <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Hacer pago general" styleClass="form__label" nameInput="pagoGeneral" value="true" />
                                    </div>
                                    <form className={`${this.state.formEnabled[1]} margin-medium-top-3`} onSubmit={(evt) => { this.payAllCommerces(evt) }} style={{ width: "800px" }}>
                                        <h3>Pago General</h3>
                                        <div className="margin-medium-top-2" >
                                            <div className="grid-layout grip-gap grid-2" onChange={(evt) => { this.resetContentForm(evt) }}>
                                                <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Afiliación" nameInput="afiliacion" styleClass="form__label" value="2" />
                                                <BoxInput typeBox="input" icon="Importe" styleClass="form__label" >
                                                    <input type="number" readOnly={afiliacionCheck} required={!afiliacionCheck} name="importeAfiliacion" className="form__input form__normal" onChange={(evt) => { this.countAll(evt, "afiliacion") }} min={1} max={9999999} step={0.01} />
                                                </BoxInput>
                                                <BoxInput typeBox="input" icon="Año de concepto" styleClass="form__label" >
                                                    <input type="number" readOnly={afiliacionCheck} required={!afiliacionCheck} name="anioConceptoAfiliacion" className="form__input form__normal" min={2000} max={2031} />
                                                </BoxInput>

                                            </div>
                                            <div className="separador__comcepto-pago" />
                                            <div className="grid-layout grip-gap grid-2 margin-medium-top-3" onChange={(evt) => { this.resetContentForm(evt) }} >
                                                <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Siem" nameInput="siem" styleClass="form__label" value="1" />
                                                <BoxInput typeBox="input" icon="Importe" styleClass="form__label" >
                                                    <input type="number" readOnly={siemCheck} required={!siemCheck} name="importeSiem" className="form__input form__normal" onChange={(evt) => { this.countAll(evt, "siem") }} min={1} max={9999999} step={0.01} />
                                                </BoxInput>
                                                <BoxInput typeBox="input" icon="Año de concepto" styleClass="form__label" >
                                                    <input type="number" readOnly={siemCheck} required={!siemCheck} name="anioConcepto" className="form__input form__normal" min={2000} max={2031} />
                                                </BoxInput>
                                                <BoxInput typeBox="input" icon="Engomado de inicio" styleClass="form__label" >
                                                    <input type="number" readOnly={siemCheck} required={!siemCheck} name="engomado" className="form__input form__normal" min={1} max={32767} />
                                                </BoxInput>
                                            </div>
                                        </div>
                                        <div className="margin-large-top-3 grid-layout grid-2 grip-gap" style={{ width: "700px" }}>
                                            <BoxInput typeBox="sample" typeInput="number" statusInput="form__normal" styleClass="form__label" icon="Recibo" nameInput="recibo" habilitado={true} />
                                            <BoxInput typeBox="sample" typeInput="date" styleClass="form__label" statusInput="form__normal" icon="Fecha de pago" nameInput="pago" />
                                        </div>
                                        <h4 className="margin-medium-top-2 flex" style={{ justifyContent: "space-between" }}>
                                            <span>Comercios Totales:{this.state.commerces.length}</span>  <span>Total: ${countPayAfiliacion + countPaySiem}</span>
                                        </h4>
                                        <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Pagar" />{spinnerPayAll ? <Spinner text="Pagando" /> : <></>}
                                    </form></> : <></>
                            }
                            <div className="margin-medium-top-3">
                                {
                                    conceptoPago.length > 0 ?
                                        <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Descargar recibo de pago" type="button" method={() => { this.downloadPFD() }} /> : <></>
                                }
                                {
                                    buttonPDF || <></>
                                }
                            </div>

                        </section>
                        <section key={"dos"}>
                            <Title type="title" content="Historial de pagos" styleClass="margin-large-top-3" />
                            <div className="margin-medium-top-3">
                                <Tabla tableHeader={["Concepto", "Fecha de registro", "Año de concepto", "importe", "Recibo", "Engomado"]} tableData={this.state.dataTable} elementTable={this.state.dataTable.length} />
                            </div>
                        </section>
                    </SectionPageDisplay>


                </main>
                <aside className={`aside-container__nav ${this.props.match.params.name === "client" ? "disabled" : ""}`} >
                    <OptionPage stateSection={(sectionNumber) => { this.setState({ sectionNumber }) }} >
                        <Tooltip text="Agregar pago" position="tooltip--right" styleComponent="margin-icon">
                            <FaDollarSign data-section="0" />
                        </Tooltip>
                        <Tooltip text="Consultar pagos" position="tooltip--right" >
                            <FaSearch data-section="1" />
                        </Tooltip>
                    </OptionPage>
                </aside>

                <ModalDos stateModal={this.state.visibilityModal} optionContent={this.state.optionButton} modalOpen={this.state.openModal} sizeModal="modal__size--small" text={this.state.messageModal}>
                    {
                        this.state.buttonModal.map(button => button)
                    }

                </ModalDos>
                <ButtonModal option={true} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="disabled" id="btn-modal" >Probar</ButtonModal>
            </>
        );
    }

    async componentDidMount() {
        const { name, id: searchId } = this.props.match.params;
        if (name === "client") {
            let commerces = await this.read(`${this.props.hosting}/webservice/comercio/${searchId}?activo=true`);
            // console.log(commerces);
            this.setState(
                {
                    commerces,
                }
            );
        } else if (name === "commerce") {
            let data = searchId.split(":");
            let commerces = await this.read(`${this.props.hosting}/webservice/comercio/${data[1]}?activo=true`);
            let commerce = commerces.filter(commerce => commerce.id === data[0]);
            let pagos = await this.read(`${this.props.hosting}/webservice/pago/${data[0]}?activo=true`);
            this.writeTablePay(pagos);

            this.setState(
                {
                    commerces: commerce
                }
            );

        } else if (name === "update") {
            let data = searchId.split(":"), pay = false;
            let pagos = await this.read(`${this.props.hosting}/webservice/pago/${data[1]}`);
            if (pagos.comercioPagoModeloLista.length > 6) {
                pay = this.chooseTypePay(pagos);
            }

            //console.log(pay);
            this.setState({ payUpdate: pay || pagos });

        }

    }

    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && response.status === 200) {
                data = await response.json();
            } else {
                data = false;
                throw new Error("messageError");
            }
        } catch (error) {
            // console.log(error);
        } finally {
            return data;
        }
    }
    async create(url, object) {
        let response = await fetch(url, object);
        let data = null;
        // console.log(response);
        if (response.ok && response.status === 201) {
            data = true;
        } else { data = false }
        return data;

    }

    async delete(url, object) {
        let response = null, data = null;
        const objData = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object),
        };

        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true;
            } else {
                data = false;
                throw new Error("messageError");
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally {
            return data;
        }

    }

    async payCommerces(evt) {
        evt.preventDefault();
        let objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {},
        };
        this.setState({ spinnerPago: true });
        let date = new Date();
        let month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        let formEntries = [], objEntries = [];
        let formPay = new FormData(evt.target);
        for (const pair of formPay.entries()) {
            formEntries.push(pair);
        }
        // console.log(formEntries);
        for (let index = 0; index < this.state.commerces.length; index++) {
            let commerce = this.objCreate(formEntries.filter(entrie => entrie[0].includes(`{${index}}`)));
            const { engomado, anioConcepto, importeSiem, importeAfiliacion, anioConceptoAfiliacion } = commerce;
            let anioConceptoNumber = parseInt(anioConcepto === "" ? 0 : anioConcepto), engomadoNumber = parseInt(engomado === "" ? 0 : engomado), anioConceptoAfiliacionNumber = parseInt(anioConceptoAfiliacion === "" ? 0 : anioConceptoAfiliacion);
            if (commerce.siem && commerce.afiliacion) {
                let obj = {
                    comercioModelo: {
                        id: commerce.idComercio
                    },
                    conceptoModelo: {
                        id: parseInt(commerce.siem)
                    },
                    engomado: engomadoNumber,
                    anioConcepto: anioConceptoNumber,
                    importe: parseInt(importeSiem === "" ? 0 : importeSiem)

                }, obj2 = {
                    comercioModelo: {
                        id: commerce.idComercio
                    },
                    conceptoModelo: {
                        id: parseInt(commerce.afiliacion)
                    },
                    // idConcepto: parseInt(commerce.afiliacion),
                    anioConcepto: anioConceptoAfiliacionNumber,
                    importe: parseInt(importeAfiliacion === "" ? 0 : importeAfiliacion)
                }
                objEntries.push(obj);
                objEntries.push(obj2);

            } else if (commerce.siem) {
                let obj = {
                    comercioModelo: {
                        id: commerce.idComercio
                    },
                    conceptoModelo: {
                        id: parseInt(commerce.siem)
                    },
                    engomado: engomadoNumber,
                    anioConcepto: anioConceptoNumber,
                    importe: parseInt(importeSiem === "" ? 0 : importeSiem)

                }

                objEntries.push(obj);

            } else if (commerce.afiliacion) {
                let obj = {
                    comercioModelo: {
                        id: commerce.idComercio
                    },
                    conceptoModelo: {
                        id: parseInt(commerce.afiliacion)
                    },
                    // idConcepto: parseInt(commerce.afiliacion),
                    anioConcepto: anioConceptoAfiliacionNumber,
                    importe: parseInt(importeAfiliacion === "" ? 0 : importeAfiliacion)
                }
                objEntries.push(obj);
            }
        }
        if (objEntries.length > 0) {
            let datePay = formPay.get("pago");
            let payObject = {
                id: parseInt(formPay.get("recibo")),
                pago: datePay !== "" ? datePay : `${date.getFullYear()}-${month}-${day}`,
                comercioPagoModeloLista: objEntries
            }
            let validate = await this.read(`${this.props.hosting}/webservice/pago/existe/${formPay.get("recibo")}`);
            if (validate) {
                this.activeModal("El numero de recibo ya no esta disponible");
                this.setState({ spinnerPago: false });
            } else {
                //  console.log(payObject, objEntries);
                objData.body = JSON.stringify(payObject);
                let response = await this.create(`${this.props.hosting}/webservice/pago`, objData);
                if (response) {
                    this.activeModal("se realizaron los pagos exitosamente");
                    this.updatePay()
                    this.createDocumentPDF(payObject.id)
                    evt.target.reset();
                    this.setState({
                        spinnerPago: false,

                    });
                }

            }


        } else {
            this.activeModal("No ha seleccionado ningun concepto")
            this.setState({ spinnerPago: false });
        }




    }

    async payAllCommerces(evt) {
        evt.preventDefault();
        this.setState({
            spinnerPayAll: true,
        });
        let objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {}
        };
        let date = new Date();
        let month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        let comercioPagoModeloLista = [];
        let formData = new FormData(evt.target);
        let obj = {}, arrayAfiliacion = [], arraySiem = []
        const { commerces } = this.state;
        for (const iterator of formData.entries()) {
            obj[iterator[0]] = iterator[1]
        }
        const { afiliacion, engomado, anioConcepto, anioConceptoAfiliacion, siem, importeAfiliacion, importeSiem, recibo, pago } = obj;
        if (afiliacion) {

            arrayAfiliacion = commerces.map(commerce => (
                {
                    comercioModelo: { id: commerce.id },
                    conceptoModelo: { id: parseInt(afiliacion) },
                    // idConcepto: parseInt(afiliacion),
                    importe: parseInt(importeAfiliacion),
                    anioConcepto: parseInt(anioConceptoAfiliacion)
                }
            ));
        }
        if (siem) {
            let dataEngomado = parseInt(engomado)
            arraySiem = commerces.map((commerce, index) =>
            (
                {
                    comercioModelo: { id: commerce.id },
                    conceptoModelo: { id: parseInt(siem) },
                    engomado: dataEngomado + index,
                    anioConcepto: parseInt(anioConcepto),
                    importe: parseInt(importeSiem)
                }
            ));
        }
        // console.log(afiliacion, siem);
        if (afiliacion === undefined && siem === undefined) {
            this.activeModal("No se puede realizar un pago si haber selecionado un concepto");
            this.setState({ spinnerPayAll: false });
        } else {
            comercioPagoModeloLista = siem && afiliacion ? arraySiem.concat(arrayAfiliacion) : siem ? arraySiem : arrayAfiliacion;
            let bodyObj = {
                id: parseInt(recibo),
                pago: pago !== "" ? pago : `${date.getFullYear()}-${month}-${day}`,
                comercioPagoModeloLista
            }
            // console.log(bodyObj);
            let validateBill = await this.read(`${this.props.hosting}/webservice/pago/existe/${recibo}`);
            if (!validateBill) {
                objData.body = JSON.stringify(bodyObj);
                // console.log(bodyObj);
                let response = await this.create(`${this.props.hosting}/webservice/pago`, objData);
                if (response) {
                    this.createDocumentPDF(recibo);
                    this.activeModal("Se agregaron los pagos correctamente");
                    evt.target.reset()

                    this.setState({
                        spinnerPayAll: false,
                        countPayAfiliacion: 0,
                        countPaySiem: 0
                    });
                }


            } else {
                this.activeModal("El numero de recibo ya no esta disponible");
                this.setState({ spinnerPayAll: false });
            }

        }



    }

    objCreate(array) {
        let obj = {}
        for (const iterator of array) {
            obj[iterator[0].replace(/[0-9]|{|}/g, "")] = iterator[1]
        }
        return obj;
    }

    formOption(evt) {
        let formEnabled = [];
        formEnabled = evt.target.checked ? ["disabled", "enabled"] : ["enabled", "disabled"];
        this.setState({
            formEnabled
        });
    }


    countAll(evt, option) {
        const { commerces } = this.state;
        /*let multiploAfiliacion = commerces.filter(commerce => !(commerce.pagoAfiliacion)).length;
        let multiploSiem = commerces.length;*/
        let multiplo = commerces.length
        let valueInput = evt.target.value === "" ? 0 : parseInt(evt.target.value);
        // console.log(evt.target.value, valueInput);
        switch (option) {
            case "afiliacion":
                this.setState(
                    {
                        countPayAfiliacion: valueInput * multiplo
                    }
                );
                break;
            case "siem":
                this.setState(
                    {
                        countPaySiem: valueInput * multiplo
                    }
                );
                break;

            default:
                break;
        }


    }

    writeTablePay(pagos) {
        let dataTable = [];
        if (pagos) {
            let contentTable = pagos.map(colum => {
                const { id: recibo, pago: fechaRegistro, comercioPagoModeloLista } = colum;
                let pagosRecibo = comercioPagoModeloLista.map(pago => ({ anioConcepto: pago.anioConcepto, conceptoModelo: pago.conceptoModelo.concepto, importe: pago.importe, engomado: pago.engomado || "S/D" }));
                return (
                    {
                        recibo,
                        fechaRegistro,
                        pagosRecibo

                    }
                );
            });
            contentTable.forEach(colum => {
                const { recibo, fechaRegistro } = colum;
                colum.pagosRecibo.forEach(pago => {
                    const obj = {
                        concepto: pago.conceptoModelo,
                        fechaRegistro,
                        anio: pago.anioConcepto,
                        importe: pago.importe,
                        recibo,
                        engomado: pago.engomado,
                    }
                    dataTable.push(obj)
                })
            });
        } else { dataTable = false; }


        this.setState({ dataTable })
    }

    activeModal(message) {
        this.setState({
            messageModal: message
        });
        document.getElementById("btn-modal").click();
    }
    async updatePay() {
        const { name, id: searchId } = this.props.match.params;
        if (name === "client") {
            // console.log("entro");
        } else if (name === "commerce") {
            // console.log("entro");
            let data = searchId.split(":");
            let pagos = await this.read(`${this.props.hosting}/webservice/pago/${data[0]}`);

            // console.log(pagos);
            this.writeTablePay(pagos);
        }
    }

    async createDocumentPDF(recibo) {
        let conceptoPago = await this.read(`${this.props.hosting}/webservice/impresion/pago/${recibo}`);
        let cantidadPago = this.countBill(conceptoPago);
        this.setState({
            conceptoPago,
            cantidadPago
        });
    }
    async updatePayCommerce(evt) {
        evt.preventDefault();
        this.setState({ spinnerUpdate: true });
        const { id: searchId } = this.props.match.params;
        const { payUpdate } = this.state;
        let dataArray = searchId.split(":");
        let objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {}
        };
        let date = new Date();
        let siem = null, afiliacion = null, comercioPagoModeloLista = [];
        let month = date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        let day = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate();
        let element = evt.target;
        let formUpdatePay = new FormData(element);
        let formEntries = [], objEntries = [];
        let obj = {};
        for (const pair of formUpdatePay.entries()) {
            obj[pair[0]] = pair[1];
            formEntries.push(pair)
        }
        if (payUpdate.idCommerces) {
            const { anioConceptoAFILIACION, anioConceptoSIEM, engomadoInicio, importeAFILIACION, importeSIEM, pago } = obj;

            if (importeSIEM) {
                siem = payUpdate.idCommerces.map((idCommerce, index) => (
                    {
                        comercioModelo: { id: idCommerce },
                        conceptoModelo: { id: 1 },
                        engomado: parseInt(engomadoInicio) + index,
                        anioConcepto: parseInt(anioConceptoSIEM),
                        importe: parseInt(importeSIEM)
                    }));
            }
            if (importeAFILIACION) {
                afiliacion = payUpdate.idCommerces.map((idCommerce) => (
                    {
                        comercioModelo: { id: idCommerce },
                        conceptoModelo: { id: 2 },
                        anioConcepto: parseInt(anioConceptoAFILIACION),
                        importe: parseInt(importeAFILIACION)
                    }));
            }

            comercioPagoModeloLista = siem && afiliacion ? siem.concat(afiliacion) : siem ? siem : afiliacion;
            let objPayCommerce = {
                id: payUpdate.id,
                pago,
                comercioPagoModeloLista
            }
            objData.body = JSON.stringify(objPayCommerce);
            //console.log(objPayCommerce);

        } else {

            for (let index = 0; index < payUpdate.comercioPagoModeloLista.length; index++) {
                let commerce = this.objCreate(formEntries.filter(entrie => entrie[0].includes(`{${index}}`)));
                let obj = null;
                const { anioConcepto, comercioModelo, concepto, engomado, importe } = commerce;

                switch (parseInt(concepto)) {
                    case 1:

                        obj = {
                            anioConcepto: parseInt(anioConcepto),
                            comercioModelo: { id: comercioModelo },
                            conceptoModelo: { id: parseInt(concepto) },
                            engomado: parseInt(engomado),
                            importe: parseInt(importe)
                        }
                        objEntries.push(obj);
                        break;
                    case 2:
                        obj = {
                            anioConcepto: parseInt(anioConcepto),
                            comercioModelo: { id: comercioModelo },
                            conceptoModelo: { id: parseInt(concepto) },
                            importe: parseInt(importe)
                        }
                        objEntries.push(obj);
                        break;

                    default:
                        break;
                }

            }
            let objPayCommerce = {
                id: payUpdate.id,
                pago: obj.pago,
                comercioPagoModeloLista: objEntries
            }

            objData.body = JSON.stringify(objPayCommerce);
        }

        let deleteResponse = await this.delete(`${this.props.hosting}/webservice/pago/${payUpdate.id}`)
        if (deleteResponse) {
            // console.log("se borro corectamente");
            let updatePayResponse = await this.create(`${this.props.hosting}/webservice/pago`, objData);
            if (updatePayResponse) {
                // console.log("todo salio bien");
                this.setState({
                    buttonModal: [<Button key="button-history" text="aceptar" styleClass="button__primary button--size-medium" method={() => {
                        window.history.back();
                    }} />],
                    spinnerUpdate: false
                });
                this.activeModal("Se actulizo el pago correctamente");
            }

        } else { /*console.log("algo salio mal");*/ }

        // console.log(bodyObj);

    }
    resetContentForm(evt) {
        let element = evt.target;
        if (element.type === "checkbox" && element.checked === false) {
            let contentParent = element.parentElement.parentElement.parentElement;
            let nodeInput = contentParent.querySelectorAll("input[type=number]");
            nodeInput.forEach(element => {
                element.value = "";
            });
            if (element.name === "siem") {
                this.setState(
                    {
                        countPaySiem: 0,
                        siemCheck: true,
                    }
                );
            }
            if (element.name === "afiliacion") {
                this.setState(
                    {
                        countPayAfiliacion: 0,
                        afiliacionCheck: true
                    }
                );
            }
        } else if (element.type === "checkbox" && element.checked === true) {
            if (element.name === "siem") {
                this.setState(
                    {
                        siemCheck: false,
                    });
            } else if (element.name === "afiliacion") {
                this.setState(
                    {
                        afiliacionCheck: false,
                    });
            }

        }


    }

    validateForm(evt) {
        let element = evt.target;
        if (element.type === "checkbox" && element.checked === false) {
            let contentParent = element.parentElement.parentElement.parentElement;
            let nodeInput = contentParent.querySelectorAll("input[type=number]");
            nodeInput.forEach(element => {
                element.value = "";
                element.readOnly = true;
                element.required = false;
            });

        }
        if (element.type === "checkbox" && element.checked === true) {
            let contentParent = element.parentElement.parentElement.parentElement;
            let nodeInput = contentParent.querySelectorAll("input[type=number]");
            nodeInput.forEach(element => {
                element.readOnly = false;
                element.required = true

            });

        }

    }

    countBill(arrayBill) {
        return arrayBill.reduce((accumulator, currentvalue) => accumulator + currentvalue.importe, 0);
    }

    downloadPFD() {
        const { cantidadPago, conceptoPago } = this.state;

        let adress = `${validateData(conceptoPago[0]?.tipoVialidad) || ""} ${validateData(conceptoPago[0]?.nombreVialidad) || ""} ${validateData(conceptoPago[0]?.numeroExterior) || ""} ${validateData(conceptoPago[0]?.numeroInterior) || ""}`;

        let exr = new RegExp("{|}", 'g');
        let conceptoRecibo = conceptoPago.map(value => {
            let anioConcepto = value.anioConcepto.replace(exr, "").split(",");
            let setAnios = new Set();
            anioConcepto.forEach((anio) => {
                setAnios.add(anio);
            });
            let stringAnios = Array.from(setAnios).join(",");
            let concepto = value.concepto.toUpperCase() === "AFILIACION" ? `${value.concepto} ${stringAnios}` : `registro ${value.concepto} ${stringAnios}`;
            return concepto.toUpperCase();
        });
        let engomado = conceptoPago.filter(pago => pago.concepto.toUpperCase() === "SIEM")[0]?.engomado.replace(exr, "").split(",").map(data => parseInt(data));
        let arrayDay = conceptoPago[0].fechaPago?.split("-");
        let fecha = arrayDay && `${arrayDay[2]}-${arrayDay[1]}-${arrayDay[0]}`;
        let dateEngomado = engomado ? (engomado.length > 1 ? ordenarData(engomado) : engomado[0]) : false;
        let datosPDF = {
            rfc: conceptoPago[0].rfc,
            nombre: conceptoPago[0].nombre,
            municipio: conceptoPago[0].municipio,
            estado: conceptoPago[0].estado,
            colonia: conceptoPago[0].colonia,
            telefono: conceptoPago[0].telefono,
            codigoPostal: conceptoPago[0].codigoPsotal,
            direccion: adress,
            conceptoRecibo,
            engomado: dateEngomado,
            fecha
        }

        this.setState(
            {
                buttonPDF: <PDFDownloadLink document={<ReciboPago cantidad={cantidadPago} conceptos={datosPDF} />} fileName="Recibo.pdf" className="disabled btn-pdf">
                    Descargar
            </PDFDownloadLink>
            });

        setTimeout(() => { document.querySelector(".btn-pdf").click() }, 400);

        function validateData(data) {
            return data ? (data === "S/D" || data === "S/N" ? false : data) : false;
        }
        function ordenarData(array) {
            let pibote = true, start, end;
            while (pibote) {
                let count = 0;
                array.forEach((data, index) => {
                    let nextData = array[index + 1] || false;
                    if (nextData && data > nextData) {
                        array[index + 1] = data;
                        array[index] = nextData;
                        count++;
                    }
                });

                pibote = count > 0;
            }

            start = array[0];
            end = array[array.length - 1];

            let result = end - start;

            let sumData = array.reduce((previus, current) => previus + current);
            let serializable = start * (result + 1) + ((result * (result + 1)) / 2);

            return sumData === serializable ? `${start}-${end}` : array.join(",");
        }

    }

    chooseTypePay(objPay) {
        let data = objPay.comercioPagoModeloLista.reduce((previus, current) => {
            let concepto = `${current.conceptoModelo.concepto}-${current.anioConcepto}`;
            let importe = `${current.conceptoModelo.concepto}-${current.importe}`
            previus.import.add(importe);
            if (current.engomado) {
                previus.engomado.add(current.engomado);
            }
            previus.arrayIdCommerce.add(current.comercioModelo.id);
            previus.concepto.add(concepto);
            return previus;


        }, { import: new Set(), engomado: new Set(), arrayIdCommerce: new Set(), concepto: new Set() });
        //console.log(data);

        let pibote = true, start = false, end = false, result = false, array = Array.from(data.engomado), commerces = [], sumData = 0, serializable = 0;
        while (pibote) {
            let count = 0;
            array.forEach((data, index) => {
                let nextData = array[index + 1] || false;
                if (nextData && data > nextData) {
                    array[index + 1] = data;
                    array[index] = nextData;
                    count++;
                }
            });

            pibote = count > 0;
        }
        if (array.length > 0) {
            start = array[0];
            end = array[array.length - 1];
            result = end - start;
            sumData = array.reduce((previus, current) => previus + current);
            serializable = start * (result + 1) + ((result * (result + 1)) / 2);
        }




        if (sumData === serializable && data.import.size < 3 && data.concepto.size < 3) {
            let importePago = Array.from(data.import);
            let siem = importePago.find(element => element.includes("SIEM"));
            let afiliacion = importePago.find(element => element.includes("AFILIACION"));
            data.concepto.forEach(pay => {
                let concepto = pay.split("-");
                let object = concepto[0] === "SIEM" ? {
                    anioConcepto: concepto[1],
                    nombreConcepto: concepto[0],
                    importe: siem.split("-")[1],
                    engomadoInicio: start
                } : {
                    anioConcepto: concepto[1],
                    nombreConcepto: concepto[0],
                    importe: afiliacion.split("-")[1],
                }
                commerces.push(object)
            });


            return {
                id: objPay.id,
                pago: objPay.pago,
                comercioPagoLista: commerces,
                idCommerces: Array.from(data.arrayIdCommerce)
            }
        } else { return false; }

    }
}

export default withLogin(Pagos);
