import React,{Component} from "react";
import { PDFViewer } from "@react-pdf/renderer";
import ReciboPago from "../Templates/recibo-pago";
import NumbertToLetter from "../Templates/number-to-letter"
class Pruebas extends Component {
    constructor(props){
        super(props);
    }

    render(){
       return(
           <PDFViewer width="80%" height="900">
               <ReciboPago cantidad={230} conceptos={[]} />
           </PDFViewer>
       ); 
    }
}

export default Pruebas;