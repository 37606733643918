import React, { Component } from "react";
import { FaArrowLeft,FaArrowRight } from "react-icons/fa";
import Button from "../Atoms/button";

class PaginationDisplay extends Component {
    constructor(props) {
        super(props);
        this.state ={
            page:0
        }

    }
    render() {
        
        return (
            <>
                {
                    this.props.children.map((child,index)=> {
                        let classVisible = index >= this.state.page && index < this.state.page + this.props.numberElement ? "enabled":"disabled";
                        return React.cloneElement(child,{
                            className: `${child.props.className} ${classVisible}`
                        })  
                    })
                }
                <div className={`margin-medium-top-2 ${this.props.children.length > this.props.numberElement ? "enabled" : "disabled" }`} onClick={(evt)=>{this.drawPages(evt) }}>
                    <Button styleClass={`${this.state.page <=0 ? "button__disabled" : "button__primary"} button--size-medium margin-medium-right-3`} text={<FaArrowLeft />} typeButton="button" reference="left" disabled={this.state.page <= 0}  />
                    <Button styleClass={`${this.state.page >= (this.props.children.length - this.props.numberElement)? "button__disabled":"button__primary"} button--size-medium`} text={<FaArrowRight />} typeButton="button" reference="right" disabled={this.state.page >= (this.props.children.length - this.props.numberElement)}/>
                </div>
                
            </>
        )
    }

    drawPages(evt) {
        let element = evt.target;
        let reference = element.dataset.reference || element.parentElement.dataset.reference || element.parentElement.parentElement.dataset.reference;
        if (reference) {
            switch (reference) {
                case "left":
                    this.setState((state) => {
                        return { page: state.page - this.props.numberElement }
                    }

                    );
                    break;
                case "right":
                    this.setState((state) => {
                        return { page: state.page + this.props.numberElement }
                    }
                    );
                    break;

                default:
                    break;
            }
        }

    }
}

export default PaginationDisplay;