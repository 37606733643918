import React, { Component } from "react";
import Header from "../Organisms/header";
import BoxInput from "../Molecules/box-input";
import Title from "../Atoms/title";
import Button from "../Atoms/button";
import { FaUser, FaUserTie, FaStoreAlt } from "react-icons/fa";
import Paginacion from "../Templates/paginacion";
import Tooltip from "../Molecules/tooltip";
import withLogin from "../HOC/withLogin";
import Spinner from "../Molecules/spinner";
import ModalDos, { ButtonModal } from "../Templates/modal-dos";
import { Link} from "react-router-dom";
import Autocompletado from "../Templates/autocompletado";
import Direction from "../Organisms/direccion";
class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            disabledOne: false,
            disabledTwo: false,
            disabledThree: false,
            messageModal: "¡ Se ha agregado un cliente correctamente !",
            formStatusInput: { nombre: "form__normal", apellidoPaterno: "form__normal", apellidoMaterno: "form__normal", moral: "form__normal", direccionFiscal: "form__normal", rfc: "form__normal" },
            clients: [],
            classDisabled: "form__disabled",
            clientsCommerce: [],
            tipoVialidad: [],
            giroComercial: [],
            //codigo postal
            colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "Sin Colonia" }],
            ciudad: "",
            municipioCiudad: "",
            //
            inputCommerceId: -1,
            inputVendorId: "a7a12106-95ad-4ea5-a9cb-da9a3619518c",
            sectionEnabled: ["enabled", "disabled", "disabled"],
            spinnerCommerce: false,
            spinnerClient: false,
            spinnerVendor: false,
            spinnerSearch: false,
            buttonDisabled: [false, true, false],
            inputSearchRfc: ["", false],
            inputSearchName: ["element-form__disable", true],
            clientAdd: false,
            nameClient: "",
            commerceAddId: false,
            //parametros del modal
            visibilityModal: "hidden",
            openModal: "",
            buttonModal: [],
            //parametros agregar codigo Postal
            otherPostCode: "disabled",
            postCodeAdd: false,
            enabledOtherPost: false,
            //parametro Busqueda Promotor
            vendors: [],
            actividadesComercio: [],
            resetAutocomplete: false,
            //parametro otra direccion Fiscal
            visibilityAddresBill: false,
            //validar tipo empresa
            typeCompany: [],
            typeCompanyDefault: false,
            //habilidar agregar direccion fiscal cliente
            enabledAdressClient: false

        }


        this.postalCodeInput = React.createRef();


    }
    render() {
        const { clientsCommerce, tipoVialidad, giroComercial, vendors, spinnerCommerce, spinnerClient, spinnerVendor, colonia, actividadesComercio, buttonModal, ciudad, municipioCiudad, spinnerSearch } = this.state;
        this.buttonsModalOne = [
            <Link className="button button__primary button--size-small margin-medium-right-3" to={`/pagos/commerce/${this.state.commerceAddId}:${this.state.inputCommerceId}`}>Pagar</Link>,
            <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__primary margin-medium-right-3" method={() => { this.updateTypeCompany() }} >Agregar comercio</ButtonModal>, <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} method={() => { this.resetCommerce() }} styleClass="button__secundary" >Salir</ButtonModal>];
        this.buttonsModalTwo = [<Link className="button button__primary button--size-small margin-medium-right-3" to={`/pagos/commerce/${this.state.commerceAddId}:${this.state.inputCommerceId}`}>Pagar</Link>, <ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__secundary" method={() => { this.resetCommerce() }} >Salir</ButtonModal>];
        this.buttonsModalThree = [<ButtonModal option={false} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="button__primary margin-medium-right-3" >Aceptar</ButtonModal>]
        return (
            <>
                <Header styleClass="layout-dashboard__header" />
                <main className="layout__main">
                    <section className={this.state.sectionEnabled[0]}>
                        <Title id="section-clients" type="title" content="Agregar Clientes" />
                        <form id="clientes" onChange={(e) => { this.disabledForm(e) }} onSubmit={(e) => { this.catchDataForm(e) }} action="" autoComplete="off">
                            <div className="margin-large-top-3" style={{ width: "500px" }}>
                                <BoxInput typeBox="description" styleClass="form__label" icon="RFC" statusInput={this.state.formStatusInput.rfc} minLength={10} maxLength={13} description="Escribir XXXX010101XXX si no cuenta el RFC" nameInput="rfc" />
                            </div>
                            <div id="form-persona-fisica" className="margin-large-top-3" style={{ display: "grid", gridTemplateColumns: "min-content 1fr", alignItems: "baseline" }}>
                                <BoxInput typeBox="checkbox" typeInput="radio" value="fisica" nameInput="TipoPersona" styleClass="form__label" icon="fisica" />
                                <div className="grid-layout grid-3">
                                    <BoxInput disabled={this.state.disabledOne} typeBox="description" typeInput="text" nameInput="apellidoPaterno" styleClass="form__label" description="Apellido Paterno" statusInput={this.state.formStatusInput.apellidoPaterno || "form__normal"} minLength={3} maxLength={40} />
                                    <BoxInput disabled={this.state.disabledOne} typeBox="description" typeInput="text" nameInput="apellidoMaterno" styleClass="form__label" description="Apellido Materno" statusInput={this.state.formStatusInput.apellidoMaterno || "form__normal"} minLength={3} maxLength={40} />
                                    <BoxInput typeBox="description" typeInput="text" disabled={this.state.disabledOne} nameInput="nombre" styleClass="form__label" description="Nombre" statusInput={this.state.formStatusInput.nombre || "form__normal"} minLength={3} maxLength={50} />
                                </div>

                            </div>
                            <div id="form-persona-moral" className="margin-large-top-3" style={{ display: "grid", gridTemplateColumns: "min-content 1fr", alignItems: "baseline" }}>
                                <BoxInput typeBox="checkbox" typeInput="radio" value="moral" nameInput="TipoPersona" styleClass="form__label" icon="moral" />
                                <div >
                                    <BoxInput typeBox="description" styleClass="form__label" typeInput="text" disabled={this.state.disabledTwo} nameInput="moral" description="Escriba el nombre de la empresa tal y como se dio de alta en la SHCP, sin abreviar, sin guiones y sin comillas" statusInput={this.state.formStatusInput.moral || "form__normal"} minLength={3} maxLength={100} />
                                </div>
                            </div>
                            <div className="margin-medium-top-3" style={{ width: "600px" }}>
                                <BoxInput typeBox="sample" typeInput="text" defaultValue="S/N" styleClass="form__label" icon="Teléfono" statusInput="form__normal" nameInput="telefono" maxLength={33} />
                                <div className="margin-medium-top-3" >
                                    <label style={{ fontWeight: "700" }} htmlFor="">Datos importantes</label>
                                    <textarea className="form__text-area form__normal margin-medium-top-2" name="datosImportantes" id="" rows="6"></textarea>
                                </div>
                            </div>
                          
                            <div className="margin-medium-top-3" onChange={(evt) => { this.setState({ enabledAdressClient: evt.target.checked }) }}>
                                <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Si el domicilio de comercio no corresponde a la dirección fiscal, marca aquí" styleClass="form__label" />
                            </div>
                            {
                                this.state.enabledAdressClient ?
                                    <>
                                        <h4 className="margin-medium-top-3 color-title__primary">Ingresar dirección fiscal</h4>
                                        <Direction modal={(message, opcion) => { this.activeModal(message, opcion) }} optionModal={3} colonia={this.state.colonia} />


                                    </> : <></>
                            }
                            <Button styleClass="button__primary button--size-medium margin-medium-top-2" text="Agregar" disabled={this.state.buttonDisabled[0]} /> {spinnerClient ? <Spinner text="Enviando" /> : <></>}

                        </form>
                    </section>
                    <section className={this.state.sectionEnabled[1]}>
                        <Title id="section-commerce" type="title" content="Agregar Comercio" styleClass="margin-large-top-1" />
                        {
                            this.state.clientAdd ? <p className="margin-large-top-3" style={{ fontWeight: "700" }}>Cliente: {this.state.clientAdd} Nombre: {this.state.nameClient} </p> :
                                <>
                                    <form id="found-client" data-type-person="cliente" action="" className="margin-large-top-3" onSubmit={(evt) => { this.searchPerson(evt) }}>
                                        <h3>Asignar cliente</h3>
                                        <div className="grid-layout grip-gap margin-large-top-3" style={{ gridTemplateColumns: "repeat(3,max-content)" }} >
                                            <BoxInput typeBox="checkbox" typeInput="radio" statusInput="form__normal" styleClass="form__label" icon="RFC" nameInput="tipoPersona" value="rfc" />
                                            <BoxInput typeBox="checkbox" typeInput="radio" statusInput="form__normal" styleClass="form__label" icon="Nombre" nameInput="tipoPersona" value="nombre" />
                                            <BoxInput typeBox="checkbox" typeInput="radio" statusInput="form__normal" styleClass="form__label" icon="Numero de cliente" nameInput="tipoPersona" value="number" />
                                        </div>
                                        <div className="margin-medium-top-2" style={{ alignItems: "end" }} >
                                            {/* <BoxInput typeBox="sample" typeInput="text" nameInput="rfc" styleClass="form__label" icon="RFC" statusInput ="form__normal"/> */}
                                            <div style={{ width: "500px" }}>
                                                <BoxInput typeBox="input" styleClass={"form__label"} icon="Dato a buscar" >
                                                    <input type="text" name="name" className="form__input form__normal" />
                                                </BoxInput>
                                            </div>
                                            <div className="margin-medium-top-2">
                                                <Button styleClass="button__primary button--size-medium" text="Buscar" />
                                                {spinnerSearch && <Spinner text="Buscando" />}
                                            </div>

                                        </div>
                                    </form>
                                    <div className="margin-large-top-1" onChange={(evt) => { this.selectedClient(evt) }}>
                                        <Paginacion numberElement={8}>
                                            {
                                                clientsCommerce.map(client => {
                                                    if (client.id === 0) {
                                                        return <p key={client.id} style={{ fontWeight: "bold" }}>NO EXISTEN COINCIDENCIAS EN LA BUSQUEDA</p>
                                                    } else {
                                                        return (
                                                            <div key={client.id} className="margin-small-top-3">
                                                                <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" nameInput="client" value={client.id} icon={`Nombre: ${client.nombre ? `${client.nombre} ${client.apellidoPaterno} ${client.apellidoMaterno}` : client.moral} RFC: ${client.rfc}`} />
                                                            </div>
                                                        )
                                                    }
                                                }
                                                )

                                            }
                                        </Paginacion>
                                    </div>
                                </>
                        }



                        <form id="commerce" className={`margin-large-top-3 ${this.state.classDisabled}`} onSubmit={(evt) => { this.catchDataForm(evt) }}>
                            <div style={{ width: "500px" }}>
                                {
                                    this.state.resetAutocomplete ? <></> :
                                        <Autocompletado icon="Promotor" nameInput="promotorModelo">
                                            {
                                                vendors.map(
                                                    (vendor, index) => (
                                                        <li key={index} tabIndex={-1} data-value={vendor.id}>
                                                            {`${vendor.apellidoPaterno} ${vendor.apellidoMaterno} ${vendor.nombre}`}
                                                        </li>
                                                    )
                                                )
                                            }
                                        </Autocompletado>
                                }
                            </div>
                            <h3 className="margin-large-top-3">Ingresar datos del comercio</h3>
                            <h4 className="margin-medium-top-3 color-title__primary">-Datos Generales----</h4>
                            <div className="margin-medium-top-3">
                                <BoxInput typeBox="description" styleClass="form__label" description="Escriba el nombre como se le conoce al establecimiento, por ejemplo: Tortillería María" typeInput="text" statusInput="form__normal" icon="Nombre Comercial" nameInput="nombre" defaultValue="S/D" maxLength={100} />
                            </div>
                            <h5 className="margin-medium-top-3 color-title__primary" style={{fontSize:"17px"}} >-Ubicación del Comercio--</h5>
                            <div className="grid-layout  grip-gap layout__ubication margin-large-top-2">
                                <label htmlFor="" className="content-element__form-sample">
                                    <span className="form__label">Tipo Vialidad</span>
                                    <select name="tipoVialidadModelo" className="form__input form__normal" >
                                        {
                                            tipoVialidad.map(
                                                vialidad => (
                                                    <option key={vialidad.id} value={vialidad.id}>{vialidad.descripcion}</option>
                                                )
                                            )
                                        }
                                    </select>
                                </label>
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" icon="Nombre de la vialidad" statusInput="form__normal" nameInput="nombreVialidad" defaultValue="S/D" maxLength={50} />
                            </div>
                            <div className="margin-large-top-3 grid-layout grip-gap grid-2" style={{ width: "550px" }}>
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" icon="Número Exterior" statusInput="form__normal" nameInput="numeroExterior" defaultValue="S/N"  maxLength={25} />
                                <BoxInput typeBox="sample" typeInput="text" defaultValue="S/N" styleClass="form__label" icon="Número Interior" statusInput="form__normal" nameInput="numeroInterior" maxLength={25} />
                            </div>
                            <div className="grid-layout grip-gap margin-medium-top-3" style={{ gridTemplateColumns: "2fr 2fr 1fr", width: "1200px" }}   >
                                <div className="grid-layout grip-gap " style={{ gridTemplateColumns: "1fr max-content" }}  >
                                    <div >
                                        <BoxInput typeBox="input" styleClass="form__label" icon="Código postal">
                                            <input type="text" maxLength={5} className="form__normal form__input" name="codigoPostal" placeholder="00000" ref={this.postalCodeInput} />
                                        </BoxInput>

                                    </div>
                                    <div>
                                        <button type="button" className="button button__primary button--size-medium" onClick={() => { this.getPostalCode() }} >consultar</button>
                                    </div>
                                </div>
                                <label htmlFor="" className="content-element__form-sample">
                                    <span className="form__label">Colonia</span>
                                    <select name="coloniaModelo" className="form__input form__normal" >
                                        {colonia.length <= 0 ? <option>cargando......</option> :
                                            colonia.map(
                                                colonia => (
                                                    <option key={colonia.id} value={colonia.id}>{colonia.nombre}</option>
                                                )
                                            )
                                        }

                                    </select>
                                </label>
                                <div onChange={(evt) => { this.otherPostCode(evt) }}>
                                    <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Otro" styleClass="form__label" />
                                </div>
                            </div>
                            <div className="margin-medium-top-3">
                                <span className="margin-medium-right-3" style={{ fontWeight: 700 }}>Estado: {ciudad || "S/D"}</span>
                                <span style={{ fontWeight: 700 }} >Municipio: {municipioCiudad || "S/D"}</span>
                            </div>
                            <div className={`margin-medium-top-3 ${this.state.otherPostCode}`} style={{ width: "600px" }}>
                                <BoxInput typeBox="sample" typeInput="text" icon="Nueva colonia" styleClass="form__label" statusInput="form__normal" nameInput="nuevaColonia" />
                            </div>
                            <div className="margin-medium-top-2 grid-layout grip-gap" style={{ gridTemplateColumns: "min-content min-content" }} >
                                <span>
                                    <BoxInput typeBox="checkbox" styleClass="form__label" nameInput="direccionFiscal" statusInput="form__normal" typeInput="checkbox" icon="Si este domicilio de comercio se desea utilizarlo como dirección fiscal, marca aquí" value="true" />
                                </span>
                                {/* <span onChange={(evt) => { this.setState({ visibilityAddresBill: evt.target.checked }) }} >
                                    <BoxInput typeBox="checkbox" styleClass="form__label" value="true" typeInput="checkbox" icon="Otra dirección" />
                                </span> */}
                            </div>

                            {/* <h4 className={`${visibilityAddresBill ? "enabled" : "disabled"} margin-medium-top-3`} >Si la direccíon fiscal del cliente no corresponde a la del comercio escribir en este campo</h4>
                            <div className={`${visibilityAddresBill ? "enabled" : "disabled"} margin-medium-top-3`}>
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" nameInput="direccionFiscalAlterna" icon="Dirección" statusInput="form__normal" />
                            </div> */}
                            <h4 className="margin-medium-top-3 color-title__primary">-Entrevialidades----</h4>
                            <div className="margin-medium-top-3">
                                <div>
                                    <BoxInput typeBox="sample" typeInput="text" icon="Nombre vialidad" styleClass="form__label" statusInput="form__normal" nameInput="nombreVialidadUno" defaultValue="S/D" maxLength={50} />
                                </div>
                                <div className="margin-medium-top-3">
                                    <BoxInput typeBox="sample" typeInput="text" icon="Nombre vialidad" styleClass="form__label" statusInput="form__normal" nameInput="nombreVialidadDos" defaultValue="S/D" maxLength={50} />
                                </div>
                            </div>
                            <h4 className="margin-medium-top-3 color-title__primary">-Datos de contacto del establecimiento para negocios----</h4>
                            <div className="margin-medium-top-3">
                                <div className="grid-layout grid-2 grip-gap">
                                    <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="nombreContacto" defaultValue="S/D" icon="Nombre" maxLength={80} />
                                    <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="puestoContacto" defaultValue="S/D" icon="Puesto" maxLength={20} />

                                </div>
                                <div className="grid-layout grid-3 grip-gap margin-medium-top-2">
                                    <BoxInput typeBox="sample" typeInput="email" styleClass="form__label" statusInput="form__normal" nameInput="correoElectronico" defaultValue="S/D" icon="Email" maxLength={40} />
                                    <BoxInput typeBox="input" styleClass="form__label" icon="Teléfono">
                                        <input type="text" className="form__normal form__input" name="telefono" placeholder="S/D" maxLength={10} />
                                    </BoxInput>
                                    <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" nameInput="extension" defaultValue="S/D" icon="Extensión" maxLength={4} />

                                </div>
                                <div className="grid-layout grid-3 grip-gap margin-medium-top-2">
                                    <BoxInput typeBox="input" styleClass="form__label" icon="Whatsapp">
                                        <input type="text" className="form__normal form__input" name="whatsapp" placeholder="S/D" maxLength={10} />
                                    </BoxInput>
                                </div>
                            </div>
                            <h4 className="margin-medium-top-3 color-title__primary">-Perfil general de la empresa----</h4>
                            <div className="grid-layout grid-2 grip-gap margin-medium-top-3">
                                <BoxInput typeBox="sample" typeInput="date" styleClass="form__label" icon="Fecha de Inicio de operaciones" statusInput="form__normal" nameInput="inicioOperaciones" defaultValue="S/D" />
                                <div className="layout-form__type-company">
                                    <span className="label-font">Empresa</span>
                                    <div className="grid-layout grid-3 grip-gap" style={{ justifyItems: "center" }} onChange={(evt) => { this.validateTypeCompany(evt) }}>
                                        <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" statusInput="form__normal" value="UNICA" nameInput="tipoEmpresa" icon="Única" />
                                        <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" statusInput="form__normal" value="MATRIZ" nameInput="tipoEmpresa" icon="Matriz" />
                                        <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" statusInput="form__normal" value="SUCURSAL" nameInput="tipoEmpresa" icon="Sucursal" />
                                    </div>
                                </div>

                            </div>
                            <h4 className="margin-medium-top-3 color-title__primary">-Actividad económica----</h4>
                            <div className="margin-medium-top-3">
                                {
                                    this.state.resetAutocomplete ? <></> :
                                        <Autocompletado icon="Giro Comercial" nameInput="actividadPrincipal" >
                                            {
                                                actividadesComercio.map(actividad => <li tabIndex={-1} data-value={actividad} >{actividad}</li>)
                                            }
                                        </Autocompletado>
                                }
                            </div>
                            <h4 className="margin-medium-top-3">Llenar solo si desea crear un nuevo Giro Comercial</h4>
                            <div className="margin-medium-top-1">
                                <BoxInput typeBox="description" typeInput="text" statusInput="form__normal" styleClass="form__label" nameInput="actividadPrincipalCrear" description="¿Cuál es la actividad principal del establecimiento según los ingresos obtenidos?" defaultValue="S/D" maxLength={150} />
                            </div>
                            <div className="margin-medium-top-3" style={{ width: "1000px" }}>
                                {
                                    this.state.resetAutocomplete ? <></> :
                                        <Autocompletado icon="Codigo SCIAN" nameInput="giroComercialModelo">
                                            {
                                                giroComercial.map(
                                                    giro => (
                                                        <li key={giro.id} tabIndex={-1} data-value={giro.id}>{`${giro.id}-${giro.descripcion}`}</li>
                                                    )
                                                )
                                            }
                                        </Autocompletado>
                                }
                            </div>
                            <h4 className="margin-medium-top-3 color-title__primary">-Personal ocupado----</h4>
                            <div className="margin-medium-top-3 layout-form__type-company" style={{ width: "80%" }}>
                                <span className="label-font">¿Cuántas personas trabajaron para este establecimiento?</span>
                                <div className="grid-layout grid-2 grip-gap" style={{ justifyItems: "center" }}>
                                    <BoxInput typeBox="sample" typeInput="number" styleClass="form__label" statusInput="form__normal form__input--medium" nameInput="trabajadorHombre" icon="Total" defaultValue="S/N" />
                                    <BoxInput typeBox="sample" typeInput="number" styleClass="form__label" statusInput="form__normal form__input--medium" nameInput="trabajadorMujer" icon="Mujeres" defaultValue="S/N" />

                                </div>
                            </div>
                            <h4 className="margin-medium-top-3 color-title__primary">-Información comercial----</h4>
                            <div className="margin-medium-top-3">
                                <h5>Escriba la url de los sitios de internet correspondientes. Por ejemplo: https://es-es.facebook.com/miempresa_mx</h5>
                                <div className="margin-medium-top-3">
                                    <BoxInput typeBox="sample" typeInput="text" nameInput="facebook" styleClass="form__label" statusInput="form__normal" icon="Facebook" defaultValue="S/D" maxLength={50} />
                                </div>
                                <div className="margin-medium-top-3">
                                    <BoxInput typeBox="sample" typeInput="text" nameInput="twitter" styleClass="form__label" statusInput="form__normal" icon="Twitter" defaultValue="S/D" maxLength={15} />
                                </div>

                            </div>

                            <input id="client-id" name="id" type="hidden" value={this.state.inputCommerceId} />
                            {/* <input type="hidden" name="promotorModelo" value={this.state.inputVendorId} /> */}
                            <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Agregar" disabled={this.state.buttonDisabled[1]} />
                            {
                                spinnerCommerce ? <Spinner text="Enviando" /> : <></>
                            }
                        </form>
                    </section>
                    <section className={this.state.sectionEnabled[2]}>
                        <Title id="section-seller" type="title" content="Agregar promotor" styleClass="margin-large-top-3" />
                        <form id="seller" onSubmit={(evt) => { this.catchDataForm(evt) }}>
                            <div className="grid-layout grid-3 grip-gap margin-medium-top-3">
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" icon="Nombre" nameInput="nombre" />
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" icon="Apellido Paterno" nameInput="apellidoPaterno" />
                                <BoxInput typeBox="sample" typeInput="text" styleClass="form__label" statusInput="form__normal" icon="Apellido Materno" nameInput="apellidoMaterno" />
                            </div>
                            <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Agregar" disabled={this.state.buttonDisabled[2]} />{spinnerVendor ? <Spinner text="Enviando" /> : <></>}

                        </form>
                    </section>

                </main>
                <aside className="layout__aside ">
                    <div className="nav__hash" onClick={(evt) => { this.selectSection(evt) }}>
                        <Tooltip text="Cliente" position="tooltip--right" styleComponent="margin-icon">
                            <FaUser data-option="user" />
                        </Tooltip>
                        <Tooltip text="Comercio" position="tooltip--right" styleComponent="margin-icon">
                            <FaStoreAlt data-option="commerce" />
                        </Tooltip>
                        <Tooltip text="Promotor" position="tooltip--right">
                            <FaUserTie data-option="seller" />
                        </Tooltip>
                    </div>
                </aside>
                <ModalDos stateModal={this.state.visibilityModal} modalOpen={this.state.openModal} sizeModal="modal__size--medium" text={this.state.messageModal}>
                    {
                        buttonModal.map(button => button)
                    }
                </ModalDos>
                <ButtonModal option={true} openModal={(openModal) => { this.setState({ openModal }) }} visibilityModal={(visibilityModal) => { this.setState({ visibilityModal }) }} styleClass="disabled" id="btn-modal" >Probar</ButtonModal>

            </>
        );
    }

    showModal(message) {
        // console.log("entro");
        this.setState(
            {
                show: true,
                messageModal: message
            }
        );
        // console.log(this.state.show);
    }
    hideModal() {
        this.setState(
            { show: false }
        );
    }

    disabledForm(e) {
        let element = e.target;
        if (element.type === "radio") {
            switch (element.value) {
                case "fisica":
                    this.setState(
                        {
                            disabledOne: false,
                            disabledTwo: true
                        }
                    );
                    document.getElementById('form-persona-moral').classList.add('form__disabled');
                    document.getElementById('form-persona-fisica').classList.remove('form__disabled');
                    break;
                case "moral":
                    this.setState(
                        {
                            disabledOne: true,
                            disabledTwo: false
                        }
                    );
                    document.getElementById('form-persona-fisica').classList.add('form__disabled');
                    document.getElementById('form-persona-moral').classList.remove('form__disabled');
                    break;

                default:
                    break;
            }
        }


    }

    catchDataForm(event) {
        event.preventDefault();
        // console.log("entro dato");
        const dataForm = new FormData(event.target);
        dataForm.delete("TipoPersona");
        this.insert(dataForm, event.target);
    }

    resetFormClient() {
        document.querySelector('input[value=fisica]').checked = true;
        this.setState(
            {
                disabledOne: false,
                disabledTwo: true
            }
        );
        document.getElementById('form-persona-moral').classList.add('form__disabled');
        document.getElementById('form-persona-fisica').classList.remove('form__disabled');
    }
    async componentDidMount() {
        this.resetFormClient();
        let tipoVialidad = await this.read(`${this.props.hosting}/webservice/catalogos/tipo-vialidad`);
        let giroComercial = await this.read(`${this.props.hosting}/webservice/catalogos/giro-comercial`);
        let vendors = await this.read(`${this.props.hosting}/webservice/promotor?estado=true`);
        let actividadesComercio = await this.read(`${this.props.hosting}/webservice/comercio/actividadPrincipal`);
        const {id,name} =  this.props.match.params;
        if (id) {
            this.setState(
                {
                    clientAdd: id,
                    nameClient: name,
                    sectionEnabled: ["disabled", "enabled", "disabled"],
                    classDisabled: "",
                    buttonDisabled: [false, false, false],
                    inputCommerceId: id,
                    typeCompanyDefault: "SUCURSAL",
                    tipoVialidad,
                    giroComercial,
                    vendors,
                    actividadesComercio
                });
        }else{
            this.setState(
                {
                    tipoVialidad,
                    giroComercial,
                    vendors,
                    actividadesComercio
                }
            );
        }
        
       
    }

    async insert(data, element) {
        let obj = {}, rfcExp = new RegExp("^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z|0-9]{3})?$"), optionName = {}, sendBoolean = false, objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {}
        };
        let objDataPut = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: {}
        }

        switch (element.getAttribute("id")) {
            case "clientes":
                optionName = { nombre: "form__normal", apellidoPaterno: "form__normal", apellidoMaterno: "form__normal", moral: "form__normal", rfc: "form__normal" }
                for (const pair of data.entries()) {
                    obj[pair[0]] = pair[1].toUpperCase();
                    if (this.state.formStatusInput.hasOwnProperty(pair[0])) {
                        if (pair[1] === "") {
                            optionName[pair[0]] = "form__danger";
                        } else { optionName[pair[0]] = "form__normal"; }
                    }
                }
                obj.rfc = obj.rfc === "" ? "XXXX010101XXX" : obj.rfc.toUpperCase();
                sendBoolean = Object.values(optionName).includes("form__danger");
                this.setState(
                    {
                        formStatusInput: optionName
                    }
                );
                if (sendBoolean) {
                    this.activeModal("Favor de llenar los campos que faltan", 3);
                } else {
                    // obj.direccionFiscal = "S/D"

                    if (obj.otroCodigo) {

                        let objPostCode = {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                coloniaModeloLista: [
                                    {
                                        nombre: obj.nuevaColonia
                                    },
                                ],
                                idMunicipio: obj.idMunicipio
                            }),

                        }
                        let response = await this.create(`${this.props.hosting}/webservice/direccion/colonia/${obj.codigoPostal}`, objPostCode, "true");
                        //preguntar si se en la respuesta puede regresar el id del codigoPostalcreado creado
                        if (response) {
                            obj.coloniaModelo = response.coloniaModeloLista[0].id;
                        }

                    }

                    this.setState({ spinnerClient: true });
                    let lastClient = await (await fetch(`${this.props.hosting}/webservice/cliente/ultimo`)).json();
                    let objAddAdress = false;
                    let objClient = obj.nombre ? {
                        id: lastClient + 1,
                        rfc: obj.rfc,
                        nombre: obj.nombre,
                        apellidoPaterno: obj.apellidoPaterno,
                        apellidoMaterno: obj.apellidoMaterno,
                        telefono: obj.telefono === "" ? "S/D" : obj.telefono,
                        datosImportantes: obj.datosImportantes === "" ? "S/D" : obj.datosImportantes
                    } : {
                        id: lastClient + 1,
                        rfc: obj.rfc,
                        moral: obj.moral,
                        telefono: obj.telefono === "" ? "S/D" : obj.telefono,
                        datosImportantes: obj.datosImportantes === "" ? "S/D" : obj.datosImportantes
                    }

                    if (this.state.enabledAdressClient) {
                        objAddAdress = {
                            idCliente: lastClient + 1,
                            tipoVialidadModelo: { id: obj.tipoVialidadModelo },
                            nombreVialidad: obj.nombreVialidad === "" ? "S/D" : obj.nombreVialidad,
                            numeroExterior: obj.numeroExterior === "" ? "S/D" : obj.numeroExterior,
                            numeroInterior: obj.numeroInterior === "" ? "S/D" : obj.numeroInterior,
                            coloniaModelo: { id: obj.coloniaModelo }
                        }
                    }


                    let response = await fetch(`${this.props.hosting}/webservice/cliente`, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(objClient) });
                    if (this.state.enabledAdressClient) {
                        let responseAdress = await fetch(`${this.props.hosting}/webservice/direccion-fiscal`, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(objAddAdress) });
                    }
                    //let dataResponse = await response.text();
                    // console.log(dataResponse);
                    if (response.ok) {
                        element.reset();
                        let nombre = obj.hasOwnProperty("moral") ? obj.moral : `${obj.nombre} ${obj.apellidoPaterno} ${obj.apellidoMaterno}`
                        let arrayTypeCompany = await this.read(`${this.props.hosting}/webservice/comercio/tipoEmpresa/${objClient.id}`);
                        this.assingTypeCompanyDefault(arrayTypeCompany);
                        this.setState(
                            {
                                spinnerClient: false,
                                clientAdd: objClient.id,
                                nameClient: nombre,
                                sectionEnabled: ["disabled", "enabled", "disabled"],
                                classDisabled: "",
                                buttonDisabled: [false, false, false],
                                inputCommerceId: objClient.id,
                                typeCompany: arrayTypeCompany || []
                            });

                    }
                    // let responseData = await response.json();

                    // element.reset();
                }

                break;
            case "commerce":
                let objCommerce = {}, objBetweenRoadsOne = {}, objBetweenRoadsTwo = {}, objContact = {};


                for (const pair of data.entries()) {

                    obj[pair[0]] = this.validateForm(pair[1].toUpperCase());
                }

                if (this.state.otherPostCode === "enabled") {
                    const { idMunicipio, nombre } = this.state.postCodeAdd;
                    let objPostCode = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            coloniaModeloLista: [
                                {
                                    nombre: obj.nuevaColonia
                                },
                            ],
                            idMunicipio
                        }),

                    }
                    let response = await this.create(`${this.props.hosting}/webservice/direccion/colonia/${nombre}`, objPostCode, "true");
                    //preguntar si se en la respuesta puede regresar el id del codigoPostalcreado creado
                    if (response) {
                        obj.coloniaModelo = response.coloniaModeloLista[0].id;
                       
                    }

                }

                let { numeroExterior, trabajadorHombre, trabajadorMujer } = obj;
                obj.tipoVialidadModelo = { id: obj.tipoVialidadModelo};
                obj.giroComercialModelo = { id: obj.giroComercialModelo === "S/D" ? "000000" : obj.giroComercialModelo };
                obj.promotorModelo = { id: obj.promotorModelo === "S/D" ? "a7a12106-95ad-4ea5-a9cb-da9a3619518c" : obj.promotorModelo.toLowerCase() };
                obj.numeroExterior = numeroExterior;
                obj.trabajadorHombre = this.validateNumber(trabajadorHombre);
                obj.trabajadorMujer = this.validateNumber(trabajadorMujer);
                //crear objeto Commercio
                objCommerce.nombre = obj.nombre;
                objCommerce.tipoVialidadModelo = obj.tipoVialidadModelo;
                objCommerce.giroComercialModelo = obj.giroComercialModelo;
                objCommerce.nombreVialidad = obj.nombreVialidad
                objCommerce.numeroExterior = obj.numeroExterior  === "" ? "S/N" : obj.numeroExterior;
                objCommerce.numeroInterior = obj.numeroInterior === "" ? "S/N" : obj.numeroInterior;
                objCommerce.coloniaModelo = { id: obj.coloniaModelo.toLowerCase() };
                objCommerce.inicioOperaciones = obj.inicioOperaciones === "S/D" ? "0000-01-01" : obj.inicioOperaciones;
                objCommerce.tipoEmpresa = obj.tipoEmpresa || this.state.typeCompanyDefault;
                objCommerce.actividadPrincipal = obj.actividadPrincipal === "S/D" && obj.actividadPrincipalCrear === "S/D" ? "S/D" : obj.actividadPrincipal === "S/D" ? obj.actividadPrincipalCrear : obj.actividadPrincipal;
                objCommerce.trabajadorHombre = obj.trabajadorHombre;
                objCommerce.trabajadorMujer = obj.trabajadorMujer;
                objCommerce.facebook = obj.facebook;
                objCommerce.twitter = obj.twitter;
                objCommerce.promotorModelo = obj.promotorModelo;
                objCommerce.direccionFiscal = obj.direccionFiscal === "TRUE" || false;
                /**ver si hay otra direccion fiscal */
                // let addAdress = obj.direccionFiscalAlterna !== "S/D" ? obj.direccionFiscalAlterna : false;
                //crear objeto entre vialidad
                objBetweenRoadsOne.idComercio = "";
                objBetweenRoadsOne.nombre = obj.nombreVialidadUno;
                objBetweenRoadsTwo.idComercio = "";
                objBetweenRoadsTwo.nombre = obj.nombreVialidadDos;
                //crear objeto contacto
                objContact.nombre = obj.nombreContacto.concat(":", obj.puestoContacto);
                objContact.telefono = obj.telefono;
                objContact.correoElectronico = obj.correoElectronico;
                objContact.whatsapp = obj.whatsapp;
                objContact.extension = obj.extension;
                // console.log(objCommerce);
                if (parseInt(obj.id) === -1 || this.state.typeCompanyDefault === "invalida") {
                    // this.showModal("Favor de asignar un cliente");

                    this.activeModal(obj.id === -1 ? "Favor de asignar un cliente" : "No se puede agregar este comercio por que el cliente ya cuenta con un comercio con tipo de empresa  \"única\". Se recomienda actulizar el comercio anterior con matriz o sucursal", 3)
                    window.scroll(0, 0);
                } else {
                    this.setState({ spinnerCommerce: true });
                    objData.body = JSON.stringify(objCommerce);

                    if (this.state.typeCompany.length > 0 && objCommerce.direccionFiscal) {
                        if (!(await this.changeAdressCommerce(obj.id))) {
                            this.activeModal("Error al agregar comercio", 3)
                            return 0;
                        }
                    }
                    //agregar Comercio
                    // console.log(objCommerce);

                    let responseDataCommerce = await this.create(`${this.props.hosting}/webservice/comercio/${obj.id}`, objData);
                    if (responseDataCommerce) {
                        let responseDirectionBill = null;
                        objBetweenRoadsOne.idComercio = responseDataCommerce.id;
                        objBetweenRoadsTwo.idComercio = responseDataCommerce.id;
                        objData.body = JSON.stringify(objBetweenRoadsOne);
                        let responseDataBetweenRoadsOne = await this.create(`${this.props.hosting}/webservice/entre-vialidad`, objData);
                        objData.body = JSON.stringify(objBetweenRoadsTwo);
                        let responseDataBetweenRoadsTwo = await this.create(`${this.props.hosting}/webservice/entre-vialidad`, objData);

                        objData.body = JSON.stringify(objContact);
                        let responseDataContact = await this.create(`${this.props.hosting}/webservice/datos-contacto/${responseDataCommerce.id}`, objData, "false");
                        // if (this.state.visibilityAddresBill && addAdress) {
                        //     objDataPut.body = JSON.stringify({ direccionFiscal: addAdress });
                        //     responseDirectionBill = await fetch(`${this.props.hosting}/webservice/cliente/direccion/${obj.id}`, objDataPut);

                        // }

                        if (responseDataBetweenRoadsOne && responseDataBetweenRoadsTwo && responseDataContact) {
                            element.reset();
                            window.scroll(0, 0);
                            this.setState(
                                {
                                    spinnerCommerce: false,
                                    classDisabled: this.state.clientAdd ? "" : "form__disabled",
                                    //inputCommerceId: this.state.clientAdd ? this.state.inputCommerceId : -1,
                                    inputVendorId: "a7a12106-95ad-4ea5-a9cb-da9a3619518c",
                                    commerceAddId: responseDataCommerce.id,
                                    colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "Sin Colonia" }],
                                    resetAutocomplete: true
                                });


                            if (objCommerce.tipoEmpresa === "UNICA") {
                                this.activeModal("Se agrego un comercio correctamente,Desea realizar un pago", 2);
                            } else {
                                this.activeModal("Se agrego un comercio correctamente,Desea realizar un pago o agregar otro comercio", 1);
                            }
                            this.setState({ resetAutocomplete: false })

                        } else {
                            this.activeModal("Ocurrio un error", 3);
                        }


                    }

                }
                break;
            case "seller":
                let responseData = null;
                for (const pair of data.entries()) {
                    obj[pair[0]] = this.validateForm(pair[1].toUpperCase());
                }
                objData.body = JSON.stringify(obj);
                this.setState({ spinnerVendor: true });
                responseData = await this.create(`${this.props.hosting}/webservice/promotor`, objData);
                if (responseData) {
                    this.setState({ spinnerVendor: false });
                    this.activeModal("Se agrego promotor correctamente", 3)
                    element.reset();
                } else {
                    this.setState({ spinnerVendor: false });
                    this.activeModal("Error no se puso agregar promotor", 3)
                }
                break;
            default:
                break;
        }


    }

    async read(url) {
        const response = await fetch(url);
        let dataResponse = null;
        switch (response.status) {
            case 200:
                dataResponse = await response.json();

                break;
            case 204:
                dataResponse = false;
                break;

            default:
                break;
        }
        return dataResponse;

    }
    async create(url, dataObj, needResponse) {
        let response = await fetch(url, dataObj);
        let dataResponse;
        needResponse = needResponse || "true";
        if (response.status === 201) {
            dataResponse = needResponse === "false" ? true : await response.json();

        } else { dataResponse = false }
        return dataResponse;
    }
    async update(url, contentBody) {
        let data, response;
        const objData = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true
            }
            // console.log(contentBody);

        } catch (error) {
            // console.log(error);
            // console.log(contentBody);
            data = false;
        } finally { return data }


    }

    async searchPerson(evt) {
        evt.preventDefault();
        this.setState({spinnerSearch:true});
        let typePerson = evt.target.dataset.typePerson;
        const objForm = new FormData(evt.target);
        let stringParam = null;
        if (objForm.has("tipoPersona")) {
            switch (objForm.get("tipoPersona")) {
                case "rfc":
                    stringParam = `?estado=true&rfc=${objForm.get("name")}`;
                    break;
                case "nombre":
                    stringParam = `?estado=true&palabra=${objForm.get("name")}`;
                    break;
                case "number":
                    stringParam = `/${objForm.get("name")}`;
                    break;
                default:
                    break;
            }
        } else { stringParam = `?estado=true&palabra=${objForm.get("name")}` }


        let data = await this.read(`${this.props.hosting}/webservice/${typePerson}${stringParam}`);
        data = data ? (Array.isArray(data) ? data : [data]):false;
        
        //console.log(data);
        switch (typePerson) {
            case "cliente":
                this.setState(
                    {
                        clientsCommerce: data || [{ id: 0 }],
                        // classDisabled: data.length > 0 ? "" : "form__disabled",
                        buttonDisabled: [false, false, false],
                        spinnerSearch:false
                    }
                );
                break;
            case "promotor":

                this.setState(
                    {
                        vendors: data || [{ id: 0 }],
                        spinnerSearch:false
                    }
                );
                break;

            default:
                break;
        }

    
    }

    selectSection(evt) {
        let element = evt.target.nodeName.toUpperCase() === "SVG" ? evt.target : evt.target.parentElement;
        switch (element.dataset.option) {
            case "user":
                this.setState(
                    {
                        sectionEnabled: ["enabled", "disabled", "disabled"]
                    }
                );
                break;
            case "commerce":
                this.setState(
                    {
                        sectionEnabled: ["disabled", "enabled", "disabled"]
                    }
                )
                break;
            case "seller":
                this.setState(
                    {
                        sectionEnabled: ["disabled", "disabled", "enabled"]
                    }
                );
                break;
            default:
                break;
        }
    }

    validateForm(data) {
        return data === "" ? "S/D" : data;

    }
    validateNumber(number) {
        return number === "S/D" ? 0 : parseInt(number)
    }
    async getPostalCode() {
        this.setState({ colonia: [] });
        let postCode = this.postalCodeInput.current.value;
        if (this.postalCodeInput.current.value.length < 5) {
            this.activeModal("El codigo postal debe tener 5 caracteres. Por ejemplo 9999 colocar 09999", 3)
            this.setState({ colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "No existe el codigo postal" }] });
            return 0;
        }
        let response = await this.read(`${this.props.hosting}/webservice/direccion/${postCode}`);
        // console.log(`${this.props.hosting}/webservice/direccion/${postCode}`);
        const coloniaModeloLista = response.coloniaModeloLista ? response.coloniaModeloLista : [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "No existe el codigo postal" }];
        let { idMunicipio, estado, municipio } = response;
        this.setState({
            colonia: coloniaModeloLista,
            ciudad: estado,
            municipioCiudad: municipio,
            postCodeAdd: { idMunicipio, nombre: postCode }
        });
        if (this.state.enabledOtherPost) {
            this.setState({ otherPostCode: "enabled" });
        }
    }

    activeModal(message, option) {
        let optionButton = null;
        switch (option) {
            case 1:
                optionButton = this.buttonsModalOne;
                break;
            case 2:
                optionButton = this.buttonsModalTwo;
                break;
            case 3:
                optionButton = this.buttonsModalThree;
                break;
            default:
                break;
        }
        this.setState({
            messageModal: message,
            buttonModal: optionButton
        });
        document.getElementById("btn-modal").click();
    }
    otherPostCode(evt) {

        if (this.state.postCodeAdd) {
            this.setState({ otherPostCode: evt.target.checked ? "enabled" : "disabled" });
            this.setState({ enabledOtherPost: evt.target.checked })
        } else if (evt.target.checked) {
            this.setState({ enabledOtherPost: true })
            this.activeModal("Favor de seleccionar primero el codigo postal", 3);
        }

    }
    resetCommerce() {
        // console.log("entro reseteo");
        this.setState({
            clientAdd: false,
            inputCommerceId: -1,
            clientsCommerce: [],
            classDisabled: "form__disabled",
            buttonDisabled: [false, true, false],
            typeCompany: [],
            typeCompanyDefault: false
        })
        if (document.getElementById('found-client')) {
            document.getElementById('found-client').reset();
        }
    }

    validateTypeCompany(evt) {
        let element = evt.target, validateType = null;
        const { typeCompany } = this.state;
        if (typeCompany.length > 0) {
            let typeUnica = typeCompany.includes("UNICA") ? 1 : 0;
            let typeSucursal = typeCompany.includes("SUCURSAL") ? 2 : 0;
            let typeMatriz = typeCompany.includes("MATRIZ") ? 3 : 0;
            validateType = typeUnica + typeSucursal + typeMatriz;
        } else { validateType = 0 }

        if (element.checked === true) {
            switch (element.value) {
                case "UNICA":
                    if (validateType === 1) {
                        element.checked = false;
                        this.activeModal("No se puede asignar este campo ya que se tiene agregado un comercio con el tipo de empresa de \"única\"", 3);
                    }
                    if (validateType > 1) {
                        this.activeModal("No se puede asignar el tipo de empresa \"única\" debido a que ya se tienen agregados otros comercios", 3)
                        element.checked = false;
                    } else { console.log("puede agregar cualquiere"); }

                    break;
                case "SUCURSAL":
                    if (validateType === 1) {
                        element.checked = false;
                        this.activeModal("No se puede asignar este campo ya que se tiene agregado un comercio con el tipo de empresa \"única\"", 3);
                    } else { console.log("puede agregar cualquiere"); }
                    break;
                case "MATRIZ":
                    if (validateType === 1 || validateType >= 3) {
                        element.checked = false;
                        this.activeModal("No se puede asignar este campo ya que se tiene agregado un comercio con el tipo de empresa \"única\" o \"matriz\"", 3);
                    } else { console.log("puede agregar cualquiere"); }
                    break;

                default:
                    break;
            }
        }

    }

    async updateTypeCompany() {
        let type = await this.read(`${this.props.hosting}/webservice/comercio/tipoEmpresa/${this.state.inputCommerceId}`);
        this.assingTypeCompanyDefault(type);
        this.setState({
            typeCompany: type || [],
            classDisabled: ""
        });
    }

    async selectedClient(evt) {
        let element = evt.target;
        let type = await this.read(`${this.props.hosting}/webservice/comercio/tipoEmpresa/${element.value}`);
        this.assingTypeCompanyDefault(type);
        if (this.state.typeCompanyDefault === "invalida") {
            // this.showModal("Favor de asignar un cliente");
            this.activeModal("No se puede agregar este comercio por que el cliente ya cuenta con un comercio con tipo de empresa  \"única\". Se recomienda actulizar el comercio anterior con matriz o sucursal", 3)
            window.scroll(0, 0);
            return 0;
        }
        this.setState({
            inputCommerceId: element.value,
            typeCompany: type || [],
            classDisabled: ""
        });

    }
    assingTypeCompanyDefault(arrayType) {
        let typeCompanyDefault = arrayType ? (arrayType.includes("UNICA") ? "invalida" : "SUCURSAL") : "UNICA";
        this.setState({ typeCompanyDefault });
    }

    async changeAdressCommerce(idClient) {
        // console.log("entro cambio de recobo");
        let clientData = await this.read(`${this.props.hosting}/webservice/comercio/direccionFiscal/${idClient}`);
        if (clientData) {
            let commerceData = await this.read(`${this.props.hosting}/webservice/comercio/${clientData.id}`);
            let objUpdateAdress = {
                nombre: commerceData.nombre,
                tipoVialidadModelo: {
                    id: commerceData.tipoVialidadModelo.id
                },
                giroComercialModelo: {
                    id: commerceData.giroComercialModelo.id
                },
                coloniaModelo: { id: commerceData.coloniaModelo.id },
                nombreVialidad: commerceData.nombreVialidad,
                numeroExterior: commerceData.numeroExterior,
                numeroInterior: commerceData.numeroInterior,
                promotorModelo: { id: commerceData.promotorModelo.id },
                inicioOperaciones: commerceData.inicioOperaciones,
                tipoEmpresa: commerceData.tipoEmpresa,
                actividadPrincipal: commerceData.actividadPrincipal,
                trabajadorHombre: commerceData.trabajadorHombre,
                trabajadorMujer: commerceData.trabajadorMujer,
                facebook: commerceData.facebook,
                twitter: commerceData.twitter,
                direccionFiscal: false,
                estado: commerceData.estado
            }
            let resposeCommerce = await this.update(`${this.props.hosting}/webservice/comercio/${clientData.id}`, objUpdateAdress);
            if (!resposeCommerce) {
                return false;
            }

        }

        return true;

    }
}

export default withLogin(Registro);
