import React, { Component } from 'react';
import Header from "../Organisms/header";
import Filtros from "../Templates/filtros";
import BoxInput from '../Molecules/box-input';
import Button from '../Atoms/button';
import { FaUser, FaFileAlt, FaPlusCircle, FaEllipsisV, FaStore, FaDollarSign, FaSearch, FaFileInvoiceDollar, FaFileDownload, FaFileInvoice, FaFileContract } from 'react-icons/fa';
import Table from "../Templates/tabla";
import { Link } from 'react-router-dom';
import withLogin from '../HOC/withLogin';
import Dropdawn from "../Molecules/drop-down";
import Tooltip from '../Molecules/tooltip';
import Title from '../Atoms/title';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchElement: <></>,
            className: "form__container",
            dataTable: [],
            search: false,
            payButtonClass: "disabled",
            idPay: "",
            resetTable: false,
            tableHeader: ["promotor", "razón social", "número de cliente", "concepto", "fecha de registro", "monto", "recibo / engomado", "opciones"],
            //reporte csv
            reportPayDate: false,
            dataCSV: false,
            dataCSVCommerce: false,
            dataCSVCommerceDate: false,
            dataCSVDeptor: false,
            //id de comercios para el reporte
            idCommerces: [],
            messageErrorReportSearch: false,
            //filterTable
            dataTableFilter: [],
            globalData: []
        }
        this.formRef = React.createRef();
        this.linkCSV = React.createRef();
        this.inputFilter = React.createRef();
    }
    render() {

        const { tableHeader, reportPayDate, dataCSV, idCommerces, dataCSVCommerceDate, dataCSVDeptor, messageErrorReportSearch } = this.state;
        return (
            <>
                <Header />
                <main style={{ marginLeft: "200px", marginBottom: "30px", paddingRight: "30px" }}>
                    <form id="filter-form" className={this.state.className} style={{ padding: "0.1px" }} ref={this.formRef} onSubmit={(evt) => { this.optionSearchConsult(evt) }}>
                        {this.state.searchElement}
                        <div style={{ textAlign: "center" }}>
                            <Button styleClass="button__primary margin-medium-top-2" text="consultar" />
                        </div>
                    </form>
                    {
                        dataCSV ?
                            <>
                                <h3 style={{ textAlign: "center" }} className="margin-large-top-3">Descargar Reporte</h3>
                                <div style={{ textAlign: "center" }} className="margin-medium-top-3">
                                    <FaFileDownload className="icon__download" onClick={() => { this.downloadDocument("Reporte-ingreso-diario", 1) }} />
                                </div>
                            </> :
                            <></>
                    }

                    {
                        dataCSVCommerceDate ?
                            <>
                                <h3 style={{ textAlign: "center" }} className="margin-large-top-3">Descargar Reporte</h3>
                                <div style={{ textAlign: "center" }} className="margin-medium-top-3">
                                    <FaFileDownload className="icon__download" onClick={() => { this.downloadDocument("Reporte-comercio", 3) }} />
                                </div>
                            </> :
                            <></>
                    }
                    {
                        dataCSVDeptor ?
                            <>
                                <h3 style={{ textAlign: "center" }} className="margin-large-top-3">Descargar Reporte</h3>
                                <div style={{ textAlign: "center" }} className="margin-medium-top-3">
                                    <FaFileDownload className="icon__download" onClick={() => { this.downloadDocument("Reporte-deuda", 4) }} />
                                </div>
                            </> :
                            <></>
                    }
                    {
                        messageErrorReportSearch ? messageErrorReportSearch : <></>
                    }

                    <div >
                        {
                            this.state.search ? <p className="margin-large-top-3" style={{ textAlign: "center" }}><span style={{ display: "inline-flex", alignItems: "center" }}><span className="loader margin-large-left-3"></span><span style={{ fontSize: "20px", display: "inline-block", fontWeight: "900" }} className="margin-medium-left-3">Buscando....</span></span></p> : <></>
                        }
                    </div>

                    <div style={{ marginTop: "100px" }} className={reportPayDate ? "disabled" : "enabled"}>
                        <div style={{ justifyContent: "space-between" }} className={`${this.state.resetTable ? "disabled" : "flex"}`} >
                            <div>
                                <span style={{ fontWeight: "700" }} ><FaStore className="icon__small icon-active" /> Comercios activados</span>
                                <span style={{ fontWeight: "700" }} className="margin-medium-left-3"><FaStore className="icon__small icon-inactive" /> Comercios desactivados</span>
                            </div>
                            <div>
                                {/* <Link to={`/pagos/client/${this.state.idPay}`} className={`${this.state.payButtonClass} button button__primary link"`}><FaDollarSign /> Pagar todos</Link> */}
                                <Button text="Reporte comercios" styleClass={`button__primary button--size-medium margin-medium-left-3 ${idCommerces.length > 0 ? "" : "disabled"}`} method={() => { this.downloadReportCommerce(1) }} />
                            </div>

                        </div>
                        <div className="margin-small-top-3">
                            <div className={this.state.dataTableFilter ? "enabled" : "disabled"}>
                                <div className={`${this.state.resetTable ? "disabled" : "flex"}`} >
                                    <div style={{ width: "500px" }} >
                                        <BoxInput typeBox="input" styleClass="form__label" icon="Filtrado">
                                            <input type="text" name="filter" className="form__input form__normal" ref={this.inputFilter} />
                                        </BoxInput>
                                    </div>
                                    <Button text="Buscar" typeButton="button" styleClass="button__primary button--size-medium margin-medium-left-3" method={() => {
                                        this.writeTableFilter(true);
                                    }} />
                                    <Button text="Limpiar" typeButton="button" styleClass="button__primary button--size-medium margin-medium-left-3" method={() => {
                                        this.writeTableFilter(false);
                                    }} />

                                </div>
                            </div>
                            <div className="margin-medium-top-2">
                                {this.state.resetTable ? <></> :
                                    <Table tableData={this.state.dataTable} tableHeader={tableHeader} elementTable={this.state.dataTable.length} />
                                }
                            </div>
                        </div>
                    </div>
                    <span className="icon__add--position">
                        <Tooltip text="Agregar" position="tooltip--bottom" styleComponent="margin-medium-right-3">
                            <Link to="/registro">
                                <FaPlusCircle className="icon__add" />
                            </Link>
                        </Tooltip>
                        <Tooltip text="Busqueda general" position="tooltip--bottom" styleComponent="icon__hover margin-medium-right-3">
                            <FaSearch className="icon__add" onClick={() => { this.searchAll() }} />
                        </Tooltip>
                        <Tooltip text="Reporte de ingresos diarios" position="tooltip--bottom" styleComponent="icon__hover margin-medium-right-3">
                            <FaFileInvoiceDollar className="icon__add" onClick={() => { this.reportPay("de ingresos diarios") }} />
                        </Tooltip>
                        <Tooltip text="Reporte comercio fecha " position="tooltip--bottom" styleComponent="icon__hover margin-medium-right-3">
                            <FaFileInvoice className="icon__add" onClick={() => { this.reportPay("comercio") }} />
                        </Tooltip>
                        <Tooltip text="Reporte de deudores" position="tooltip--bottom" styleComponent="icon__hover">
                            <FaFileContract className="icon__add" onClick={() => { this.reportPay("deudores") }} />
                        </Tooltip>
                    </span>
                    <a ref={this.linkCSV} className="disabled">descargar</a>
                </main>
                <Filtros hosting={process.env.REACT_APP_DOMAIN} handler={(element, styleName, payButtonClass) => this.chooseSearch(element, styleName, payButtonClass)} styleClass="aside-container__nav" />
            </>
        );
    }

    defaultElement() {
        return (
            <div style={{ width: "250px", margin: "10px auto 0 auto" }}>
                <BoxInput icon={<FaUser className="icon-form" />} typeBox="sample" statusInput="form__normal" nameInput="promotor" />
            </div>);
    }

    chooseSearch(element, styleName, payButtonClass) {
        this.setState({
            searchElement: element,
            className: styleName,
            payButtonClass,
            reportPayDate: false,
            dataCSV: false,
            dataCSVCommerceDate: false,
            dataCSVDeptor:false,
            messageErrorReportSearch: false,
            dataTable: false,
            dataTableFilter: false,
            idCommerces: [],
            globalData: [],
        });
        this.formRef.current.reset();
    }
    async componentDidMount() {

        this.writeTableData("palabra", "deudor");
        this.searchAll();


    }
    componentDidUpdate() {
        let elementFilter = document.getElementById('filter-form');
        elementFilter.classList.add("animacion-search");
        setTimeout(() => { elementFilter.classList.remove("animacion-search") }, 400)
    }


    async optionSearchConsult(evt) {
        evt.preventDefault();
        let obj = {};
        let arrayData = [];
        const searchForm = new FormData(evt.target);
        if (searchForm.has("inicio")) {
            let currentDate = new Date();
            for (const pair of searchForm) {
                obj[pair[0]] = pair[1];
            }
            let validateForm = obj.inicio === "" || obj.fin === "" ? false : true;
            if (validateForm) {
                this.setState({
                    dataCSV: false,
                    dataCSVCommerceDate: false,
                    dataCSVDeptor:false,
                    messageErrorReportSearch: false
                });
                let getObjDateEnd = obj.fin.split("-");
                let getObjDateStart = obj.inicio.split("-");
                let dataEnd = null, dataStart = null;
                let dayEnd = parseInt(parseInt(getObjDateEnd[2])), mountEnd = parseInt(getObjDateEnd[1]), yearEnd = parseInt(getObjDateEnd[0]);
                let dayStart = parseInt(parseInt(getObjDateStart[2])), mountStart = parseInt(getObjDateStart[1]), yearStart = parseInt(getObjDateStart[0]);
                let currentDay = currentDate.getDate(), currentMount = currentDate.getMonth() + 1, currentYear = currentDate.getFullYear();
                if (yearEnd > currentYear) {
                    dataEnd = false;
                } else if (yearEnd === currentYear) {
                    if (mountEnd > currentMount) {
                        dataEnd = false
                    } else if (mountEnd === currentMount) {
                        if (dayEnd > currentDay) {
                            dataEnd = false
                        } else { dataEnd = true; }
                    } else { dataEnd = true; }
                } else { dataEnd = true; }

                if (yearStart > yearEnd) {
                    dataStart = false;
                } else if (yearStart === yearEnd) {
                    if (mountStart > mountEnd) {
                        dataStart = false
                    } else if (mountStart === mountEnd) {
                        // console.log("entro dato");
                        if (dayStart > dayEnd) {
                            dataStart = false
                        } else { dataStart = true; }
                    } else { dataStart = true; }
                } else { dataStart = true; }
                let validateDataFin = obj?.typeReport === "deudores" ? true : dataEnd;
                let validateDataStart = dataStart;
                let messageError = !(validateDataFin) ? "No se puede asignar fechas futuras" : !(validateDataStart) ? "La fecha de inicio no puede ser mayor que la fecha final" : false;
                if (validateDataFin && validateDataStart) {
                    this.setState(
                        {
                            typeReportGeneral: obj.typeReport,
                            messageErrorReportSearch: false
                        });

                    if (obj.typeReport === "de ingresos diarios") {
                        this.downloadReportPay(obj);
                    } else if (obj.typeReport === "deudores") {
                        this.downloadReportCommerce(3, obj);
                    } else {
                        this.downloadReportCommerce(2, obj);
                    }
                } else { this.setState({ messageErrorReportSearch: <p className="margin-medium-top-3" style={{ textAlign: "center", fontWeight: "700" }} >{messageError}</p> }) }
            } else { this.setState({ messageErrorReportSearch: <p className="margin-medium-top-3" style={{ textAlign: "center", fontWeight: "700" }} >Favor de llenar todos los campos</p> }); };




        } else {
            for (const pair of searchForm.entries()) {
                arrayData = pair;
            }
            if (arrayData[0] === "palabra" && arrayData[1] === "" ) {
                  arrayData[1] = "deudor"
              }

            this.writeTableData(arrayData[0], encodeURIComponent(arrayData[1]));
        }



    }
    searchAll() {
        this.setState({
            searchElement: <BoxInput icon={<FaSearch className="icon-form" />} styleClass="form__label" typeBox="description" statusInput="form__normal" nameInput="palabra" description="No se pueden realizar busquedas recibo de pago" />,
            className: "form__large-element",
            payButtonClass: "disabled",
            reportPayDate: false,
            dataCSV: false,
            dataCSVCommerceDate: false,
            dataCSVDeptor:false,
            messageErrorReportSearch: false,
            dataTable: false,
            idCommerces: [],
            dataTableFilter: false
        });
        this.formRef.current.reset();
    }

    reportPay(option) {

        const formReportPay =
            <div>
                <div className="margin-medium-top-3" style={{ textAlign: "left" }}>
                    <Title type="title" content={`Reporte ${option}`} />
                </div>
                <div className="grid-layout grip-gap grid-2 margin-large-top-3">
                    <BoxInput typeBox="sample" typeInput="date" styleClass="form__label" statusInput="form__normal" icon="Fecha Inicio" nameInput="inicio" />
                    <BoxInput typeBox="sample" typeInput="date" styleClass="form__label" statusInput="form__normal" icon="Fecha Fin" nameInput="fin" />
                    <input type="hidden" name="typeReport" value={option} />
                </div>
            </div>;
        this.setState({
            searchElement: formReportPay,
            className: "form__map-container",
            payButtonClass: "disabled",
            reportPayDate: true,
            messageErrorReportSearch: false,
            dataCSV: false,
            dataCSVCommerceDate: false,
            dataCSVDeptor:false
        });
        this.formRef.current.reset();
    }
    async downloadReportPay(obj) {
        let response = await this.read(`${this.props.hosting}/webservice/reporte/pago?inicio=${obj.inicio}&fin=${obj.fin}`);
        // console.log(response);
        if (response) {
            let headerReport = "ESTATUS,SIEM,RECIBO,EXPEDIDO A NOMBRE DE,CONCEPTO,IMPORTE,REPRESENTANTE,FACT No.,FECHA DE PAGO\n";
            let arrayData = response.map(data => {
                const { anioConcepto, clienteAM, clienteAP, concepto, engomado, factura, fecha, idPago, importe, nombreMoral, promotorAM, promotorAP, promotorNombre, tipoPago, clienteNombre } = data;
                let nombrePromotor = `${promotorAP} ${promotorAM} ${promotorNombre}`;
                let nombreCliente = clienteNombre ? `${clienteAP} ${clienteAM} ${clienteNombre}` : nombreMoral;
                let dataRow = [tipoPago, engomado, idPago, nombreCliente, `${concepto}/${anioConcepto}`, importe, nombrePromotor, factura, fecha];
                return this.validateDataReport(dataRow);
            });
            let csv = this.writeCSV(arrayData, headerReport);
            this.setState({ dataCSV: csv })
        } else { this.setState({ messageErrorReportSearch: <p className="margin-medium-top-3" style={{ textAlign: "center", fontWeight: "700" }} >No hay resultados en la consulta</p> }) }


    }

    async downloadReportCommerce(option, obj) {
        const objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(this.state.idCommerces)
        }
        //console.log(this.state.idCommerces);
        let data = null;
        switch (option) {
            case 1:
                data = await fetch(`${this.props.hosting}/webservice/reporte/comercio?estatus=true`, objData);
                break;
            case 2:
                data = await fetch(`${this.props.hosting}/webservice/reporte/comercio/${obj.inicio}/${obj.fin}`);
                break;
            case 3:
                data = await fetch(`${this.props.hosting}/webservice/reporte/deudores/${obj.inicio}/${obj.fin}`);
                break;
            default:
                break;
        }
      
        if (data.ok && data.status === 200) {
            let response = await data.text();

          
            let dataCsv = response;
            switch (option) {
                case 1:
                    this.setState({ dataCSVCommerce: dataCsv });
                    this.downloadDocument("Reporte-comercio", 2);
                    break;
                case 2:
                    this.setState({ dataCSVCommerceDate: dataCsv });
                    break;
                case 3:
                    this.setState({dataCSVDeptor: dataCsv});
                    break;

                default:
                    
                    break;
            }
            

        } else { this.setState({ messageErrorReportSearch: <p className="margin-medium-top-3" style={{ textAlign: "center", fontWeight: "700" }} >No hay resultados en la consulta</p> }) }

    }
    validateDataReport(dataArray) {
        let reg = /,/i;
        let validateData = null;
        validateData = dataArray.map(data => {
            let word = null;
            if (data) {
                switch (typeof data) {
                    case "string":
                        word = data.replace(reg, "")
                        break;
                    case "number":
                        word = data;
                        break;
                    case "boolean":
                        word = data;
                        break;
                    default:
                        break;
                }
            } else {
                word = "S/D"
            }
            return word
        });
        return validateData;
    }
    writeCSV(data, csv) {
        // console.log(data, csv);
        data.forEach(row => {
            csv += row.join(",");
            csv += "\n";
        })
        return csv;

    }
    downloadDocument(nameFile, option) {
        let data = null;
        switch (option) {
            case 1:
                data = this.state.dataCSV;
                break;
            case 2:
                data = this.state.dataCSVCommerce;
                break;
            case 3:
                data = this.state.dataCSVCommerceDate;
                break;
            case 4:
                data = this.state.dataCSVDeptor;
                break;
            default:
                break;
        }
        let enlace = this.linkCSV.current;
        /*enlace.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
        enlace.target = "_blank";
        enlace.download = `${nameFile}.csv`;
        enlace.click();*/
        const blob = new Blob([data], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        enlace.href = url;
        enlace.download = `${nameFile}.csv`;
        enlace.click();
        window.URL.revokeObjectURL(url);
    }

    async read(url) {
        let response = null, data = false;
        try {
            let messageError = "";
            response = await fetch(url);
            //console.log(response);
            if (response.status >= 500) {
                messageError = "Error del servidor";
                data = false;
                throw new Error(messageError);
            } else if (response.ok && response.status === 200) {
                data = await response.json();
            } else { data = false }
        } catch (error) {
            // console.log(error);
            data = false;
        } finally {
            return data;
        }

    }

    async writeTableData(paramSearch, textSearch) {
        this.setState({
            search: true,
            resetTable: true
        });
        console.log(paramSearch,textSearch);
        let columnsTable = false;
        let idCommerces = [];
        let commercesIdPay = new Set();
        let dataCommerce = null;
        if (textSearch) {
            let url = paramSearch === "palabra" ? `${process.env.REACT_APP_DOMAIN}/webservice/busqueda` : `${process.env.REACT_APP_DOMAIN}/webservice/busqueda/individual`;
            let urlIdPay = paramSearch === "engomado" ? `${process.env.REACT_APP_DOMAIN}/webservice/pago/${textSearch}` : false;
            if (urlIdPay) {
                let dataClient = await this.read(`${process.env.REACT_APP_DOMAIN}/webservice/impresion/pago/${textSearch}`); 
                const { nombre, comercioId: idClient, pagoId } = dataClient && dataClient[0];
                let dataIdPay = dataClient ? await this.read(urlIdPay) : false;
                dataCommerce = dataIdPay ? {
                    ...dataIdPay,
                    nombre,
                    idClient,
                    pagoId
                } : false;
    
                columnsTable = dataCommerce ? dataCommerce.comercioPagoModeloLista.map((column) => {
                    const { nombre: nombreComercio, promotorModelo, id: comercioId, estado } = column.comercioModelo;
                    const { engomado, anioConcepto, importe, conceptoModelo } = column;
                    let promotor = promotorModelo.nombre ? `${promotorModelo.apellidoPaterno} ${promotorModelo.nombre}` : "S/D";
                    let razonSocial = dataCommerce.nombre;
                    let conceptoComercio = conceptoModelo?.concepto || "S/D";
                    let fechaPagoComercio = anioConcepto || "S/D";
                    let idPagoComercio = dataCommerce.pagoId;
                    let montoComercio = importe;
                    razonSocial = nombreComercio ? <div className="table__column-commerce"> <Link style={{ color: "black" }} className="link link-razon-social" to={`/detalles/${comercioId}/${dataCommerce.idClient}`}><span className="margin-small-right-2 text-center" >{razonSocial}</span></Link><Dropdawn icon={<FaStore className={` ${estado ? "icon-active" : "icon-inactive"}`} />} styleClass={`dropdawn-inline`} ><li>{nombreComercio}</li></Dropdawn></div> : razonSocial;
                    if (comercioId) {
                        commercesIdPay.add(comercioId);
                    }
    
                    return {
                        promotor,
                        razonSocial,
                        numeroCliente: dataCommerce.idClient,
                        concepto: conceptoComercio || "S/D",
                        fechaRegistro: fechaPagoComercio || "S/D",
                        monto: montoComercio || "S/D",
                        recibo: <Dropdawn icon={<FaFileAlt />}>
                            <li>Recibo {conceptoComercio}: {idPagoComercio}</li>
                            <li>Engomado: {`${engomado || "S/D"}`}</li>
                        </Dropdawn>,
                        opciones:
                            <Dropdawn icon={<FaEllipsisV />}>
                                <li><Link style={{ color: "black" }} className="link" to={`/pagos/commerce/${comercioId}:${dataCommerce.idClient}`}><FaDollarSign className="margin-small-right-3" />Pagar</Link></li>
                            </Dropdawn>
                    }
                }) : false;
    
                idCommerces = Array.from(commercesIdPay);
    
            } else {
                let endPoint = textSearch === "deudor" ? url : `${url}?${paramSearch}=${textSearch}`;
                dataCommerce = await this.read(endPoint);
                columnsTable = dataCommerce ? dataCommerce.map((column) => {
                    const { concepto, idPago, fechaPago, monto } = column;
                    let promotor = column.promotorNombre ? `${column.promotorApellidoPaterno} ${column.promotorNombre}` : "S/D";
                    let razonSocial = column.fisicoNombre ? `${column.fisicoApellidoPaterno} ${column.fisicoApellidoMaterno} ${column.fisicoNombre}` : column.moral;
                    let conceptoComercio = structColum(concepto);
                    let fechaPagoComercio = structColum(fechaPago);
                    let idPagoComercio = structColumPay(idPago);
                    let montoComercio = structColum(monto);
                    razonSocial = column.comercioNombre ? <div className="table__column-commerce"> <Link style={{ color: "black" }} className="link link-razon-social" to={`/detalles/${column.comercioId}/${column.id}`}><span className="margin-small-right-2 text-center" >{razonSocial}</span></Link><Dropdawn icon={<FaStore className={` ${column.comercioEstado ? "icon-active" : "icon-inactive"}`} />} styleClass={`dropdawn-inline`} ><li>{column.comercioNombre}</li></Dropdawn></div> : razonSocial;
                    if (column.comercioId) {
                        idCommerces.push(column.comercioId);
                    }
    
                    return {
                        promotor,
                        razonSocial,
                        numeroCliente: column.id,
                        concepto: conceptoComercio || "S/D",
                        fechaRegistro: fechaPagoComercio || "S/D",
                        monto: montoComercio || "S/D",
                        recibo: <Dropdawn icon={<FaFileAlt />}>
                            {idPagoComercio}
                            <li>Engomado: {`${column.engomado || "S/D"}`}</li>
                        </Dropdawn>,
                        opciones:
                            <Dropdawn icon={<FaEllipsisV />}>
                                <li><Link style={{ color: "black" }} className="link" to={`/pagos/commerce/${column.comercioId}:${column.id}`}><FaDollarSign className="margin-small-right-3" />Pagar</Link></li>
                            </Dropdawn>
                    }
                }) : false;
            }
            
        }
       
      
        // const data = await this.read(`http://localhost:8080/canacope-backend-0.1/webservice/busqueda?palabra=${textSearch}`);
        let linkPay = "", payButtonClass = "disabled";
        if (paramSearch === "rfc" && columnsTable) {
            linkPay = columnsTable[0].numeroCliente;
            payButtonClass = textSearch === "XXXX010101XXX" ? "disabled" : "";
        }

        this.setState(
            {
                dataTable: columnsTable,
                search: false,
                idPay: linkPay,
                payButtonClass,
                resetTable: false,
                idCommerces: columnsTable ? idCommerces : [],
                globalData: dataCommerce,
                dataTableFilter: dataCommerce ? true : false
            }
        );


        function structColum(array) {
            let option = array[0] && array[1] ? 2 : (array[0] || array[1] ? 1 : 0);
            switch (option) {
                case 2:
                    return <><p>{array[0]}</p><p style={{ borderTop: "solid 2px black" }}>{array[1]}</p></>;
                case 1:
                    return array[0] ? <p>{array[0]}</p> : <p>{array[1]}</p>;
                case 0:
                    return false
                default:
                    break;
            }
        }

        function structColumPay(array) {
            let option = array[0] && array[1] ? 2 : (array[0] || array[1] ? 1 : 0);
            switch (option) {
                case 2:
                    return <><li>Recibo Siem: {array[0]}</li><li>Recibo Afiliación: {array[1]}</li></>;
                case 1:
                    return array[0] ? <li>Recibo Siem: {array[0]}</li> : <li>Recibo Afiliación: {array[1]}</li>;
                case 0:
                    return <li>Recibo: {"S/D"}</li>
                default:
                    break;
            }
        }
    }

    writeTableFilter(option) {
        // this.setState({
        //     search: true,
        //     resetTable: true
        // });
        let { globalData } = this.state;
        let linkPay = "", payButtonClass = "disabled";
        let textSearch = option ? this.inputFilter.current.value.toUpperCase() : "";
        // console.log(textSearch);
        let idCommerces = [], commercesIdPay = new Set();
        let columnsTable = null;
        if (!option) {
            this.inputFilter.current.value = ""
        }
        //console.log(this.state.dataTableFilter);
        if (Array.isArray(globalData)) {
            let data = globalData.filter((column) => {
                let razonSocial = column.fisicoNombre ? `${column.fisicoApellidoPaterno} ${column.fisicoApellidoMaterno} ${column.fisicoNombre}` : column.moral;
                //console.log(razonSocial,index);
                return razonSocial.toUpperCase().includes(textSearch) || column.comercioNombre?.toUpperCase().includes(textSearch) || textSearch.includes(column?.engomado) || parseInt(textSearch) === column.id;
            });

            columnsTable = data.length > 0 ? data.map((column) => {
                const { concepto, idPago, fechaPago, monto } = column;
                let promotor = column.promotorNombre ? `${column.promotorApellidoPaterno} ${column.promotorNombre}` : "S/D";
                let razonSocial = column.fisicoNombre ? `${column.fisicoApellidoPaterno} ${column.fisicoApellidoMaterno} ${column.fisicoNombre}` : column.moral;
                let conceptoComercio = structColum(concepto);
                let fechaPagoComercio = structColum(fechaPago);
                let idPagoComercio = structColumPay(idPago);
                let montoComercio = structColum(monto);
                razonSocial = column.comercioNombre ? <div className="table__column-commerce"> <Link style={{ color: "black" }} className="link link-razon-social" to={`/detalles/${column.comercioId}/${column.id}`}><span className="margin-small-right-2 text-center" >{razonSocial}</span></Link><Dropdawn icon={<FaStore className={` ${column.comercioEstado ? "icon-active" : "icon-inactive"}`} />} styleClass={`dropdawn-inline`} ><li>{column.comercioNombre}</li></Dropdawn></div> : razonSocial;
                if (column.comercioId) {
                    idCommerces.push(column.comercioId);
                }

                return {
                    promotor,
                    razonSocial,
                    numeroCliente: column.id,
                    concepto: conceptoComercio || "S/D",
                    fechaRegistro: fechaPagoComercio || "S/D",
                    monto: montoComercio || "S/D",
                    recibo: <Dropdawn icon={<FaFileAlt />}>
                        {idPagoComercio}
                        <li>Engomado: {`${column.engomado || "S/D"}`}</li>
                    </Dropdawn>,
                    opciones:
                        <Dropdawn icon={<FaEllipsisV />}>
                            <li><Link style={{ color: "black" }} className="link" to={`/pagos/commerce/${column.comercioId}:${column.id}`}><FaDollarSign className="margin-small-right-3" />Pagar</Link></li>
                        </Dropdawn>
                }
            }) : false;
        } else {
            let data = globalData.comercioPagoModeloLista.filter((column) => {

                return column.comercioModelo?.nombre?.toUpperCase().includes(textSearch) || textSearch.includes(column?.engomado) || column.conceptoModelo.concepto.includes(textSearch)
            });
            columnsTable = data ? data.map((column) => {
                const { nombre: nombreComercio, promotorModelo, id: comercioId, estado } = column.comercioModelo;
                const { engomado, anioConcepto, importe, conceptoModelo } = column;
                let promotor = promotorModelo.nombre ? `${promotorModelo.apellidoPaterno} ${promotorModelo.nombre}` : "S/D";
                let razonSocial = globalData.nombre;
                let conceptoComercio = conceptoModelo?.concepto || "S/D";
                let fechaPagoComercio = anioConcepto || "S/D";
                let idPagoComercio = globalData.pagoId;
                let montoComercio = importe;
                razonSocial = nombreComercio ? <div className="table__column-commerce"> <Link style={{ color: "black" }} className="link link-razon-social" to={`/detalles/${comercioId}/${globalData.idClient}`}><span className="margin-small-right-2 text-center" >{razonSocial}</span></Link><Dropdawn icon={<FaStore className={` ${estado ? "icon-active" : "icon-inactive"}`} />} styleClass={`dropdawn-inline`} ><li>{nombreComercio}</li></Dropdawn></div> : razonSocial;
                if (comercioId) {
                    commercesIdPay.add(comercioId);
                }

                return {
                    promotor,
                    razonSocial,
                    numeroCliente: globalData.idClient,
                    concepto: conceptoComercio || "S/D",
                    fechaRegistro: fechaPagoComercio || "S/D",
                    monto: montoComercio || "S/D",
                    recibo: <Dropdawn icon={<FaFileAlt />}>
                        <li>Recibo {conceptoComercio}: {idPagoComercio}</li>
                        <li>Engomado: {`${engomado || "S/D"}`}</li>
                    </Dropdawn>,
                    opciones:
                        <Dropdawn icon={<FaEllipsisV />}>
                            <li><Link style={{ color: "black" }} className="link" to={`/pagos/commerce/${comercioId}:${globalData.idClient}`}><FaDollarSign className="margin-small-right-3" />Pagar</Link></li>
                        </Dropdawn>
                }
            }) : false;

            idCommerces = Array.from(commercesIdPay);

        }

        this.setState(
            {
                dataTable: columnsTable,
                // search: false,
                idCommerces,
                idPay: linkPay,
                payButtonClass,
                // resetTable: false,
            }
        );
        function structColum(array) {
            let option = array[0] && array[1] ? 2 : (array[0] || array[1] ? 1 : 0);
            switch (option) {
                case 2:
                    return <><p>{array[0]}</p><p style={{ borderTop: "solid 2px black" }}>{array[1]}</p></>;
                case 1:
                    return array[0] ? <p>{array[0]}</p> : <p>{array[1]}</p>;
                case 0:
                    return false
                default:
                    break;
            }
        }

        function structColumPay(array) {
            let option = array[0] && array[1] ? 2 : (array[0] || array[1] ? 1 : 0);
            switch (option) {
                case 2:
                    return <><li>Recibo Siem: {array[0]}</li><li>Recibo Afiliación: {array[0]}</li></>;
                case 1:
                    return array[0] ? <li>Recibo Siem: {array[0]}</li> : <li>Recibo Afiliación: {array[1]}</li>;
                case 0:
                    return <li>Recibo: {"S/D"}</li>
                default:
                    break;
            }
        }
    }

}

export default withLogin(Dashboard);