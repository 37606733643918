import React, { Component } from "react";
import Title from "../Atoms/title";
import BoxInput from "../Molecules/box-input";
import Button from "../Atoms/button";
import Paginacion from "../Templates/paginacion";
import Direction from "../Organisms/direccion";
import { Link } from "react-router-dom";

class FormCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientsCommerce: [],
            inputCommerceId: "",
            typePerson: false,
            typePersonTwo: false,
            client: false,
            adressClient: false,
            classAddAdress: false,
            colonia: [{ id: "5d931fb3-4042-49b8-9a57-04b6d2aa782a", nombre: "Sin Colonia" }],

        }

    }
    render() {
        const { clientsCommerce, typePerson, typePersonTwo, colonia, adressClient, classAddAdress } = this.state;
        const { clienteDireccionFiscalModelo } = this.state.client;
        return (
            <section>
                <Title type="title" content="Actualizar Cliente" />
                {
                    this.props.params.idClient && !(this.props.reset) ?
                        <>
                            <form onSubmit={(evt) => { this.updateClient(evt) }}>
                                {
                                    typePerson ?

                                        typePerson === "Fisica" ?
                                            <>
                                                <div style={{ width: "500px" }} className="margin-large-top-3">
                                                    <BoxInput typeBox="description" typeInput="text" icon="RFC" nameInput="rfc" styleClass="form__label" statusInput="form__normal" description="Escribir XXXX010101XXX si no cuenta el RFC" minLength={10} maxLength={13} />
                                                </div>
                                                <div id="form-persona-fisica" className="margin-medium-top-2 grid-layout grip-gap" style={{ gridTemplateColumns: "max-content 1fr", alignItems: "center" }} >
                                                    <h4>Persona Física</h4>
                                                    <div className="grid-layout grid-3 margin-medium-top-3">
                                                        <BoxInput typeBox="description" typeInput="text" nameInput="apellidoPaterno" styleClass="form__label" description="Apellido Paterno" statusInput={"form__normal"} minLength={3} maxLength={40} />
                                                        <BoxInput typeBox="description" typeInput="text" nameInput="apellidoMaterno" styleClass="form__label" description="Apellido Materno" statusInput={"form__normal"} minLength={3} maxLength={40} />
                                                        <BoxInput typeBox="description" typeInput="text" nameInput="nombre" styleClass="form__label" description="Nombre" statusInput={"form__normal"} minLength={3} maxLength={50} />
                                                    </div>
                                                    <input type="hidden" name="id" value={this.props.params.idClient} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ width: "400px" }} className="margin-large-top-3">
                                                    <BoxInput typeBox="description" typeInput="text" icon="RFC" nameInput="rfc" styleClass="form__label" statusInput="form__normal" description="Escribir XXXX010101XXX si no cuenta el RFC" minLength={10} maxLength={13} />
                                                </div>
                                                <div id="form-persona-moral" className="margin-large-top-3">
                                                    <h4>Persona Moral</h4>
                                                    <div className="margin-medium-top-3" >
                                                        <BoxInput typeBox="description" styleClass="form__label" typeInput="text" nameInput="moral" description="Escriba el nombre de la empresa tal y como se dio de alta en la SHCP, sin abreviar, sin guiones y sin comillas" statusInput={"form__normal"} minLength={3} maxLength={100} />
                                                    </div>
                                                    <input type="hidden" name="id" value={this.props.params.idClient} />
                                                </div>
                                            </>
                                        : <></>
                                }
                                <div className="margin-medium-top-3" style={{ width: "600px" }}>
                                    <BoxInput typeBox="sample" typeInput="text" defaultValue="S/N" styleClass="form__label" icon="Teléfono" statusInput="form__normal" nameInput="telefono" maxLength={33} />
                                    <div className="margin-medium-top-3" >
                                        <label style={{ fontWeight: "700" }} htmlFor="">Datos importantes</label>
                                        <textarea className="form__text-area form__normal margin-medium-top-2" name="datosImportantes" id="" rows="6"></textarea>
                                    </div>
                                </div>

                                {
                                    !adressClient ?
                                        <div className="margin-medium-top-3">
                                            <h3>Dirección Fiscal</h3>
                                            {!clienteDireccionFiscalModelo && <h4 className="margin-medium-top-3">El cliente no tiene una dirección fiscal asignada</h4>}
                                            <Direction modal={(message, option) => { this.props.modal(message, option) }} optionModal={1} colonia={colonia} estado={clienteDireccionFiscalModelo?.estado} municipio={clienteDireccionFiscalModelo?.municipio}/>
                                        </div>
                                        : <></>}

                                <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Actualizar cliente" />


                            </form>


                            {
                                adressClient ?
                                    <form className="margin-medium-top-3" onSubmit={(evt) => { this.createAdress(evt) }}>
                                        <Title type="subtitle" content="Dirección fiscal" />
                                        <h3 className="margin-medium-top-2">La dirección fiscal actualmente esta vinculada a un comercio</h3>
                                        <p className="margin-medium-top-2" style={{ textDecoration: "underline" }}><span style={{ fontWeight: "700" }} >Nombre comercial:</span> {adressClient.nombre} <span style={{ fontWeight: "700" }}>Dirección:</span> {adressClient.nombreVialidad} {adressClient.numeroExterior} {adressClient.numeroInterior} {adressClient.coloniaModelo.nombre} {adressClient.municipio}</p>
                                        <h4 className="margin-medium-top-2" >En caso de que el comercio  y la dirección fiscal sigan relacionados le recomendamos actualizar la dirección del comercio.</h4>
                                        <h4 className="margin-small-top-2"> Si el comercio y la dirección fiscal ya no guardan relación agrege una nueva dirección fiscal</h4>
                                        <div className="flex margin-medium-top-3">
                                            <Link to={`/detalles/${adressClient.id}/${this.props.params.idClient}`} className="button button__primary button--size-medium link">Actualizar dirección del comercio</Link>
                                            <span className="margin-medium-left-3" onChange={(evt) => { this.setState({ classAddAdress: evt.target.checked }) }}>
                                                <BoxInput typeBox="checkbox" typeInput="checkbox" icon="Agregar dirección fiscal" styleClass="form__label" />
                                            </span>
                                        </div>
                                        <div className={`margin-medium-top-3 ${classAddAdress ? "enabled" : "disabled"}`}>
                                            <Direction modal={(message, option) => { this.props.modal(message, option) }} optionModal={1} colonia={colonia} />
                                            <Button styleClass="button__primary button--size-medium margin-medium-top-3" text="Agregar dirección fiscal" />
                                        </div>


                                    </form> : <></>
                            }

                        </> :
                        <>
                            <form id="found-client" className="margin-large-top-3" onSubmit={(evt) => { this.searchPerson(evt) }}>
                                <h3>Buscar cliente</h3>
                                <div className="grid-layout grip-gap margin-large-top-3" style={{ gridTemplateColumns: "repeat(3,max-content)" }} >
                                    <BoxInput typeBox="checkbox" typeInput="radio" statusInput="form__normal" styleClass="form__label" icon="RFC" nameInput="tipoPersona" value="rfc" />
                                    <BoxInput typeBox="checkbox" typeInput="radio" statusInput="form__normal" styleClass="form__label" icon="Nombre" nameInput="tipoPersona" value="nombre" />
                                    <BoxInput typeBox="checkbox" typeInput="radio" statusInput="form__normal" styleClass="form__label" icon="Numero de cliente" nameInput="tipoPersona" value="number" />
                                </div>
                                <div className="margin-medium-top-2" style={{ alignItems: "end" }} >
                                    {/* <BoxInput typeBox="sample" typeInput="text" nameInput="rfc" styleClass="form__label" icon="RFC" statusInput ="form__normal"/> */}
                                    <div style={{ width: "500px" }}>
                                        <BoxInput typeBox="input" styleClass={"form__label"} icon="Dato a buscar" >
                                            <input type="text" name="name" className="form__input form__normal" />
                                        </BoxInput>
                                    </div>

                                    <div className="margin-medium-top-2">
                                        <Button styleClass="button__primary button--size-medium" text="Buscar" />
                                    </div>

                                </div>

                            </form>
                            <div className="margin-large-top-1" onChange={(evt) => { this.assignPerson(evt) }}>
                                <Paginacion numberElement={2}>
                                    {
                                        clientsCommerce.map(client => {
                                            if (client.id === 0) {
                                                return <p key={client.id} style={{ fontWeight: "bold" }}>NO EXISTEN COINCIDENCIAS EN LA BUSQUEDA</p>
                                            } else {
                                                let typePerson = null;
                                                if (client.nombre) {
                                                    typePerson = "Fisica"
                                                } else { typePerson = "Moral" }
                                                return (
                                                    <div key={client.id} className="margin-small-top-3">
                                                        <BoxInput typeBox="checkbox" typeInput="radio" styleClass="form__label" nameInput="client" value={`${client.id}:${typePerson}`} icon={`Nombre: ${client.nombre ? `${client.nombre} ${client.apellidoPaterno} ${client.apellidoMaterno}` : client.moral} RFC: ${client.rfc} Persona: ${typePerson}`} />
                                                    </div>
                                                )
                                            }
                                        }
                                        )

                                    }
                                </Paginacion>
                            </div>
                            <div className="margin-large-top-3">
                                <form id="clientes" autoComplete="off" className={typePersonTwo ? "enabled" : "disabled"}>
                                    <div className="margin-large-top-3" style={{ width: "400px" }}>
                                        <BoxInput typeBox="description" typeInput="text" nameInput="rfc" styleClass="form__label" icon="RFC" statusInput={`form__normal form__uppercase`} description="Escribir XXXX010101XXX si no cuenta el RFC" minLength={10} maxLength={13} />
                                    </div>
                                    {
                                        typePersonTwo ?
                                            <>
                                                {
                                                    typePersonTwo === "Fisica" ?
                                                        <div id="form-persona-fisica" className="margin-large-top-3" style={{ display: "grid", gridTemplateColumns: "min-content 1fr", alignItems: "baseline" }}>
                                                            <BoxInput typeBox="checkbox" typeInput="radio" value="fisica" nameInput="TipoPersona" styleClass="form__label" icon="fisica" />
                                                            <div className="grid-layout grid-3">
                                                                <BoxInput typeBox="description" typeInput="text" nameInput="apellidoPaterno" styleClass="form__label" description="Apellido Paterno" statusInput={"form__normal"} minLength={3} maxLength={40} />
                                                                <BoxInput typeBox="description" typeInput="text" nameInput="apellidoMaterno" styleClass="form__label" description="Apellido Materno" statusInput={"form__normal"} minLength={3} maxLength={40} />
                                                                <BoxInput typeBox="description" typeInput="text" nameInput="nombrePila" styleClass="form__label" description="Nombre" statusInput={"form__normal"} minLength={3} maxLength={50} />
                                                            </div>
                                                        </div> :
                                                        <div id="form-persona-moral" className="margin-large-top-3" style={{ display: "grid", gridTemplateColumns: "min-content 1fr", alignItems: "baseline" }}>
                                                            <BoxInput typeBox="checkbox" typeInput="radio" value="moral" nameInput="TipoPersona" styleClass="form__label" icon="moral" />
                                                            <div >
                                                                <BoxInput typeBox="description" styleClass="form__label" typeInput="text" nameInput="moral" description="Escriba el nombre de la empresa tal y como se dio de alta en la SHCP, sin abreviar, sin guiones y sin comillas" statusInput={"form__normal"} minLength={3} maxLength={100} />
                                                            </div>
                                                        </div>
                                                }
                                            </> : <></>
                                    }

                                    <Button styleClass="button__primary button--size-medium margin-medium-top-2" text="Actualizar" />
                                </form>
                            </div>



                        </>
                }

            </section>
        );
    }

    async componentDidMount() {
        const { idClient } = this.props.params;
        let responseClient = null, typePerson = null, adressClient = null;
        if (idClient) {
            responseClient = await this.read(`${this.props.hosting}/webservice/cliente/${idClient}`);
            typePerson = responseClient.nombre ? "Fisica" : "Moral";
            adressClient = !responseClient.clienteDireccionFiscalModelo ? await this.read(`${this.props.hosting}/webservice/comercio/direccionFiscal/${idClient}`) : false;
            this.setState({ client: responseClient, typePerson, adressClient });
            this.fillCLient(typePerson);
        }

    }
    fillCLient(person) {
        let dataAdress = this.state.client.clienteDireccionFiscalModelo || false;
        let nombreVialidad, numeroExterior, numeroInterior, coloniaModelo, tipoVialidadModelo;
        const { datosImportantes, telefono } = this.state.client;
        if (dataAdress) {
            ({ nombreVialidad, numeroExterior, numeroInterior, coloniaModelo, tipoVialidadModelo } = dataAdress);
            document.querySelector("input[name=nombreVialidad]").value = nombreVialidad;
            document.querySelector("input[name=numeroExterior]").value = numeroExterior;
            document.querySelector("input[name=numeroInterior]").value = numeroInterior;
            document.querySelector("input[name=codigoPostal]").value = coloniaModelo.codigoPostal;
            let tipoVialidad = document.querySelector("select[name=tipoVialidadModelo]");
            let optionTipoVialidad = Array.from(tipoVialidad.querySelectorAll("option"));
            let indexVialidad = optionTipoVialidad.findIndex(option => option.value === tipoVialidadModelo.id);
            tipoVialidad.selectedIndex = indexVialidad;
            this.setState({ colonia: [coloniaModelo] });
        }
        switch (person) {
            case "Fisica":
                const { nombre, apellidoPaterno, apellidoMaterno, rfc } = this.state.client;
                document.querySelector("input[name=nombre]").value = nombre;
                document.querySelector("input[name=rfc]").value = rfc;
                document.querySelector("input[name=apellidoPaterno]").value = apellidoPaterno;
                document.querySelector("input[name=apellidoMaterno]").value = apellidoMaterno;
                break;
            case "Moral":
                const { moral, rfc: rfcMoral } = this.state.client;
                document.querySelector("input[name=rfc]").value = rfcMoral;
                document.querySelector("input[name=moral]").value = moral;
                break;

            default:
                break;
        }

        document.querySelector("textarea[name=datosImportantes]").value = datosImportantes || "S/D";
        document.querySelector("input[name=telefono]").value = telefono || "S/D";





    }
    async searchPerson(evt) {
        evt.preventDefault();
        const objForm = new FormData(evt.target);
        let stringParam = null;
        if (objForm.has("tipoPersona")) {
            switch (objForm.get("tipoPersona")) {
                case "rfc":
                    stringParam = `?estado=true&rfc=${objForm.get("name")}`;
                    break;
                case "nombre":
                    stringParam = `?estado=true&palabra=${objForm.get("name")}`;
                    break;
                case "number":
                    stringParam = `/${objForm.get("name")}`;
                    break;
                default:
                    break;
            }
        } else { stringParam = `?estado=true&palabra=${objForm.get("name")}` }


        let data = await this.read(`${this.props.hosting}/webservice/cliente${stringParam}`);
        if (data) {
            data = Array.isArray(data) ? data : [data];
        } else {
            data = [{ id: 0 }]
        }

        // console.log(data);
        this.setState({ clientsCommerce: data });



    }
    async read(url) {
        let response = null, data = null;
        try {
            response = await fetch(url);
            if (response.ok && (response.status === 200 || response.status === 205)) {
                data = await response.json()
            } else {
                throw new Error("No se pudo realizar la consulta")
            }
        } catch (error) {
            // console.log(error);
            data = false;

        } finally {
            return data;
        }

    }
    async update(url, contentBody) {
        let data, response;
        const objData = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            if (response.ok && response.status === 205) {
                data = true
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally { return data }


    }
    async create(url, contentBody) {
        let data, response;
        const objData = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(contentBody)
        }
        try {
            response = await fetch(url, objData);
            // console.log(response.status);
            if (response.ok && (response.status >= 200 && response.status <= 400)) {
                if (response.status === 201) {
                    data = await response.json()
                }else{
                    data = true
                }
                
            }

        } catch (error) {
            // console.log(error);
            data = false;
        } finally { return data }
    }
    assignPerson(evt) {
        let element = evt.target;
        let data = element.value;
        let params = data.split(":");
        // console.log(params);
        this.setState(
            {
                inputCommerceId: params[0],
                typePersonTwo: params[1]
            }
        );
    }

    async updateClient(evt) {
        evt.preventDefault();
        let form = new FormData(evt.target);
        // form.delete("direccionFiscal");
        let obj = {};
        let addPostCode = null;
        for (const pair of form.entries()) {
            obj[pair[0]] = pair[1].toUpperCase();
            /*if (pair[1] === "") {
                this.props.modal("No se pueden dejar campos vacios", 1);
                return 0;
            }*/
        }
        
        if (obj.nuevaColonia) {
           addPostCode = await this.addOtherPostcode(obj);
        }
        
       
        let objClient = obj.nombre ?
            {
                rfc: obj.rfc,
                nombre: obj.nombre,
                apellidoPaterno: obj.apellidoPaterno,
                apellidoMaterno: obj.apellidoMaterno,
                telefono: obj.telefono,
                datosImportantes: obj.datosImportantes
            } : {
                rfc: obj.rfc,
                moral: obj.moral,
                telefono: obj.telefono,
                datosImportantes: obj.datosImportantes
            };

        let objStateclient = false, newAdress = false;
        let objAdress = this.state.client.clienteDireccionFiscalModelo ? {
            nombreVialidad: obj.nombreVialidad === "" ? "S/D" : obj.nombreVialidad,
            numeroExterior: obj.numeroExterior === "" ? "S/N" : obj.numeroExterior,
            numeroInterior: obj.numeroInterior === "" ? "S/N" : obj.numeroInterior,
            tipoVialidadModelo: obj.tipoVialidadModelo,
            coloniaModelo: addPostCode || obj.coloniaModelo,
        } : false;


        if (objAdress) {
            // console.log("entro objAdress");
            let { clienteDireccionFiscalModelo } = this.state.client;
            objStateclient = {
                nombreVialidad: clienteDireccionFiscalModelo.nombreVialidad === "" ? "S/D" : obj.nombreVialidad,
                numeroExterior: clienteDireccionFiscalModelo.numeroExterior === "" ? "S/N" : obj.numeroExterior,
                numeroInterior: clienteDireccionFiscalModelo.numeroInterior === "" ? "S/N" : obj.numeroInterior,
                tipoVialidadModelo: clienteDireccionFiscalModelo.tipoVialidadModelo.id,
                coloniaModelo: clienteDireccionFiscalModelo.coloniaModelo.id.toLowerCase(),
            }
            objStateclient = changeData(objStateclient, objAdress) ? false : {
                ...objAdress,
                tipoVialidadModelo: { id: obj.tipoVialidadModelo },
                coloniaModelo: { id: objAdress.coloniaModelo.toLowerCase() },

            };
        } else if (obj.nombreVialidad) {
            // console.log("entro newAdress");
            newAdress = {
                nombreVialidad: obj.nombreVialidad === "" ? "S/D" : obj.nombreVialidad,
                numeroExterior: obj.numeroExterior === "" ? "S/N" : obj.numeroExterior,
                numeroInterior: obj.numeroInterior === "" ? "S/N" : obj.numeroInterior,
                tipoVialidadModelo: obj.tipoVialidadModelo,
                coloniaModelo: addPostCode || obj.coloniaModelo.toLowerCase(),
            }

        }

        let response = await this.update(`${process.env.REACT_APP_DOMAIN}/webservice/cliente/${this.props.params.idClient}`, objClient);
        if (objStateclient) {
            let responseAdress = await this.update(`${process.env.REACT_APP_DOMAIN}/webservice/direccion-fiscal/${this.props.params.idClient}`, objStateclient);
        }
        if (newAdress && (newAdress.nombreVialidad !== "S/D" || newAdress.numeroExterior !== "S/N" || newAdress.numeroInterior !== "S/N")) {
            newAdress = {
                ...newAdress,
                idCliente: parseInt(this.props.params.idClient),
                tipoVialidadModelo: { id: obj.tipoVialidadModelo },
                coloniaModelo: { id: newAdress.coloniaModelo },
            }
            let response = await this.create(`${process.env.REACT_APP_DOMAIN}/webservice/direccion-fiscal`, newAdress);

        }
        if (response) {
            this.props.modal("El cliente fue actualizado correctamente, ¿Desea regresar a detalles del comercio?", 2);
        } else {
            this.props.modal("Error", 1);
        }
        function changeData(objOne, objTwo) {
            for (const key in objOne) {
                if (objOne[key] !== objTwo[key]) {
                    return false;
                }
            }
            return true;
        }

    }

    async createAdress(evt) {
        evt.preventDefault();
        let element = evt.target;
        let formAdress = new FormData(element);
        let objAdress = {},addPostCode = null;
        // formAdress.delete("codigoPostal");
        // formAdress.delete("idMunicipio");
        for (const pair of formAdress.entries()) {
            objAdress[pair[0]] = pair[1].toUpperCase();
        }
        if (objAdress.nuevaColonia) {
            addPostCode = await this.addOtherPostcode(objAdress);
         }
        objAdress = {
            nombreVialidad: objAdress.nombreVialidad === "" ? "S/D" : objAdress.nombreVialidad,
            numeroExterior: objAdress.numeroExterior === "" ? "S/N" : objAdress.numeroExterior,
            numeroInterior: objAdress.numeroInterior === "" ? "S/N" : objAdress.numeroInterior,
            idCliente: parseInt(this.props.params.idClient),
            coloniaModelo: { id: addPostCode || objAdress.coloniaModelo.toLowerCase() },
            tipoVialidadModelo: { id: objAdress.tipoVialidadModelo },
        }

        let response = await this.create(`${process.env.REACT_APP_DOMAIN}/webservice/direccion-fiscal`, objAdress);
        // console.log(objAdress);
        // console.log(response);
        if (response) {
            this.props.modal("Se agrego correctamente la dirección fiscal", 1);
        }
    }

   async addOtherPostcode(obj){
        const {nuevaColonia,idMunicipio,codigoPostal} = obj;
            // console.log("entro nueva colonia");
            let objPostCode = {
                coloniaModeloLista: [
                    {nombre: nuevaColonia}
                ],
                idMunicipio
            } 
           let response = await this.create(`${process.env.REACT_APP_DOMAIN}/webservice/direccion/colonia/${codigoPostal}`,objPostCode);
        //    console.log(response);
           return response ? response.coloniaModeloLista[0].id : false;
    }


}

export default FormCliente;