import React,{Component} from "react";

class Button extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
        <button data-reference={this.props.reference} onClick={this.props.method} className={`button ${this.props.styleClass}`} type={this.props.typeButton} disabled={this.props.disabled}>{this.props.text}</button>
        );
    }
}
export default Button;