import React, { Component } from "react";
import { } from "module";

class ModalDos extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={`modal ${this.props.stateModal} ${this.props.modalOpen}`}>
                <div className={this.props.sizeModal}>
                    <div className="modal__body">
                        <div >{this.props.text}</div>
                    </div>
                    <div className="modal__footer">
                        {this.props.optionContent ? this.props.optionContent === 1 ? this.props.buttonsModalOne : this.props.buttonsModalTwo : this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}

class ButtonModal extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <button type="button" onClick={() => { this.stateModal() }} className={`${this.props.styleClass || ""} button  button--size-small`} id={this.props.id}>{this.props.children}</button>
        )
    }

    stateModal() {
        if (this.props.option) {
            this.show();
        } else {
            this.hidden();
            if (this.props.method) {
                this.props.method();

            }
        }

    }

    show() {
        this.props.visibilityModal("visibility")
        setTimeout(() => {
            this.props.openModal("modal-open")
        }, 500);
    }

    hidden() {
        this.props.openModal("");
        setTimeout(() => {
            this.props.visibilityModal("hidden")
        }, 500);

    }

}

export { ModalDos as default, ButtonModal };